<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div
				class="col-md-6"
				*ngIf="!sent">
				<div class="form-group">
					<label>{{'labels.sender' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedSender($event)"
						(onDelete)="onDeleteSender()"
						name="customer"
						[(ngModel)]="senderSelected"
						placeholder="{{ 'labels.sender' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>
			<div
				class="col-md-6"
				*ngIf="!received">
				<div class="form-group">
					<label>{{'labels.receiver' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedreceiver($event)"
						(onDelete)="onDeletereceiver()"
						name="customer"
						[(ngModel)]="receiverSelected"
						placeholder="{{ 'labels.receiver' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>

			<div
				class="col-md-6"
				*ngIf="!travelid && !uid">
				<div class="form-group">
					<label>{{'labels.travel' | translate}}</label>
					<app-entity-autocomplete
						entityName="travel"
						entityField="name"
						entityFilterField="name"
						entityFilterType="like"
						[entitySearch]="searchTravel"
						[entityClass]="travelClass"
						(onSelected)="onSelectedtravel($event)"
						(onDelete)="onDeletetravel()"
						name="travel"
						[(ngModel)]="travelSelected"
						placeholder="{{ 'labels.travel' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>

			<div class="col-md-6 mb-1">
				<label>{{'labels.insdate' | translate}}</label>
				<div class="row">
					<div class="col-md-1">{{'labels.from' | translate}}:</div>
					<div class="col-md-5">

						<app-datepicker
							name="insdate.from"
							[(ngModel)]="insdate.from">
						</app-datepicker>
					</div>
					<div class="col-md-1">{{'labels.to' | translate}}:</div>
					<div class="col-md-5">
						<app-datepicker
							[disabled]="!insdate.from"
							[min]="insdate.from"
							name="insdate.to"
							[(ngModel)]="insdate.to">
						</app-datepicker>
					</div>
				</div>
			</div>

			<div class="col-md-4">
				<div class="fake-label"></div>
				<input
					id="approved"
					type="checkbox"
					name="approved"
					[(ngModel)]="toBeApproved"
					(click)="searchparams.simpleTmp['approved'] = (($event.target.checked) ? 0 : '')">
				<label
					for="approved"
					class="ml-1 checkbox-label">
					{{'labels.tobeapproved' | translate}}
				</label>
			</div>

			<div class="col-md-4">
				<div class="fake-label"></div>
				<input
					id="happened"
					type="checkbox"
					name="happened"
					[(ngModel)]="partiti"
					(click)="searchparams.simpleTmp['travel.happened'] = (($event.target.checked) ? 1 : '')">
				<label
					for="happened"
					class="ml-1 checkbox-label">
					Solo viaggi partiti
				</label>
			</div>

			<div class="col-md-4">
				<div class="fake-label"></div>
				<input
					id="media"
					type="checkbox"
					name="media"
					[(ngModel)]="mediaonly"
					(click)="searchparams.simpleTmp['travel.media'] = (($event.target.checked) ? 1 : '')">
				<label
					for="media"
					class="ml-1 checkbox-label">
					Solo viaggi con media
				</label>
			</div>

		</div>

		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
