import { Component, OnInit } from '@angular/core';
import { Travel as _TravelOld, Guest } from 'meetravel-ui-lib';
import { Search, CrudService, MessageService, Page, Breadcrumb } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminFeatures } from '../../conf/features';

class Travel extends _TravelOld {
  smart: boolean;
}

@Component({
	selector: 'app-travel',
	templateUrl: './travel.component.html',
	styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit {

	travel: Travel;
	activeTab: string;
	messageid: number;
	breadcrumb: Breadcrumb;
	FEATURE_EDIT_VIAGGI = AdminFeatures.ADMIN_EDIT_VIAGGI;

	constructor(
		private crudService: CrudService<Travel>,
		private guestService: CrudService<Guest>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private router: Router
	) {

	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];
			if (id) {
				this.load(id);
			}
		});

		this.route.queryParams.subscribe(params => {
			const messageid = params['mid'];
			if (messageid) {
				this.messageid = messageid;
			}
		});
		console.log('active', this.activeTab);
	}

	setBreadcrumb() {
		this.breadcrumb = new Breadcrumb();
		this.breadcrumb.breadcrumbitems = [
			{ title: 'labels.travel', url: '/travel', param: '' },
		];
	}

	load(id: number) {
		const search = new Search('travel');
		search.addSimpleFilter('id', id);
		this.crudService.searchEntities(Travel, 'travel', search, new Page(1)).subscribe(result => {
			this.travel = result[0];
			this.loadGuests(this.travel.id);
			if (this.messageid) {
				this.activeTab = 'chat';
			}
			this.setBreadcrumb();
		}, error => {
			console.log('error', error);
		});

	}

	loadGuests(tid: number) {
		const search = new Search('travelguest');
		search.addSimpleFilter('travelid', tid);

		this.guestService.searchEntities(Guest, 'travelguest', search, new Page(1000)).subscribe(result => {
			this.travel.guests = result.filter((v, i, a) => a.findIndex(t => (t.userid === v.userid)) === i);
		}, error => {
			console.log('error', error);
		});
	}

}
