import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';
import { Search, ParamsService } from 'elbuild-ui-lib-core';

@Component({
  selector: 'app-banner-list-filters',
  templateUrl: './banner-list-filters.component.html',
  styleUrls: ['./banner-list-filters.component.scss']
})
export class BannerListFiltersComponent extends ListFiltersComponent implements OnInit {
  keyword: string;
  active: boolean = true;

  @Output() searchEvent = new EventEmitter<Search>();


  constructor(router: Router,
    _route: ActivatedRoute,
    paramsService: ParamsService) {
    super(router, _route, paramsService);
  }

  ngOnInit() {
    this.resetSearch();
  }

  resetSearch() {
    this.keyword = undefined;
    this.active = true;
    this.searchparams.reset();
    this.search();
  }

  search(keeppage?: boolean) {
    this.searchEvent.emit(this.searchparams);
  }

}
