<div class="row">

	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<h3 class="float-right mr-4 mb-1">
				{{'labels.currentbalance'|translate}}: {{balance}}
			</h3>
			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-points' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="metric.metric"
						[flexGrow]="1"
						[name]="'labels.attivita' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="0.5"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value |date:'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="points"
						[flexGrow]="0.5"
						[sortable]="false"
						[name]="'labels.punti' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<label *ngIf="row.id!=selectedPointsid">{{value}}</label>
							<input
								*ngIf="row.id==selectedPointsid"
								type="number"
								[(ngModel)]="pointsvalue"
								[min]="0"
								[max]="100000"
								(keyup)="pointsvalue > 100000 ? pointsvalue = 100000 :pointsvalue <1 ?pointsvalue =undefined : ''"
								name="points{{row.id}}"
								class="form-control"
								id="points"
								placeholder="Inserisci punti">
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="travelid"
						[flexGrow]="1"
						[name]="'labels.travel' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a
								*ngIf="value"
								[ngbTooltip]="'labels.go-to-travel'|translate"
								[routerLink]="'/travel/edit/'+value">
								{{row.travelname + ' (' + row.travelrole + ')' }}
							</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a
									*ngIf="row.id != selectedPointsid"
									(click)="editInPlace(row.id)">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<a
									(click)="saveItem(row)"
									*ngIf="row.id == selectedPointsid">
									<i
										container="body"
										ngbTooltip="{{'labels.save' | translate}}"
										class="feather icon-check"></i>
								</a>
							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>

</div>
