<div class="card">
	<div class="card-body">
		<div class="row">
			<div class="col-md-3">
				<input
					id="incognito"
					class="bootstrap"
					name="incognito"
					[(ngModel)]="_incognito"
					(ngModelChange)="onIncognitoPress($event)"
					#incognito="ngModel"
					type="checkbox">
				<label
					class="check-label ml-2"
					for="incognito">
					{{'labels.incognito' | translate}}
				</label>
			</div>
			<div class="col-md-3">
				<input
					id="secret"
					class="bootstrap"
					name="secret"
					[(ngModel)]="_secret"
					(ngModelChange)="onSecretPress($event)"
					#secret="ngModel"
					type="checkbox">
				<label
					class="check-label ml-2"
					for="secret">
					{{'labels.secret' | translate}}
				</label>
			</div>
			<div class="col-md-6">
				<app-range-picker-filters (onRangeChange)="onRangeChange($event)"></app-range-picker-filters>
			</div>
		</div>
	</div>
</div>
<app-loading
	[full]="false"
	*ngIf="loading"></app-loading>

<div *ngIf="!loading">
	<div class="row">
		<div class="col">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.tottravel"></app-metric>
				</div>
			</div>
		</div>
		<div class="col">
			<div
				(click)="travelModal('created')"
				class="card">
				<div class="card-body">
					<app-metric [metric]="data?.travel"></app-metric>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.activetravel"></app-metric>
				</div>
			</div>
		</div>
		<div class="col">
			<div
				(click)="travelModal('running')"
				class="card">
				<div class="card-body">
					<app-metric [metric]="data?.runningtravel"></app-metric>
				</div>
			</div>
		</div>
		<div class="col">
			<div
				(click)="travelModal('happened')"
				class="card">
				<div class="card-body">
					<app-metric [metric]="data?.happenedtravel"></app-metric>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.accomodationpiechart"
						[id]="'accomodationpiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.locationpiechart"
						[id]="'locationpiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.budgetpiechart"
						[id]="'budgetpiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.statuspiechart"
						[id]="'statuspiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.typepiechart"
						[id]="'typepiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<app-line-chart
						[chartStruct]="data?.travelchart"
						divid="travelchart"></app-line-chart>
				</div>
			</div>
		</div>

		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<app-line-chart
						[chartStruct]="data?.happenedtravelchart"
						divid="happenedtravelchart"></app-line-chart>
				</div>
			</div>
		</div>
	</div>
</div>
