import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CrudService, MessageService } from 'elbuild-ui-lib-core';
import { Guest, GuestStatus, PresenceType, TravelService } from 'meetravel-ui-lib';

@Component({
	selector: 'app-guest-card',
	templateUrl: './guest-card.component.html',
	styleUrls: ['./guest-card.component.scss']
})
export class GuestCardComponent implements OnInit {

	@Input() guest: Guest;
	@Input() isInvite: boolean = false;
	@Input() isRecived: boolean = false;
	@Input() isSent: boolean = false;

	PresenceType = PresenceType;

	showNote = false;
	showLeavereason = false;

	constructor(private travelService: TravelService,
		private messageService: MessageService,
		private guestService: CrudService<Guest>,
		private translateService: TranslateService,) {}

	ngOnInit() {
	}
	changePresenza() {
		this.travelService.travelGuestPresent(this.guest).subscribe(res => {
			this.guest = res;
			this.messageService.sendSuccess(
				this.translateService.instant('labels.presenza-segnata'),
				this.translateService.instant('labels.success')
			);
		})
	}
	changeAssenza() {
		this.travelService.travelGuestAbsent(this.guest).subscribe(res => {
			this.guest = res;
			this.messageService.sendSuccess(
				this.translateService.instant('labels.assenza-segnata'),
				this.translateService.instant('labels.success')
			);
		})
	}

	changeChatStatus(event: any) {
		if ((event != GuestStatus.JOINED) && (event != GuestStatus.PARTECIPANT)) {
			this.travelService.forceChat(this.guest).subscribe((res: Guest) => {
				this.guest = res;
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else if (event == GuestStatus.JOINED || event == GuestStatus.PARTECIPANT) {
			this.travelService.expelChat(this.guest).subscribe((res: Guest) => {
				this.guest = res;
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

}
