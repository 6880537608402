<div class="card">
	<div class="card-body">

		<div *ngIf="isRecived || isSent">
			<h4>
				<a [routerLink]="'/travel/edit/'+guest?.travelid">{{guest?.travelname}}</a>
			</h4>
			<hr>
		</div>
		<div class="row">
			<div class="col-md-2">
				<img
					asyncimg
					[opaque]="guest?.user?.img"
					[alt]
					placeholder="/assets/images/user-placeholder.png"
					size="md"
					class="img-fluid mt-3 float-right">
			</div>
			<div class="col-md-10 row">
				<div class="col-md-12 mt-3 mb-2">
					<app-guest-status-badge 
						[status]="guest?.realstatus"
						(changeChatStatus)="changeChatStatus($event)">
					</app-guest-status-badge>
				</div>
				<div
					class="col-md-4"
					*ngIf="!isRecived">
					<p>
						<span class="font-weight-bold">{{'labels.user' | translate}}:</span>
						<a [routerLink]="'/user/edit/' + guest.userid">{{guest?.user?.fullname}}</a>
					</p>
					<p>
						<span class="font-weight-bold">{{'labels.gender' | translate}}:</span>
						{{'types.gender.' + guest?.user?.gender | translate}}
					</p>
					<p>
						<span class="font-weight-bold">{{'labels.age' | translate}}:</span>
						{{guest?.user?.age | translate}}
					</p>
					<p>
						<span class="font-weight-bold">{{'labels.mobile' | translate}}:</span>
						{{guest?.user?.mobile}}
					</p>
				</div>
				<div class="col-md-4">
					<p>
						<span class="font-weight-bold">{{'labels.email' | translate}}:</span>
						{{guest?.user?.email}}
					</p>
					<p>
						<span class="font-weight-bold">{{'labels.guesttype' | translate}}:</span>
						{{'types.guesttype.' + guest?.guesttype | translate}}
					</p>
					<p *ngIf="!isInvite && !isSent && !isRecived">
						<span class="font-weight-bold">{{'labels.jointype' | translate}}:</span>
						{{'types.jointype.' + guest?.jointype | translate}}
					</p>
					<p *ngIf="(isInvite || isRecived) && guest?.inviter">
						<span class="font-weight-bold">{{'labels.invitedby' | translate}}:</span>
						<a [routerLink]="'/user/edit/' + guest.inviterid">{{guest?.inviter.fullname}}</a>
					</p>
					<p>
						<span class="font-weight-bold">{{'labels.compatibility' | translate}}:</span>
						{{guest?.compatibility | number : '1.2-2'}}%
					</p>

				</div>

				<div class="col-md-4">
					<p>
						<span class="font-weight-bold">{{'labels.joindate' | translate}}:</span>
						{{guest?.joindate | date: "dd/MM/yyyy"}}
					</p>
					<p *ngIf="guest.isRejected">
						<span class="font-weight-bold">{{'labels.rejectdate' | translate}}:</span>
						{{guest?.rejectdate | date: "dd/MM/yyyy"}}
					</p>
					<p *ngIf="guest.leavedate">
						<span class="font-weight-bold">{{'labels.leavedate' | translate}}:</span>
						{{guest?.leavedate | date: "dd/MM/yyyy"}}
					</p>
					<p *ngIf="guest.guestnote">
						<span class="font-weight-bold">{{'labels.note' | translate}}:</span> 
						<i
							class="feather icon-clipboard ml-1"
							(click)="showNote = !showNote"
							[ngbTooltip]="('labels.note-'+((showNote)?'hide':'show')) | translate"></i>
					</p>
					<p *ngIf="guest.leavereason">
						<span class="font-weight-bold">{{'labels.leavereason' | translate}}:</span> 
						<i
							class="feather icon-clipboard ml-1"
							(click)="showLeavereason = !showLeavereason"
							[ngbTooltip]="('labels.leavereason-'+((showLeavereason)?'hide':'show')) | translate"></i>
					</p>

				</div>
			</div>

		</div>
		<div [ngbCollapse]="!showNote">
			<br>
			<div>
				<span class="font-weight-bold">{{'labels.note' | translate}}:</span>
				{{guest?.guestnote}}
			</div>
		</div>
		<div [ngbCollapse]="!showLeavereason">
			<br>
			<div>
				<span class="font-weight-bold">{{'labels.leavereason' | translate}}:</span>
				{{guest?.leavereason}}
			</div>
		</div>
		<div
			class="text-right"
			*ngIf="guest.isAbsent || guest.isPresent">

			<h5 [ngClass]="{'text-danger': guest.isAbsent, 'text-success': guest.isPresent}">
				{{'types.presence.'+guest.presence | translate}}
			</h5>
		</div>

		<div
			class="text-left mt-2 ml-2"
			*ngIf="guest.realstatus === 'partecipant'">
			<button
				*ngIf="guest.presence != PresenceType.PRESENT"
				type="button"
				class="btn btn-success mr-2"
				(click)="changePresenza()">
				{{'labels.segnapresenza' | translate}}
			</button>

			<button
				*ngIf="guest.presence != PresenceType.ABSENT"
				type="button"
				class="btn btn-danger"
				(click)="changeAssenza()">
				{{'labels.segnaassenza' | translate}}
			</button>
		</div>

	</div>
</div>
