import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Page, MessageService, CrudService, Search } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Wishlist } from 'meetravel-ui-lib';
import { DialogService } from 'elbuild-ui-componentslib';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  @Input() uid: number;

  loading: boolean = false;
  rows: Wishlist[] = [];
  page = new Page();
  search = new Search('wishlist');

  constructor(
    private crudService: CrudService<Wishlist>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef) {


  }

  ngOnInit() {
    this.search.addSimpleFilter('userid', this.uid);
    this.loadItems();
  }


  loadItems() {
    this.loading = true;
    this.rows = [];
    this.cdr.detectChanges();
    const datarequest = this.crudService.searchEntities(Wishlist, 'wishlist', this.search, this.search.page);
    const countrequest = this.crudService.countEntities(Wishlist, 'wishlist', this.search);


    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.page.setTotal((results[1] as any).ct);
      this.rows = results[0];
      console.log(this.rows);
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
      this.loading = false;
    });
  }

  onPageChange(pageInfo: any) {

    this.page.setPageNumber(pageInfo.offset);
    this.loadItems();
  }


  rowClass = (row: any) => {
    return '';
  }


}
