import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Search, ParamsService } from 'elbuild-ui-lib-core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { MeetravelUser, UserType } from 'meetravel-ui-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
  selector: 'app-travel-feedback-list-filters',
  templateUrl: './travel-feedback-list-filters.component.html',
  styleUrls: ['./travel-feedback-list-filters.component.scss']
})
export class TravelFeedbackListFiltersComponent extends ListFiltersComponent implements OnInit {


  @Input() travelid: number;

  keyword: string;
  active: boolean = true;

  searchUsers: Search;
  userClass: ClassType<MeetravelUser> = MeetravelUser;
  senderSelected: MeetravelUser;

  @Output() searchEvent = new EventEmitter<Search>();


  constructor(router: Router,
    _route: ActivatedRoute,
    paramsService: ParamsService) {
    super(router, _route, paramsService);
    this.searchUsers = new Search('user');
    this.searchUsers.addSimpleFilter('utype', UserType.USER);


  }

  ngOnInit() {
    this.resetSearch();
  }



  onSelectedSender(event: MeetravelUser) {
    this.searchparams.addSimpleFilter('senderid', event.id);
  }

  onDeleteSender() {
    this.searchparams.removeSimpleFilter('senderid');
  }

  resetSearch() {
    this.keyword = undefined;
    this.active = true;
    this.searchparams.reset();
    this.senderSelected = undefined;
    if (this.travelid) {
      this.searchparams.addSimpleFilter('travelid', this.travelid);
    }
    this.search();

  }

  search(keeppage?: boolean) {
    if (this.senderSelected) {
      this.searchparams.addSimpleFilter('senderid', this.senderSelected.id);
    }
    this.searchEvent.emit(this.searchparams);
  }
}

