import { Component, OnInit, OnDestroy } from '@angular/core';
import { Country } from 'meetravel-ui-lib';
import { CrudService, SharedDataService, MessageService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-country-edit',
	templateUrl: './country-edit.component.html',
	styleUrls: ['./country-edit.component.scss']
})

export class CountryEditComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	productid: number;
	country: Country = new Country();

	get title(): string {
		return this.country?.id ? 'country-edit' : 'country-create';
	}


	constructor(
		private countryService: CrudService<Country>,
		private sharedDataService: SharedDataService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal
	) { }

	ngOnInit() { }

	ngOnDestroy(): void {
		this.sharedDataService.addCommonData('route', undefined);
	}

	load(countryid: number): void {
		this.loading = true;
		this.countryService.getEntity(Country, 'country', countryid).subscribe((country: Country) => {
			this.country = country;
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.country'));
			this.country = undefined;
			this.loading = false;
		});
	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	submit(): void {
		if (!this.country.id) {
			this.countryService.createEntity(Country, 'country', this.country).subscribe((response: Country) => {
				this.country = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.country-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.country);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {
			this.countryService.saveEntity(Country, 'country', this.country).subscribe((response: Country) => {
				this.country = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.country-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.country);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

}
