<div class="row">
	<div class="col-sm-12">
		<app-travel-feedback-list-filters
			#listfilters
			[searchparams]="advancedSearch"
			[travelid]="travelid"
			(searchEvent)="applySearch($event)">
		</app-travel-feedback-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="advancedSearch.page.totalElements"
					[offset]="advancedSearch.page.pageNumber"
					[limit]="advancedSearch.page.size"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-travel-feedback' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="user"
						[flexGrow]="1"
						[name]="'labels.user' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value?.fullname}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="rating"
						[flexGrow]="1"
						[name]="'labels.rating' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="note"
						[flexGrow]="0.5"
						[name]="'labels.note' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<i
								container="body"
								ngbTooltip="{{value}}"
								(click)="showFeedbackNote(row)"
								class="feather icon-clipboard"></i>
						</ng-template>
					</ngx-datatable-column>
					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">

								<a (click)="delete(row)">
									<i
										container="body"
										ngbTooltip="{{'labels.delete' | translate}}"
										class="feather icon-trash"></i>
								</a>

							</div>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
			</div>

		</app-card>
	</div>

</div>
