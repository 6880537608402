import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-travel-rating',
  templateUrl: './travel-rating.component.html',
  styleUrls: ['./travel-rating.component.scss']
})
export class TravelRatingComponent implements OnInit {

  @Input() travelid: number;

  constructor() { }

  ngOnInit() {
  }

}
