import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Search, CrudService, Page, MessageService } from 'elbuild-ui-lib-core';
import { Guest, MeetravelUser } from 'meetravel-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  uid: number;
  user: MeetravelUser;
  recivedInvites: Guest[];
  sentInvites: Guest[];

  constructor(private guestService: CrudService<Guest>,
    private crudService: CrudService<MeetravelUser>,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private httpClient: HttpClient) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];
      if (id) {
        this.loadUser(id);
      } else {
        this.user = new MeetravelUser();
      }

    });
  }

  loadUser(id: number) {
    this.crudService.getEntity(MeetravelUser, 'user', id).subscribe(user => {
      this.user = user;
      this.user.decorate(this.httpClient);
      console.log('user', this.user);
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.user = undefined;
    });
  }

  refreshUser() {
	  this.user.decorate(this.httpClient);
  }

  loadRecivedInvites() {
    const search = new Search('travelguest');
    search.addNotEqualFilter('inviterid', this.uid);
    search.addSimpleFilter('userid', this.uid);

    this.guestService.searchEntities(Guest, 'travelguest', search, new Page(100)).subscribe(result => {
      this.recivedInvites = result;
    }, error => {
      console.log('error', error);
    });
  }

  loadSentInvites() {
    const search = new Search('travelguest');
    search.addSimpleFilter('inviterid', this.uid);
    search.addNotEqualFilter('userid', this.uid);
    this.guestService.searchEntities(Guest, 'travelguest', search, new Page(100)).subscribe(result => {
      this.sentInvites = result;
    }, error => {
      console.log('error', error);
    });
  }

}
