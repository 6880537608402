<div class="card">
	<div class="card-body">
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-6">
				<app-range-picker-filters (onRangeChange)="onRangeChange($event)"></app-range-picker-filters>
			</div>
		</div>
	</div>
</div>
<app-loading
	[full]="false"
	*ngIf="loading"></app-loading>
<div *ngIf="!loading">
	<div class="row">
		<div class="col-md-2 offset-md-1">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.appstart1w"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.appstart1m"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.appstart3m"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.appstart6m"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.appstart1y"></app-metric>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-2 offset-md-1">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totcustomer"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.activecustomer"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.followercustomer"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.joinedcustomer"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.hostcustomer"></app-metric>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-2 offset-md-1">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.pendingcustomer"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totpremium"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totpremiumperiod"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totpremiumtravel"></app-metric>
				</div>
			</div>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.totcustomerchart"
				divid="totcustomerchart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.activecustomerchart"
				divid="activecustomerchart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.followercustomerchart"
				divid="followercustomerchart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.joinedcustomerchart"
				divid="joinedcustomerchart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.hostcustomerchart"
				divid="hostcustomerchart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.newpremium"
				divid="newpremium"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.trendpremium"
				divid="trendpremium"></app-line-chart>
		</div>
	</div>
	<div
		*ngIf="gamificationUserList.length"
		class="card">
		<div class="table-title">Utenti top scorer nel periodo</div>
		<div class="card-body">

			<table class="table">
				<tbody>
					<tr>
						<th>{{'labels.user' | translate}}</th>
						<th>{{'labels.email' | translate}}</th>
						<th>{{'labels.insdate' | translate}}</th>
						<th>{{'labels.birthdate' | translate}}</th>
						<th>
							{{'labels.hostcertificato' | translate}}
						</th>
						<th>{{'labels.level' | translate}}</th>
						<th>{{'labels.points' | translate}}</th>
					</tr>
					<tr *ngFor="let user of gamificationUserList">
						<td>
							<a routerLink="../../user/edit/{{user.id}}">{{user.fname}} {{user.lname}}</a>
						</td>
						<td>{{user.email}}</td>
						<td>{{user.insdate | date: 'dd/MM/yyyy'}}</td>
						<td>{{user.birthdate | date: 'dd/MM/yyyy'}}</td>
						<td>
							{{((user.certifiedhost) ? 'labels.yes' : 'labels.no') | translate}}
						</td>
						<td>{{user.gamificationlevel}}</td>
						<td>{{user.gamificationpoints}}</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
</div>
