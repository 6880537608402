import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CrudService, MessageService, Page, RangeFilter, Search, SortDirection } from 'elbuild-ui-lib-core';
import { Feedback, Travel, TravelFeedback, TravelService } from 'meetravel-ui-lib';

@Component({
	selector: 'app-feedback-modal',
	templateUrl: './feedback-modal.component.html',
	styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {

	travels: Travel[] = [];
	rangeFilter: RangeFilter;
	loading: boolean;

	constructor(private activeModal: NgbActiveModal,
		private messageService: MessageService,
		private translateService: TranslateService,
		private travelService: TravelService,
		private router: Router) {
	}

	ngOnInit() {
		this.loadFeedback();
	}

	loadFeedback() {
		this.loading = true;
		const filter = { start: Math.trunc(this.rangeFilter.from / 1000), stop: Math.trunc(this.rangeFilter.to / 1000) };
		console.log(filter)
		this.travelService.travelFeedback(filter.start, filter.stop).subscribe((res: Travel[]) => {
			console.log(res);
			this.travels = res;
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onActivate(event: any) {
		if (event.type === 'dblclick') {
			this.router.navigateByUrl(`/travel/edit/${event.row.id}`);
			this.dismiss();
		}
	}


	rowClass = (row: any) => {
		return '';
	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

}
