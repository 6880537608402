<div class="metric text-center">
	<div *ngIf="metric">
		<h4>{{metric?.label}}</h4>
		<br>
		<h5>{{metric?.value}}</h5>
	</div>
	<div *ngIf="!metric">
		<h4>{{'labels.no-value' | translate}}</h4>
	</div>
</div>
