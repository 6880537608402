<div class="modal-content">
	<div class="modal-header">
		<button
			type="button"
			class="close"
			(click)="dismiss()"
			aria-label="Close">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="text-center">
			<img
				class="media"
				asyncimg
				[opaque]="img"
				[alt]
				placeholder="/assets/images/user-placeholder.png"
				size="md"
				alt="Avatar">
		</div>
	</div>

</div>
