import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
	{
		path: 'signin',
		component: LoginComponent,
		data: {
			title: 'Login'
		}
	},
	{
		path: 'change-password',
		component: ChangePasswordComponent,
		data: {
			title: 'Change password'
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BackofficeAuthRoutingModule { }
