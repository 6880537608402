import { Injectable } from '@angular/core';
import { AdminFeatures, ConfigurazioneFeatures, DashBoardFeatures, ZoneFeatures } from 'src/app/@core/core-components/conf/features';

export interface NavigationItem {
	id: string;
	title: string;
	type: 'item' | 'collapse' | 'group';
	translate?: string;
	icon?: string;
	hidden?: boolean;
	url?: string;
	classes?: string;
	exactMatch?: boolean;
	external?: boolean;
	target?: boolean;
	breadcrumbs?: boolean;
	function?: any;
	badge?: {
		title?: string;
		type?: string;
	};
	children?: Navigation[];
	types?: string[];
	fontawsome: boolean;
	section?: string;
}

export interface Navigation extends NavigationItem {
	children?: NavigationItem[];
}

const NavigationItems = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'group',
		icon: 'feather icon-align-left',
		section: DashBoardFeatures.SECTION_DASHBOARD,
		children: [
			{
				id: 'dashboard-customer',
				title: 'Dashboard Clienti',
				type: 'item',
				url: '/dashboard/customer',
				classes: 'nav-item',
				icon: 'feather icon-users',
				section: DashBoardFeatures.DASHBOARD_CLIENTI,
			},
			{
				id: 'dashboard-travel',
				title: 'Dashboard Viaggi',
				type: 'item',
				url: '/dashboard/travel',
				classes: 'nav-item',
				icon: 'feather icon-compass',
				section: DashBoardFeatures.DASHBOARD_VIAGGI,

			},
			{
				id: 'dashboard-invite',
				title: 'Dashboard Inviti',
				type: 'item',
				url: '/dashboard/invite',
				classes: 'nav-item',
				icon: 'feather icon-mail',
				section: DashBoardFeatures.DASHBOARD_INVITI,

			},
			{
				id: 'dashboard-feedback',
				title: 'Dashboard Feedback',
				type: 'item',
				url: '/dashboard/feedback',
				classes: 'nav-item',
				icon: 'feather icon-check-square',
				section: DashBoardFeatures.DASHBOARD_FEEDBACK,

			},
			{
				id: 'dashboard-chat',
				title: 'Dashboard Chat',
				type: 'item',
				url: '/dashboard/chat',
				classes: 'nav-item',
				icon: 'feather icon-message-circle',
				section: DashBoardFeatures.DASHBOARD_CHAT,

			}
		]
	},
	{
		id: 'admin',
		title: 'Admin',
		type: 'group',
		icon: 'feather icon-align-left',
		section: AdminFeatures.SECTION_ADMIN,
		children: [
			{
				id: 'staff',
				title: 'Staff',
				type: 'item',
				url: '/staff',
				icon: 'feather icon-user-check',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_STAFF,

			},
			{
				id: 'users',
				title: 'Utenti',
				type: 'item',
				url: '/user',
				icon: 'feather icon-users',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_UTENTI,

			},
			{
				id: 'ruoli',
				title: 'Ruoli e Feature',
				type: 'item',
				url: '/rolefeatures',
				icon: 'feather icon-file-text',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_RUOLI_E_FEATURES,
			},
			{
				id: 'travel',
				title: 'Viaggi',
				type: 'item',
				url: '/travel',
				icon: 'feather icon-compass',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_VIAGGI,
			},
			{
				id: 'banner',
				title: 'Banner',
				type: 'item',
				url: '/banner',
				icon: 'feather icon-target',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_BANNER,

			},
			{
				id: 'discount',
				title: 'Sconti',
				type: 'item',
				url: '/discount',
				icon: 'feather icon-target',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_BANNER,

			},
			{
				id: 'product',
				title: 'Prodotti in-app',
				type: 'item',
				url: '/product',
				icon: 'feather icon-globe',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_PRODUCT,

			},
			{
				id: 'purchase',
				title: 'Pagamenti',
				type: 'item',
				url: '/purchase',
				icon: 'feather icon-credit-card',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_PURCHASE,

			},
			{
				id: 'smart-transactions',
				title: 'Transazioni smart',
				type: 'item',
				url: '/smart-transactions',
				icon: 'feather icon-credit-card',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_PURCHASE,

			},
			{
				id: 'gamification',
				title: 'Gamification',
				type: 'item',
				url: '/gamification',
				icon: 'feather icon-crosshair',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_GAMIFICATION,
			},
			{
				id: 'testcreator',
				title: 'Test Creator',
				type: 'item',
				url: '/testcreator',
				icon: 'feather icon-book',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_GAMIFICATION,
			},
			{
				id: 'report',
				title: 'Segnalazioni',
				type: 'item',
				url: '/report',
				icon: 'feather icon-alert-circle',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_SEGNALAZIONI,

			},
			{
				id: 'feedback',
				title: 'Feedback',
				type: 'item',
				url: '/feedback',
				icon: 'feather icon-thumbs-up',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_FEEDBACK,

			},
			{
				id: 'alert',
				title: 'Alert',
				type: 'item',
				url: '/alert',
				icon: 'feather icon-alert-triangle',
				classes: 'nav-item',
				section: AdminFeatures.ADMIN_ALERT,

			},
			{
				id: 'destinazioni',
				title: 'Destinazioni',
				type: 'item',
				icon: 'feather icon-map-pin',
				url: '/destination',
				section: AdminFeatures.ADMIN_DESTINAZIONI,

			},
			{
				id: 'notification',
				title: 'Notifiche',
				type: 'item',
				icon: 'feather icon-bell',
				url: '/notification',
				section: AdminFeatures.ADMIN_NOTIFICHE,

			}
			,
			{
				id: 'list',
				title: 'Liste',
				type: 'item',
				url: '/list',
				classes: 'nav-item',
				icon: 'feather icon-list',
				section: AdminFeatures.ADMIN_LISTE,


			},
			{
				id: 'exoprt',
				title: 'Export Asincroni',
				type: 'item',
				url: '/exports',
				classes: 'nav-item',
				icon: 'fas fa-file-export',
				section: AdminFeatures.ADMIN_LISTE,


			},
		]
	},
	{
		id: 'zone',
		title: 'Zone',
		type: 'group',
		icon: 'feather icon-layers',
		section: ZoneFeatures.SECTION_ZONE,
		children: [
			{
				id: 'country',
				title: 'Nazioni',
				type: 'item',
				icon: 'feather icon-map',
				url: '/country',
				section: ZoneFeatures.ZONE_NAZIONI,

			},
			{
				id: 'regione',
				title: 'Regioni',
				type: 'item',
				icon: 'feather icon-map',
				url: '/regione',
				section: ZoneFeatures.ZONE_REGIONI,

			},
			{
				id: 'province',
				title: 'Province',
				type: 'item',
				icon: 'feather icon-map',
				url: '/province',
				section: ZoneFeatures.ZONE_PROVINCE,

			},
			{
				id: 'comuni',
				title: 'Comuni',
				type: 'item',
				icon: 'feather icon-map',
				url: '/city',
				section: ZoneFeatures.ZONE_COMUNI,

			}
		]
	},
	{
		id: 'config',
		title: 'Configurazione',
		type: 'group',
		icon: 'fas fa-cog',
		section: ConfigurazioneFeatures.SECTION_CONFIGURAZIONE,
		children: [
			{
				id: 'conf',
				title: 'System',
				type: 'item',
				url: '/conf',
				classes: 'nav-item',
				section: ConfigurazioneFeatures.CONFIGURAZIONE_SYSTEM,

			}
		]
	}
	/*{
		id: 'admin',
		title: 'Admin',
		type: 'group',
		icon: 'feather icon-align-left',
		children: [
			{
				id: 'users',
				title: 'Accessi',
				type: 'collapse',
				icon: 'feather icon-users',

				children: [
					{
						id: 'users',
						title: 'Utenti',
						type: 'item',
						url: '/user',
						classes: 'nav-item'
					}, {
						id: 'ruoli',
						title: 'Ruoli e Feature',
						type: 'item',
						url: '/rolefeatures',
						classes: 'nav-item',
					}]
			},
			{
				id: 'list',
				title: 'Liste',
				type: 'item',
				url: '/list',
				classes: 'nav-item',
				icon: 'feather icon-list'
			},
			{
				id: 'zone',
				title: 'Zone',
				type: 'collapse',
				icon: 'feather icon-layers',

				children: [
					{
						id: 'country',
						title: 'Nazioni',
						type: 'item',
						url: '/country',
					},
					{
						id: 'regione',
						title: 'Regioni',
						type: 'item',
						url: '/regione',
					},
					{
						id: 'province',
						title: 'Province',
						type: 'item',
						url: '/province',
					},
					{
						id: 'comuni',
						title: 'Comuni',
						type: 'item',
						url: '/city',
					}
				]
			},
			{
				id: 'config',
				title: 'Configurazione',
				type: 'collapse',
				icon: 'fas fa-cog',
				children: [
					{
						id: 'conf',
						title: 'System',
						type: 'item',
						url: '/conf',
						classes: 'nav-item',
					}
				]
			},

		]
	}*/



];

@Injectable()
export class NavigationItem {
	public get() {
		return NavigationItems;
	}
}
