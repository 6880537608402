import { Component, Input, OnInit } from '@angular/core';
import { Attach, CrudService, Page, Search, thisYear, User } from 'elbuild-ui-lib-core';
import { Travel } from 'meetravel-ui-lib';

@Component({
  selector: 'app-travel-media',
  templateUrl: './travel-media.component.html',
  styleUrls: ['./travel-media.component.scss']
})
export class TravelMediaComponent implements OnInit {

  @Input() travel: Travel;
  loading: boolean = false;
  rows = [];
  advancedSearch: Search = new Search('attach');

  constructor(private crudService: CrudService<Attach>) { }

  ngOnInit() {
    this.advancedSearch.addSimpleFilter('entity', 'travel');
    this.advancedSearch.addSimpleFilter('eid', this.travel.id);
    this.advancedSearch.addInFilter('type', ['photo', 'video']);
    this.loadItems();
  }

  loadItems() {
    this.loading = true;
    this.crudService.searchEntities(Attach, 'attach', this.advancedSearch, new Page(100))
      .subscribe(res => {
        this.rows = res;
        this.loading = false;
      })
  }

  getGuest(attach: any) {
    return this.travel.guests.filter(guest => { return guest.userid == attach.uid })[0];
  }

}
