import { Component, OnInit } from '@angular/core';
import { CrudService, MessageService, Search, SortDirection, UserRole, Page } from 'elbuild-ui-lib-core';
import { Role, UserToRole } from 'src/app/@core/model/role.model';
import { RoleService } from 'src/app/@core/data/role.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/@core/data/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-role-modal',
  templateUrl: './user-role-modal.component.html',
  styleUrls: ['./user-role-modal.component.scss']
})
export class UserRoleModalComponent implements OnInit {

  set uid(value: number) {
    this._uid = value;
    this.loadRoles();
  }

  _uid: number;
  rows: Role[];
  loading: boolean;
  selected: Role[] = [];
  canCheck: boolean = true;
  userRoles: UserRole[];
  name: string;


  advancedSearch: Search = new Search('role');

  constructor(private crudService: CrudService<Role>,
    private activeModal: NgbActiveModal,
    private utrCrudService: CrudService<UserToRole>,
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService) {
    this.advancedSearch.setSortDirection(SortDirection.ASCENDING);
    this.advancedSearch.setSortField('rolename');

  }

  ngOnInit() {
  }

  loadRoles() {

    this.loading = true;

    const utrSearch = new Search('usertorole');
    utrSearch.addSimpleFilter('uid', this._uid);

    const userrolesrequest = this.utrCrudService.searchEntities(UserToRole, 'usertorole', utrSearch, new Page(100));
    const rolesrequest = this.crudService.searchEntities(Role, 'role', this.advancedSearch, new Page(100));

    forkJoin([userrolesrequest, rolesrequest]).subscribe(results => {
      const roles = results[1];
      this.userRoles = results[0];
      this.userRoles.forEach(userRole => {
        const index = roles.map(r => r.id).indexOf(userRole.rid);
        this.selected.push(roles[index]);
      });
      this.rows = roles;
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.loading = false;
    });
  }

  rowClass = (row: Role) => {
    return this.userRoles.find(x => x.rid === row.id) ? 'success-row' : '';
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  close(): void {
    this.activeModal.close();
  }

  onSelect(event: any) {
    const selected: Role[] = event.selected;
    const rids = this.userRoles.map(ur => ur.rid);
    // const srids = filter(selected.map(s => s ? s.id : undefined), function (o) { return o !== undefined; });
    const srids = selected.map(s => s ? s.id : undefined).filter(r => r !== undefined);
    // checked
    if (srids.length > rids.length) {
      const rid = this.getDiff(srids, rids);
      if (rid) {
        this.userService.assignRoleToUser(this._uid, rid).subscribe(userRole => {
          this.userRoles.push(userRole);
          const messages = this.translateService.instant(['labels.user-role-save-success', 'labels.user']);
          this.messageService.sendSuccess(messages['labels.user-role-save-success'], messages['labels.user']);
        }, ({ error }) => {
          const index = this.selected.map(s => s.id).indexOf(rid);
          this.selected.splice(index, 1);
          this.messageService.sendError(error, this.translateService.instant('labels.user'));
        });
      }
      // unchecked
    } else {
      const rid = this.getDiff(rids, srids);
      const index = this.userRoles.map(ur => ur.rid).indexOf(rid);
      const id = this.userRoles[index].id;
      this.userService.unassignRoleToUser(id).subscribe(() => {
        this.userRoles.splice(index, 1);
        const messages = this.translateService.instant(['labels.user-role-delete-success', 'labels.user']);
        this.messageService.sendSuccess(messages['labels.user-role-delete-success'], messages['labels.user']);
      }, ({ error }) => {
        const i = this.rows.map(s => s.id).indexOf(rid);
        this.selected.push(this.rows[i]);
        this.messageService.sendError(error, this.translateService.instant('labels.user'));
      });
    }
  }

  private getDiff(l1: number[], l2: number[]): number {
    for (let i = 0; i < l1.length; i++) {
      let exists = false;
      for (let j = 0; j < l2.length && !exists; j++) {
        if (l1[i] === l2[j]) {
          exists = true;
        }
      }
      if (!exists) {
        return l1[i];
      }
    }
  }

}
