import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CrudService, ListItem, MessageService, Page, Search } from 'elbuild-ui-lib-core';
import { Banner, BannerFilter, Destination, Provincia } from 'meetravel-ui-lib';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Discount, DiscountFilter } from '../discount-list/discount-list.component';

@Component({
	selector: 'app-discount-add-edit-modal',
	templateUrl: './discount-add-edit-modal.component.html',
	styleUrls: ['./discount-add-edit-modal.component.scss']
})
export class DiscountAddEditModalComponent implements OnInit {

	discount: Partial<Discount>;
	title: string;
	loading: boolean = false;
	discountClass = Discount;
	destinationClass = Destination;
	destinationSelected: Destination;
	jsonfilteractive = false;
	interests: any;
	selectedinterests = [];
	searchInterest: Search = new Search('selistitem');
	nationalDestinationSearch = new Search('destination');
	internationalDestinationSearch = new Search('destination');
	regionSearch = new Search('destination');
	travelstyles: any;
	traveltypes: any;
	accomodation: any;
	selectedtravelstyles = [];
	selectedtraveltypes = [];
	selectedaccomodation = [];
	searchTravelstyle: Search = new Search('selistitem');
	searchTravelType: Search = new Search('selistitem');
	searchAccomodation: Search = new Search('selistitem');
	province: any;
	selectedprovince = [];
	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		selectAllText: 'Seleziona tutti',
		unSelectAllText: 'Deseleziona tutti',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};
	typeDropdownSettings: IDropdownSettings = {
		singleSelection: true,
		selectAllText: 'Seleziona tutti',
		unSelectAllText: 'Deseleziona tutti',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};


	constructor(private activeModal: NgbActiveModal,
		private crudService: CrudService<Discount>,
		private messageService: MessageService,
		private listService: CrudService<ListItem>,
		private provinceService: CrudService<Provincia>,
		private destinationService: CrudService<Destination>,
		private translateService: TranslateService,) {
		this.searchInterest.addSimpleFilter('lid', 1); //ID 1 = Interessi
		this.searchTravelstyle.addSimpleFilter('lid', 2); //ID 2 = Stili di viaggio
		this.searchTravelType.addSimpleFilter('lid', 6); //ID 2 = Stili di viaggio
		this.searchAccomodation.addSimpleFilter('lid', 3); //ID 2 = Stili di viaggio
		this.nationalDestinationSearch.addSimpleFilter('type', 'national');
		this.nationalDestinationSearch.addSimpleFilter('regione', 0);
		this.regionSearch.addSimpleFilter('regione', 1);
		this.internationalDestinationSearch.addSimpleFilter('type', 'international');
	}

	ngOnInit() {
		if (!this.discount) {
			this.discount = new Discount();
			this.title = 'discount-create';
		} else {
			this.title = 'discount-edit';

			this.destinationService.getEntity(this.destinationClass, 'destination', this.discount.destinationid).subscribe(dest => {
				this.destinationSelected = dest;
			});

			this.selectedtraveltypes.push(this.discount.traveltype);
			this.selectedaccomodation.push(this.discount.travelaccomodation);

		}

		this.jsonfilteractive = (this.discount?.jsonfilter != null) ? true : false;
		this.discount.discountfilter = new DiscountFilter(this.discount?.jsonfilter);
		if (this.jsonfilteractive) {
			this.selectedinterests = this.discount.discountfilter?.interest;
			this.selectedtravelstyles = this.discount.discountfilter?.traveltstyle;
			this.selectedprovince = this.discount.discountfilter?.province;
		}
		//interests
		this.listService.searchEntities(ListItem, 'selistitem', this.searchInterest, new Page(100)).subscribe((item: ListItem) => {
			this.interests = item;
			this.interests = this.interests.map((e) => e.itemvalue);
			this.loading = false;
		});
		//travelstyles
		this.listService.searchEntities(ListItem, 'selistitem', this.searchTravelstyle, new Page(100)).subscribe((item: ListItem) => {
			this.travelstyles = item;
			this.travelstyles = this.travelstyles.map((e) => e.itemvalue);
			this.loading = false;
		}
		);
		//traveltypes
		this.listService.searchEntities(ListItem, 'selistitem', this.searchTravelType, new Page(100)).subscribe((item: ListItem) => {
			this.traveltypes = item;
			this.traveltypes = this.traveltypes.map((e) => e.itemvalue);
			this.loading = false;
		}
		);
		//accomodation
		this.listService.searchEntities(ListItem, 'selistitem', this.searchAccomodation, new Page(100)).subscribe((item: ListItem) => {
			this.accomodation = item;
			this.accomodation = this.accomodation.map(e => e.itemvalue);
			this.loading = false;
		}
		);
		//province
		this.provinceService.getAll(Provincia, 'province').subscribe((item: Provincia) => {
			this.province = item;
			this.province = this.province.map((e) => e.descrizione);
			this.loading = false;
		}
		);
	}

	dismiss() {
		this.activeModal.close();
	}

	// verifyBanner() {
	// 	return this.discount.cta.startsWith('https');
	// }

	update(event: any): void {
		if (event && event.success && event.response) {
			this.discount.image = event.response.image;

		} else {
			this.messageService.sendError(
				this.translateService.instant('labels.event-upload-error')
			);
		}
	}

	onSelectedDestination(event: Destination) {
		this.discount.destinationid = event.id;
	}

	onDeleteDestination() {
		this.discount.destinationid = undefined;
	}

	submit() {
		// if (this.verifyBanner()) {
		this.discount.traveltype = this.selectedtraveltypes[0];
		this.discount.travelaccomodation = this.selectedaccomodation[0];
		let observable;
		if (this.jsonfilteractive) {
			this.discount.discountfilter.interest = this.selectedinterests;
			this.discount.discountfilter.traveltstyle = this.selectedtravelstyles;
			this.discount.discountfilter.province = this.selectedprovince;
			this.discount.jsonfilter = this.discount.discountfilter.json;
		} else {
			this.discount.discountfilter = undefined;
			this.discount.jsonfilter = undefined;
		}
		if (!this.discount.id) {
			observable = this.crudService.createEntity(Discount, 'discount', this.discount);
		} else {
			observable = this.crudService.saveEntity(Discount, 'discount', this.discount);
		}
		observable.subscribe(result => {
			this.messageService.sendSuccess(this.translateService.instant(this.discount.id ? "labels.discount-edit-completed" : "labels.discount-add-completed"));
			this.activeModal.close();
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
		});
		// } else {
		// 	this.messageService.sendWarn('Il link non è https');
		// }

	}



}
