<div
	class="col-md-12"
	style="margin-top:20px">

	<a [href]="'https://'+baseurl+'/ws/download/x/'+attach.opaqueid">
		<img
			asyncimg
			*ngIf="attach.type == 'photo'"
			[opaque]="attach.opaqueid"
			class="photoBox">
	</a>

	<div *ngIf="attach.type == 'video'">
		<video
			width="256"
			height="256"
			controls
			style="background-color: black;"
			[src]="'https://'+baseurl+'/ws/download/x/'+attach.opaqueid"
			type="video/mp4">
		</video>

	</div>

	<div class="row">
		<div class="col-md-10">
			<div class="col-md-12">
				<div class="row">
					<span>
						Caricato da:
						<a href="/user/edit/{{attach.uid}}">{{guest ? guest.name : '-'}}</a>
					</span>
				</div>
			</div>

			<div class="col-md-12">
				<div class="row">
					<i class="fas fa-calendar"></i>
					<span style="margin-left:5px">
						{{attach.uploaddate | date: 'dd/MM/yyyy'}}
					</span>
				</div>
			</div>

			<div class="col-md-12">
				<div class="row">
					<span>{{attach.size}}Kb</span>

				</div>

			</div>
		</div>

		<div class="col-md-2">
			<button
				class="btn btn-primary float-right mt-4"
				(click)="deleteAttach(attach)">
				<i class="fas fa-trash"></i>
			</button>
		</div>
	</div>

</div>
