<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4
			class="modal-title"
			*ngIf="!loading">
			{{'labels.' + title | translate}}
		</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div
			class="row"
			*ngIf="loading">
			<div class="col text-center">
				<app-loader [material]="false"></app-loader>
			</div>
		</div>

		<div
			class="row"
			*ngIf="!loading && conf">

			<div class="col-sm-12">
				<div class="row">

					<!-- note -->
					<div class="form-group col-md-12">
						<label>{{ 'labels.note' | translate }}</label>
						<div>{{conf.note}}</div>
					</div>

				</div>
			</div>
			<div class="col-sm-12">
				<div class="row">

					<!-- confval -->
					<div class="form-group col-md-12">
						<label>{{ 'labels.confval' | translate }}*</label>
						<input
							[(ngModel)]="conf.confval"
							#confval="ngModel"
							name="confval"
							required
							type="text"
							class="form-control"
							placeholder="{{ 'labels.confval' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && confval.invalid}"
							maxlength="128">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && confval.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>

				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-secondary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
		<button
			type="reset"
			class="btn btn-secondary">
			{{'labels.reset' | translate}}
		</button>
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
	</div>
</form>
