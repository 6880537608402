import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RangeFilter } from 'elbuild-ui-lib-core';
import { DashboardService } from 'meetravel-ui-lib';

import { DashboardTravelModalComponent } from './dashboard-travel-modal/dashboard-travel-modal.component';

@Component({
	selector: 'app-dashboard-travel',
	templateUrl: './dashboard-travel.component.html',
	styleUrls: ['./dashboard-travel.component.scss']
})
export class DashboardTravelComponent implements OnInit {

	loading = true;
	data: any;
	rangeFilter: RangeFilter;
	today: Date;
	activedFilter: number;
	type = 'all'
	_secret: boolean = false;
	_incognito: boolean = false;

	constructor(private dashboardService: DashboardService,
		private modalService: NgbModal) {
		this.today = new Date();
		this.rangeFilter = new RangeFilter();
	}

	ngOnInit() {
	}

	loadDashboard() {
		this.loading = true;
		const filter = { start: Math.trunc(this.rangeFilter.from / 1000), stop: Math.trunc(this.rangeFilter.to / 1000) };
		this.dashboardService.getTravelDashboard(filter.start, filter.stop, this.type).subscribe(result => {
			this.data = result;
			this.loading = false;
		}, error => {
			console.log('error', error);
		});
	}

	onRangeChange(event: RangeFilter) {
		this.rangeFilter = event;
		if (this.rangeFilter.from && this.rangeFilter.to) {
			this.loadDashboard();
		}
	}

	onSecretPress(value: boolean) {
		this._secret = value;
		this._incognito = false;
		if (this._secret) {
			this.type = 'secret';
		} else {
			if (!this._incognito) {
				this.type = 'all';
			}
		}
		this.loadDashboard();
	}

	onIncognitoPress(value: boolean) {
		this._incognito = value;
		this._secret = false;
		if (this._incognito) {
			this.type = 'incognito';
		} else {
			if (!this._secret) {
				this.type = 'all';
			}
		}
		this.loadDashboard();
	}


	travelModal(type: string) {
		const modal = this.modalService.open(DashboardTravelModalComponent, { centered: true, size: 'xl' });

		modal.componentInstance.rangeFilter = this.rangeFilter;
		modal.componentInstance.type = type;

		modal.result.then(() => {

		}).catch(() => {});
	}

}
