<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title">
			{{'labels.' + ((feedback?.id)?'edit':'create') | translate}} {{'labels.feedback' | translate}}
		</h5>
		<button
			type="button"
			class="close"
			data-dismiss="modal"
			(click)="dismiss()"
			aria-label="Close">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<form
			#editForm="ngForm"
			(ngSubmit)="editForm.valid && submit()">
			<div class="row form-group">
				<div class="col-md-6">
					<label for="rating">{{'labels.rating' | translate}}</label>
					<div class="input-group">
						<input
							id="rating"
							class="form-control"
							type="number"
							[min]="1"
							[max]="5"
							name="rating"
							[(ngModel)]="feedback.rating"
							(keyup)="feedback.rating > 5 ?feedback.rating = 5 :feedback.rating <1 ?feedback.rating =1 : ''"
							#rating="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && rating.invalid}"
							required>
					</div>
				</div>
				<div class="col-md-6">
					<label for="mgmtrating">{{'labels.organization' | translate}}</label>
					<div class="input-group">
						<input
							id="mgmtrating"
							type="number"
							class="form-control"
							name="compatibilitymax"
							[min]="1"
							[max]="5"
							name="mgmtrating"
							(keyup)="feedback.mgmtrating > 5 ?feedback.mgmtrating = 5 :feedback.mgmtrating <1 ?feedback.mgmtrating =1 : ''"
							[(ngModel)]="feedback.mgmtrating"
							#mgmtrating="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && mgmtrating.invalid}"
							required>
					</div>
				</div>
				<div class="col-md-12">
					<label>{{'labels.note' | translate}}</label>
					<textarea
						#note="ngModel"
						name="note"
						rows="3"
						[maxlength]="256"
						class="form-control"
						id="note"
						[(ngModel)]="feedback.note"
						[ngClass]="{'is-invalid': editForm.submitted && note.invalid}"></textarea>
				</div>
			</div>

			<div class="modal-footer">
				<button class="btn btn-primary">{{'labels.save' | translate}}</button>
				<button
					(click)="dismiss()"
					type="button"
					class="btn btn-secondary">
					{{'labels.close' | translate}}
				</button>
			</div>
		</form>
	</div>
</div>
