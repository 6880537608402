import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrudService, SharedDataService, MessageService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Regione } from 'meetravel-ui-lib';

@Component({
	selector: 'app-regione-edit',
	templateUrl: './regione-edit.component.html',
	styleUrls: ['./regione-edit.component.scss']
})
export class RegioneEditComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	regione: Regione = new Regione('');

	get title(): string {
		return this.regione?.id ? 'regione-edit' : 'regione-create';
	}


	constructor(
		private regioneService: CrudService<Regione>,
		private sharedDataService: SharedDataService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal
	) { }

	ngOnInit() { }

	ngOnDestroy(): void {
		this.sharedDataService.addCommonData('route', undefined);
	}

	load(regioneid: number): void {
		this.loading = true;
		this.regioneService.getEntity(Regione, 'regioni', regioneid).subscribe((regione: Regione) => {
			this.regione = regione;
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.regioni'));
			this.regione = undefined;
			this.loading = false;
		});
	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	submit(): void {
		if (!this.regione.id) {
			this.regioneService.createEntity(Regione, 'regioni', this.regione).subscribe((response: Regione) => {
				this.regione = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.regione-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.regione);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {
			this.regioneService.saveEntity(Regione, 'regioni', this.regione).subscribe((response: Regione) => {
				this.regione = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.regione-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.regione);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

}

