<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4
			*ngIf="!loading"
			class="modal-title">
			{{"labels."+title | translate}}
		</h4>
		<button
			(click)="dismiss()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div
			*ngIf="loading"
			class="row">
			<div class="col text-center">
				<app-loader
					[material]="false"
					[dark]="true">
				</app-loader>
			</div>
		</div>

		<div
			*ngIf="!loading && discount"
			class="row">

			<div class="form-group col-md-3">
				<label>{{ 'labels.name' | translate}}</label>
				<input
					[(ngModel)]="discount.name"
					#name="ngModel"
					name="name"
					type="text"
					class="form-control"
					maxlength="32"
					id="name"
					placeholder="{{ 'labels.name' | translate}}"
					[ngClass]="{'is-invalid': editForm.submitted && name.invalid}">
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && name.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="form-group col-md-3">
				<label>{{ 'labels.description' | translate}}</label>
				<input
					[(ngModel)]="discount.description"
					#description="ngModel"
					name="description"
					type="text"
					class="form-control"
					maxlength="32"
					id="description"
					placeholder="{{ 'labels.description' | translate}}"
					[ngClass]="{'is-invalid': editForm.submitted && description.invalid}">
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && description.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="form-group col-md-3">
				<label>{{ 'labels.link' | translate}}</label>
				<input
					[(ngModel)]="discount.link"
					#link="ngModel"
					name="link"
					type="url"
					class="form-control"
					maxlength="100"
					id="link"
					placeholder="{{ 'labels.link' | translate}}"
					[ngClass]="{'is-invalid': editForm.submitted && link.invalid}">
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && link.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="col-md-3">
				<label>{{'labels.desttype' | translate}}</label>
				<select
					class="form-control"
					name="desttype"
					[(ngModel)]="discount.desttype">
					<option ngValue="national">Nazionale</option>
					<option ngValue="international">Internazionale</option>
				</select>
			</div>

			<div *ngIf="discount.desttype === 'national'" class="form-group col-md-3">
				<input
					id="region"
					class="bootstrap"
					name="region"
					[(ngModel)]="discount.region"
					#region="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="region">
					{{'labels.regione' | translate}}
				</label>
			</div>

			<div class="form-group col-md-3">
				<label for="destination">{{ 'labels.destination' | translate }}*</label>
				<app-entity-autocomplete
					entityName="destination"
					entityField="name"
					entityFilterField="name"
					entityFilterType="like"
					[entitySearch]="discount.desttype === 'national' ? (discount.region ? regionSearch : nationalDestinationSearch) : internationalDestinationSearch"
					[entityClass]="destinationClass"
					(onSelected)="onSelectedDestination($event)"
					(onDelete)="onDeleteDestination()"
					name="destination"
					[(ngModel)]="destinationSelected"
					placeholder="{{ 'labels.destination' | translate }}">
				</app-entity-autocomplete>
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && name.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="col-md-3 form-group">
				<label>{{'labels.traveltype' | translate}}</label>
				<ng-multiselect-dropdown
					name="traveltypes"
					[placeholder]="'labels.select' | translate"
					[data]="traveltypes"
					[(ngModel)]="selectedtraveltypes"
					[settings]="typeDropdownSettings">
				</ng-multiselect-dropdown>
			</div>

			<div class="col-md-3 form-group">
				<label>{{'labels.travelaccomodation' | translate}}</label>
				<ng-multiselect-dropdown
					name="travelaccomodation"
					[placeholder]="'labels.travelaccomodation' | translate"
					[data]="accomodation"
					[(ngModel)]="selectedaccomodation"
					[settings]="typeDropdownSettings">
				</ng-multiselect-dropdown>
			</div>

			<div class="form-group col-md-3">
				<input
					id="active"
					class="bootstrap"
					name="active"
					[(ngModel)]="discount.active"
					#active="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="active">
					{{'labels.active' | translate}}
				</label>
			</div>


			<div class="form-group col-md-3">
				<input
					id="jsonfilteractive_"
					class="bootstrap"
					name="jsonfilteractive_"
					[(ngModel)]="jsonfilteractive"
					#jsonfilteractive_="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="jsonfilteractive_">
					{{'labels.jsonfilteractive' | translate}}
				</label>
			</div>
		</div>
		<div
			class="row"
			*ngIf="jsonfilteractive">
			<div class="col-md-4">
				<label>{{'labels.agemin' | translate}}</label>
				<input
					[(ngModel)]="discount.discountfilter.minage"
					#minage="ngModel"
					name="minage"
					type="number"
					class="form-control"
					id="minage"
					placeholder="{{ 'labels.agemin' | translate}}">
			</div>
			<div class="col-md-4">
				<label>{{'labels.agemax' | translate}}</label>
				<input
					[(ngModel)]="discount.discountfilter.maxage"
					#maxage="ngModel"
					name="maxage"
					type="number"
					class="form-control"
					id="maxage"
					placeholder="{{ 'labels.agemax' | translate}}">
			</div>
			<div class="col-md-4">
				<label>{{'labels.gender' | translate}}</label>
				<select
					class="form-control"
					name="type"
					[(ngModel)]="discount.discountfilter.gender">
					<option ngValue="M">Uomo</option>
					<option ngValue="F">Donna</option>
				</select>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.interests' | translate}}</label>
					<ng-multiselect-dropdown
						name="interests"
						[placeholder]="'labels.select' | translate"
						[data]="interests"
						[(ngModel)]="selectedinterests"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.travelstyle' | translate}}</label>
					<ng-multiselect-dropdown
						name="travelstyles"
						[placeholder]="'labels.select' | translate"
						[data]="travelstyles"
						[(ngModel)]="selectedtravelstyles"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.province' | translate}}</label>
					<ng-multiselect-dropdown
						name="province"
						[placeholder]="'labels.select' | translate"
						[data]="province"
						[(ngModel)]="selectedprovince"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>
			</div>
		</div>
		<div class="row">
			<div cdkDropList>

				<div
					cdkDrag
					*ngIf="discount?.id"
					class="row">

					<div class="col-md-6">
						<app-media-upload
							(done)="update($event)"
							[info]
							[url]="'/ws/travel/discount/'+discount?.id+'/upload/'"
							[entityClass]="discountClass"
							[id]="discount?.id"></app-media-upload>
					</div>
					<div class="col-md-6">
						<img
							asyncimg
							[opaque]="discount?.image"
							[alt]
							placeholder="/assets/images/travel-placeholder.png"
							size="md"
							class="img-fluid mt-3">
					</div>

				</div>

			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button
			class="btn btn-primary"
			type="submit">
			{{'labels.save' | translate}}
		</button>

		<button
			(click)="dismiss()"
			class="btn btn-secondary"
			type="button">
			{{'labels.cancel' | translate}}
		</button>
	</div>
</form>
