import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-following-list-filters',
  templateUrl: './following-list-filters.component.html',
  styleUrls: ['./following-list-filters.component.scss']
})
export class FollowingListFiltersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
