import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GuestStatus } from 'meetravel-ui-lib';

@Component({
	selector: 'app-guest-status-badge',
	templateUrl: './guest-status-badge.component.html',
	styleUrls: ['./guest-status-badge.component.scss']
})
export class GuestStatusBadgeComponent implements OnInit {

	@Input() status: string;

	GuestStatus = GuestStatus;
	@Output() changeChatStatus = new EventEmitter<string>();

	constructor() {}

	ngOnInit() {
	}

	chatStatus() {
		this.changeChatStatus.emit(this.status);
	}

	getBadgeClass() {
		switch (this.status) {
			case GuestStatus.JOINED: return 'badge-success';
			case GuestStatus.FOLLOWER: return 'badge-info';
			case GuestStatus.REJECTED: return 'badge-danger';
			case GuestStatus.REMOVED: return 'badge-danger';
			case GuestStatus.INVITED: return 'badge-primary';
			case GuestStatus.ABANDONED: return 'badge-warning';
			case GuestStatus.PARTECIPANT: return 'badge-info';
		}
	}

	getChatClass() {
		switch (this.status) {
			case GuestStatus.JOINED: return 'badge-danger';
			case GuestStatus.PARTECIPANT: return 'badge-danger';
			case GuestStatus.FOLLOWER: return 'badge-primary';
			case GuestStatus.REMOVED: return 'badge-primary';
			case GuestStatus.ABANDONED: return 'badge-primary';
			case GuestStatus.REJECTED: return 'badge-primary';
		}
	}

}
