import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { DialogService } from 'elbuild-ui-componentslib';
import { AclService, CrudService, MessageService, Search, SharedDataService } from 'elbuild-ui-lib-core';
import { Comune, Country, ProductKey, Provincia, UserService, UserStatus, UserType } from 'meetravel-ui-lib';
import { MeetravelUser1 } from '../../../model/user.model';
import { forkJoin } from 'rxjs';
import { Md5 } from 'ts-md5';

import { AdminFeatures } from '../../conf/features';

@Component({
	selector: 'app-user-new-edit',
	templateUrl: './user-new-edit.component.html',
	styleUrls: ['./user-new-edit.component.scss']
})
export class UserNewEditComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input('uid')
	set uid(value: number) {
		this.load(value);
	}

	@Input('meetraveluser')
	set meetraveluser(value: MeetravelUser1) {
		this.user = value;
		this.initItem();
	}

	@Input() utype: string;
	@Input() isStaff: boolean;
	@Input() editable: boolean = true;

	loading: boolean = false;
	user: MeetravelUser1;
	tab: string;
	title: string;
	userClass = MeetravelUser1;
	banreason: string;
	FEATURE_EDIT_UTENTI = AdminFeatures.ADMIN_EDIT_USER;
	feature_editable = false;
	situazsentimentali = ['Single', 'Impegnato/a']
	leftctr: number = 0;
	absencectr: number = 0;
	selectedProductKey: ProductKey;
	productKeys: string[] = [];
	nazioneClass: ClassType<Country> = Country;

	password: {
		password: string,
		repeatpassword: string
	};
	search: {
		nazione: Search,
		comune: Search,
		provincia: Search
	};
	selected: {
		nazione: Country,
		comune: Comune,
		provincia: Provincia,
	};


	constructor(private crudService: CrudService<MeetravelUser1>,
		private userService: UserService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private sharedDataService: SharedDataService,
		private dialogService: DialogService,
		private route: ActivatedRoute,
		private activeModal: NgbActiveModal,
		private countryService: CrudService<Country>,
		private provinceService: CrudService<Provincia>,
		private comuneService: CrudService<Comune>,
		private modalService: NgbModal,
		private cdr: ChangeDetectorRef,
		private aclService: AclService,
		private router: Router,
	) {


		this.selected = {
			nazione: new Country(),
			comune: undefined,
			provincia: undefined,
		};
		this.search = {
			nazione: new Search('country'),
			comune: new Search('comuni'),
			provincia: new Search('province')

		};
		this.password = { password: '', repeatpassword: '' };
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];
			if (id) {
				this.title = 'user-edit';
			} else {
				this.title = 'user-create';
				this.user = new MeetravelUser1();
				this.user.utype = this.utype;
			}
		});
		this.aclService.hasPermission(this.FEATURE_EDIT_UTENTI).subscribe(res => {
			this.editable = res;
		})
		Object.keys(ProductKey).map(r => this.productKeys.push(r));
	}



	ngOnDestroy() {
		this.sharedDataService.addCommonData('route', undefined);
	}

	ngAfterViewInit(): void {
		console.log("SUCA", this.user.id)
		let countleft = this.userService.getUserTravelleftCount(this.user.id);
		let countabs = this.userService.getUserTravelabsCount(this.user.id);
		forkJoin([countleft, countabs]).subscribe(results => {
			this.leftctr = results[0].ct;
			this.absencectr = results[1].ct;
			console.log(this.leftctr);
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}


	load(id: number) {
		this.crudService.getEntity(MeetravelUser1, 'user', id).subscribe(user => {
			this.user = user;
			this.initItem();
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.user'));
			this.user = undefined;
			this.loading = false;
		});
	}

	findCountry(name: any): void {
		if (name) {
			const countrySearch = new Search('country');
			countrySearch.addLikeFilter('name', name);
			this.countryService
				.searchEntities(Country, 'country', countrySearch, countrySearch.page)
				.subscribe((c: Country[]) => {
					this.selected.nazione = c[0];
				});
		}
	}

	findProvince(descrizione: any): void {
		if (descrizione) {
			const provinceSearch = new Search('province');
			provinceSearch.addLikeFilter('descrizione', descrizione);
			this.provinceService
				.searchEntities(Provincia, 'province', provinceSearch, provinceSearch.page)
				.subscribe((p: Provincia[]) => {
					this.selected.provincia = p[0];
				});
		}
	}

	findComune(descrizione: any): void {
		if (descrizione) {
			const comuneSearch = new Search('comuni');
			comuneSearch.addLikeFilter('descrizione', descrizione);
			this.comuneService
				.searchEntities(Comune, 'comuni', comuneSearch, comuneSearch.page)
				.subscribe((c: Comune[]) => {
					this.selected.comune = c[0];
				});
		}
	}

	dismiss() {
		this.activeModal.dismiss();
	}

	updateSelects() {

		if (this.selected.provincia) {
			this.search.comune.addSimpleFilter('provincia', this.selected.provincia.descrizione);
		} else {
			this.selected.comune = undefined;
		}
		if (this.selected.nazione === undefined || this.selected.nazione.name !== 'Italia') {
			this.selected.comune = undefined;
			this.selected.provincia = undefined;
		}
	}

	setSelectFields() {
		if (this.selected.nazione && this.selected.nazione.id) {
			this.user.country = this.selected.nazione.name;
			this.user.iso = this.selected.nazione.sigla;
		}
		if (this.selected.comune && this.selected.comune.id) {
			this.user.comune = this.selected.comune.descrizione;
		}
		if (this.selected.provincia && this.selected.provincia.id) {
			this.user.province = this.selected.provincia.descrizione;
		}
	}

	initItem() {
		if (this.user) {
			this.findCountry(this.user.country);
			this.findProvince(this.user.province);
			this.findComune(this.user.comune);
		}
	}

	update(event: any): void {

		if (event && event.success && event.response) {
			this.user.img = event.response.img;

		} else {
			this.messageService.sendError(
				this.translateService.instant('labels.event-upload-error')
			);
		}
	}

	open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then((result) => {
			console.log(result);
		});
	}

	banUser() {
		this.userService.ban(this.user.id, { 'reason': this.banreason }).subscribe(result => {
			this.user = result as MeetravelUser1;
			this.cdr.detectChanges();
			this.modalService.dismissAll();
			this.messageService.sendSuccess(this.translateService.instant('labels.ban-success'), this.translateService.instant('labels.user'));
		}, error => {
			this.messageService.sendError(error, this.translateService.instant('labels.user'));
		});
	}

	activateUser() {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-user-draft'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.userService.unban(this.user.id).subscribe(user => {
					this.user = user as MeetravelUser1;
					this.cdr.detectChanges();
					this.messageService.sendSuccess(this.translateService.instant('labels.user-unbanned'), this.translateService.instant('labels.user'));
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.user'));
				});
			}
		});
	}


	unbanUser() {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-user-unban'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.userService.unban(this.user.id).subscribe(user => {
					this.user = user as MeetravelUser1;
					this.cdr.detectChanges();
					this.messageService.sendSuccess(this.translateService.instant('labels.user-unbanned'), this.translateService.instant('labels.user'));
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.user'));
				});
			}
		});

	}

	activatePremium(modal: any) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-user-premium'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.userService.enablePremium(this.user.id, this.selectedProductKey).subscribe(user => {
					this.user = user;
					this.cdr.detectChanges();
					this.messageService.sendSuccess(this.translateService.instant('labels.user-premium'), this.translateService.instant('labels.user'));
					modal.dismiss();
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.user'));
				});
			}
		});
	}

	submit() {
		if (this.password && this.password.password && this.password.password !== '') {
			if (this.password.password !== this.password.repeatpassword) {
				this.messageService.sendError(this.translateService.instant('labels.password-mismatch'), this.translateService.instant('labels.create-user'));
				return;
			} else {
				this.user.password = Md5.hashAsciiStr(this.password.password).toString();
			}
		} else {
			this.user.password = undefined;
		}
		this.setSelectFields();
		if (!this.user.id) {
			if (this.utype === UserType.ADMIN) {
				this.userService.create(this.user).subscribe(response => {
					this.user = response as MeetravelUser1;
					const messages = this.translateService.instant(['labels.user-save-success', 'labels.success']);
					this.messageService.sendSuccess(messages['labels.user-save-success'], messages['labels.success']);
					this.router.navigateByUrl('/user/edit/' + this.user.id);
					this.dismiss();
				}, ({ error }) => {
					this.messageService.sendError(
						error.msg ? error.msg : error,
						this.translateService.instant('labels.event-save-error')
					);
				});
			} else {
				this.crudService.createEntity(MeetravelUser1, 'user', this.user).subscribe(response => {
					this.user = response;
					const messages = this.translateService.instant(['labels.user-save-success', 'labels.success']);
					this.messageService.sendSuccess(messages['labels.user-save-success'], messages['labels.success']);
					this.router.navigateByUrl('/user/edit/' + this.user.id);
					this.dismiss();
				}, ({ error }) => {
					this.messageService.sendError(
						error.msg ? error.msg : error,
						this.translateService.instant('labels.event-save-error')
					);
				});
			}
		} else {
			console.log(this.user);

			this.crudService.saveEntity(MeetravelUser1, 'user', this.user).subscribe(response => {
				this.user = response;
				this.initItem();
				const messages = this.translateService.instant(['labels.user-save-success', 'labels.success']);
				this.messageService.sendSuccess(messages['labels.user-save-success'], messages['labels.success']);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

	makeActive() {
		this.user.status = UserStatus.ACTIVE;
		this.user.banreason = '';
		this.submit();
	}

}
