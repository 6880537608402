import { Feature } from './feature.model';
import { Exclude, Transform } from 'class-transformer';
import { dateTransform } from 'elbuild-ui-lib-core';

export class Role {
	id: number;
	rolename: string;
	description: string;

	@Exclude({ toPlainOnly: true })
	slug: string;

	// "transient" fields
	@Exclude()
	features: RoleFeature[];
	@Exclude()
	showdetails: boolean;

	@Transform(dateTransform)
	insdate: number;

	@Transform(dateTransform)
	moddate: number;
}

export class RoleFeature {
	id: number;
	rid: number;
	fid: number;
	feature: Feature;
}

export class UserToRole {
	id: number;
	insdate: number;
	rid: number;
	uid: number;
}

export enum RoleSlug {
	ADMIN = 'admin',
	SUPER_ADMIN = 'superadmin',
}

export function role() {
	return Role;
}
