import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { TravelFeedback } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-travel-feedback-list',
	templateUrl: './travel-feedback-list.component.html',
	styleUrls: ['./travel-feedback-list.component.scss']
})
export class TravelFeedbackListComponent implements OnInit {

	@Input() travelid: number;
	loading: boolean = false;
	rows: TravelFeedback[] = [];
	advancedSearch: Search = new Search('travelfeedback');

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;

	constructor(private crudService: CrudService<TravelFeedback>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef) {

	}

	ngOnInit() {
		if (this.travelid) {
			this.advancedSearch.addSimpleFilter('travelid', this.travelid);
		}
		this.loadItems();
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.crudService.searchEntities(TravelFeedback, 'travelfeedback', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(TravelFeedback, 'travelfeedback', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			console.log(this.rows);
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {

		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.loadItems();
	}



	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;

		}
		this.loadItems();
	}


	applySearch(event: any) {
		this.advancedSearch = event;
		this.loadItems();
	}

	delete(item: TravelFeedback) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-feedback-delete'),
			hasIcon: true,
			type: 'warning'
		}).then((result) => {
			if (result) {
				this.crudService.deleteEntity(TravelFeedback, 'travelfeedback', item).subscribe((result) => {
					this.messageService.sendSuccess(this.translateService.instant('labels.delete-success'));
					this.loadItems();
				}, ({ error }) => {
					this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
				});
			}
		});
	}

	showFeedbackNote(item: TravelFeedback) {
		this.dialogService.openAlertModal({
			message: item.note,
			type: 'info',
		});
	}

}
