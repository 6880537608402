import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'meetravel-ui-lib';
import { RangeFilter } from 'elbuild-ui-lib-core';

@Component({
  selector: 'app-dashboard-chat',
  templateUrl: './dashboard-chat.component.html',
  styleUrls: ['./dashboard-chat.component.scss']
})
export class DashboardChatComponent implements OnInit {

  loading = true;
  data: any;
  rangeFilter: RangeFilter;
  today: Date;
  activedFilter: number;

  constructor(private dashboardService: DashboardService) {
    this.today = new Date();
    this.rangeFilter = new RangeFilter();
  }

  ngOnInit() {
  }

  loadDashboard() {
    this.loading = true;
    const filter = { start: Math.trunc(this.rangeFilter.from / 1000), stop: Math.trunc(this.rangeFilter.to / 1000) };
    this.dashboardService.getChatDashboard(filter.start, filter.stop).subscribe(result => {
      this.data = result;
      this.loading = false;
    }, error => {
      console.log('error', error);
    });
  }

  onRangeChange(event: RangeFilter) {
    this.rangeFilter = event;
    if (this.rangeFilter.from && this.rangeFilter.to) {
      this.loadDashboard();
    }
  }


}
