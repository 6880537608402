<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group d-flex flex-row">
					<input
						type="text"
						class="form-control d-inline"
						name="keyword"
						[(ngModel)]="searchparams.genericSearchTerm">
					<button
						type="button"
						class="btn btn-link ml-2"
						(click)="toggleAdvanced()">
						<i class="feather icon-filter"></i>

					</button>
				</div>
			</div>

		</div>
		<div [ngbCollapse]="!showAdvancedSearch">
			<div class="row">
				<div class="form-group col-md-3">
					<label>{{'labels.user' | translate}}</label>
					<app-entity-autocomplete
						[loadonfocus]="true"
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entityClass]="userClass"
						[entitySearch]="searchAuto.user"
						placement="bottom"
						name="user"
						[(ngModel)]="selected.user"
						placeholder="{{ 'labels.user' | translate }}">
					</app-entity-autocomplete>
				</div>

				<div class="form-group col-md-3">
					<label>{{'labels.travel' | translate}}</label>
					<app-entity-autocomplete
						[loadonfocus]="true"
						entityName="travel"
						entityField="name"
						entityFilterField="name"
						entityFilterField="concat(id, ' ', name)"
						entityFilterType="like"
						[entitySearch]="searchAuto.travel"
						[entityClass]="travelClass"
						name="travel"
						placement="bottom"
						[(ngModel)]="selected.travel"
						placeholder="{{ 'labels.travel' | translate }}">
					</app-entity-autocomplete>
				</div>

				<div class="form-group col-md-6">
					<label>{{'labels.purchasedate' | translate}}</label>
					<app-datepicker-range
						name="purchasedate"
						placeholder="dd/mm/yyyy"
						[(ngModel)]="purchasedate">
					</app-datepicker-range>
				</div>

				<div class="form-group col-md-3">
					<input
						id="includesubs"
						class="bootstrap"
						name="includesubs"
						[(ngModel)]="includeSubs"
						#includesubs="ngModel"
						type="checkbox">
					<label
						class="check-label ml-2"
						for="includesubs">
						{{'labels.includesubs' | translate}}
					</label>
				</div>

				<div class="form-group col-md-3">
					<input
						id="includecons"
						class="bootstrap"
						name="includecons"
						[(ngModel)]="includeCons"
						#includecons="ngModel"
						type="checkbox">
					<label
						class="check-label ml-2"
						for="includecons">
						{{'labels.includecons' | translate}}
					</label>
				</div>

				<div class="form-group col-md-3">
					<input
						id="includependings"
						class="bootstrap"
						name="includependings"
						[(ngModel)]="includePendings"
						#includependings="ngModel"
						type="checkbox">
					<label
						class="check-label ml-2"
						for="includependings">
						{{'labels.includependings' | translate}}
					</label>
				</div>
			</div>

		</div>
		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>

				<a
					class="btn btn-success float-right"
					(click)="loading ? undefined : export()">
					<i
						*ngIf="loading"
						class="fas fa-spinner fa-spin"></i>
					{{'labels.export-xlsx' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
