import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Feature } from 'src/app/@core/model/feature.model';

@Component({
	selector: 'app-new-edit-feature',
	templateUrl: './new-edit-feature.component.html',
	styleUrls: ['./new-edit-feature.component.scss']
})
export class NewEditFeatureComponent implements OnInit {

	feature: Feature;

	constructor(private activeModal: NgbActiveModal) {
	}

	ngOnInit() {

	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	close(): void {
		this.activeModal.close(this.feature);
	}

	submit() {
		this.close();
	}


	get title() {
		return this.feature.id ? 'feature-edit' : 'feature-add'
	}

}
