import { Component, OnInit } from "@angular/core";
import { DashboardService, GamificationService, GamificationUser } from "meetravel-ui-lib";
import { AclService, RangeFilter } from "elbuild-ui-lib-core";
import { DashBoardFeatures } from "../../conf/features";
import { Router } from "@angular/router";

@Component({
	selector: "app-dashboard-customer",
	templateUrl: "./dashboard-customer.component.html",
	styleUrls: ["./dashboard-customer.component.scss"],
})
export class DashboardCustomerComponent implements OnInit {
	loading = true;
	data: any;
	rangeFilter: RangeFilter;
	today: Date;
	activedFilter: number;
	gamificationUserList: GamificationUser[] = [];
	FEATURE_DASHBOARD = DashBoardFeatures.SECTION_DASHBOARD;

	constructor(private dashboardService: DashboardService,
		private aclService: AclService,
		private router: Router,
		private gamificationService: GamificationService,) {
		this.today = new Date();
		this.rangeFilter = new RangeFilter();
	}

	ngOnInit() {
		this.aclService.hasPermission(this.FEATURE_DASHBOARD).subscribe(res => {
			if (!res)
				this.router.navigateByUrl("/travel");
		})
	}

	loadDashboard() {
		this.loading = true;
		const filter = {
			start: Math.trunc(this.rangeFilter.from / 1000),
			stop: Math.trunc(this.rangeFilter.to / 1000),
		};
		this.dashboardService
			.getCustomerDashboard(filter.start, filter.stop)
			.subscribe(
				(result) => {
					this.data = result;
					this.loading = false;
				},
				(error) => {
					console.log("error", error);
				}
			);
	}

	loadGamificationUSer() {
		const filter = {
			start: Math.trunc(this.rangeFilter.from / 1000),
			stop: Math.trunc(this.rangeFilter.to / 1000),
		};
		this.gamificationService
			.getUserRanking(filter.start, filter.stop)
			.subscribe(
				(result) => {
					this.gamificationUserList = result;
				}
			)
	}

	onRangeChange(event: RangeFilter) {
		this.rangeFilter = event;
		if (this.rangeFilter.from && this.rangeFilter.to) {
			this.loadDashboard();
			this.loadGamificationUSer();
		}
	}
}
