import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Rispostetestcreator } from 'meetravel-ui-lib';

@Component({
	selector: 'app-rispostetest-modal-add-edit',
	templateUrl: './rispostetest-modal-add-edit.component.html',
	styleUrls: ['./rispostetest-modal-add-edit.component.scss']
})
export class RispostetestModalAddEditComponent implements OnInit {

	item: Rispostetestcreator;
	productClass = Rispostetestcreator;
	constructor(private activeModal: NgbActiveModal) {}

	ngOnInit() {
		if (!this.item) {
			this.item = new Rispostetestcreator();
		}
	}

	submit() {
		this.activeModal.close(this.item);
	}

	dismiss() {
		this.activeModal.dismiss();
	}

	close() {
		this.activeModal.close();
	}

}
