<div>
	<span
		class="listitem"
		*ngFor="let item of listitems">
		<input
			type="checkbox"
			[checked]="isChecked(item)"
			(click)="addDelete(item, $event.target.checked)">
		{{item.itemlabel}}
	</span>
</div>
