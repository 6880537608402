<!-- <app-datepicker-range [(ngModel)]="rangeFilter" [filters]="rangeFilters" [minDate]='minDate'  [closeOnRangeClick]="true" (ngModelChange)="onPeriodChange($event)">
</app-datepicker-range> -->
<div class="row">
	<div class="form-group col-md-4">
		<label>{{'labels.datefrom' | translate}}</label>
		<app-datepicker
			name="from"
			[(ngModel)]="rangeFilter.from">
		</app-datepicker>
	</div>

	<div class="form-group col-md-4">
		<label>{{'labels.dateto' | translate}}</label>
		<app-datepicker
			name="to"
			[(ngModel)]="rangeFilter.to">
		</app-datepicker>
	</div>

	<div class="col-md-1 mt-5">
		<i
			class="feather icon-refresh-ccw"
			aria-hidden="true"
			container="body"
			ngbTooltip="{{'labels.refresh' | translate}}"
			(click)="onPeriodChange(undefined)"></i>
	</div>

	<div
		class="dateFilter col-md-3"
		*ngIf="rangeFilters?.length>0">
		<nav>
			<div>
				<br>
				<div
					class="sub-menu-parent"
					tab-index="0">
					<button
						class="btn btn-outline-dark"
						(click)="onFilterClick(activedFilter)">
						{{rangeFilters[activedFilter].label}}
					</button>
					<div class="sub-menu">
						<div *ngFor="let item of rangeFilters; let i = index">
							<button
								class="btn btn-outline-dark"
								[ngClass]="{'active': activedFilter == i}"
								(click)="onFilterClick(i)">
								{{item.label}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</div>
