import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CrudService, MessageService } from 'elbuild-ui-lib-core';
import { Destination, Feedback } from 'meetravel-ui-lib';

@Component({
	selector: 'app-edit-feedback-modal',
	templateUrl: './edit-feedback-modal.component.html',
	styleUrls: ['./edit-feedback-modal.component.scss']
})
export class EditFeedbackModalComponent implements OnInit {

	feedback: Feedback;
	loading: boolean;
	constructor(
		private crudService: CrudService<Destination>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbActiveModal
	) {
	}

	ngOnInit() {
	}

	dismiss() {
		this.modalService.dismiss();
	}

	close() {
		this.modalService.close(this.feedback);
	}


	submit() {
		this.close()
	}


}