import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { RangeFilter } from 'elbuild-ui-lib-core';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-range-picker-filters',
    templateUrl: './range-picker-filters.component.html',
    styleUrls: ['./range-picker-filters.component.scss']
})
export class RangePickerFiltersComponent implements OnInit {
    rangeFilter: RangeFilter;
    rangeFilters: { label: string, from: number, to: number }[];
    minDate = { year: 2018, month: 1, day: 1 };
    activedFilter = 0;


    @Output() onRangeChange: EventEmitter<RangeFilter> = new EventEmitter();

    constructor() {
        this.rangeFilters = [];
        this.rangeFilter = new RangeFilter();
    }

    onPeriodChange(event) {
        this.onRangeChange.emit(this.rangeFilter);
    }

    ngOnInit() {
        this.factoryRangeFilters();
        const initFilter = this.getRangeFilter(2);
        this.activedFilter = 2;
        this.rangeFilter = { field: '', from: initFilter.from, to: initFilter.to };
        this.onRangeChange.emit(this.rangeFilter);
    }

    onFilterClick(index) {
        this.activedFilter = index;
        this.rangeFilter.from = new Date(this.rangeFilters[index].from).setHours(0, 0, 0);
        this.rangeFilter.to = new Date(this.rangeFilters[index].to).setHours(23, 59, 59);
        this.onPeriodChange(this.rangeFilter);
    }

    factoryRangeFilters() {
        this.rangeFilters.push(this.getRangeFilter(0));
        this.rangeFilters.push(this.getRangeFilter(1));
        this.rangeFilters.push(this.getRangeFilter(2));
        this.rangeFilters.push(this.getRangeFilter(3));
        this.rangeFilters.push(this.getRangeFilter(4));
        this.rangeFilters.push(this.getRangeFilter(5));
        this.rangeFilters.push(this.getRangeFilter(6));
    }

    getRangeFilter(filterIndex: number) {
        const startDate = new Date();
        const stopDate = new Date();

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        stopDate.setHours(23);
        stopDate.setMinutes(59);
        stopDate.setSeconds(59);

        let label: string;

        switch (filterIndex) {
            case 0:
                label = 'Oggi';
                break;
            case 1: {
                label = 'Ultimi 7 giorni';
                startDate.setDate(startDate.getDate() - 7);
                break;
            }
            case 2: {
                label = 'Ultimi 30 giorni';
                startDate.setMonth(startDate.getMonth() - 1);
                break;
            }
            case 3: {
                label = 'Questo mese';
                startDate.setDate(1);
                break;
            }
            case 4: {
                label = 'Ultimi 6 mesi';
                startDate.setMonth(startDate.getMonth() - 6);
                break;
            }
            case 5: {
                label = 'Ultimi 12 mesi';
                startDate.setFullYear(startDate.getFullYear() - 1);
                break;
            }
            case 6: {
                label = 'Da inizio anno';
                startDate.setMonth(0, 1);
                break;
            }
        }
        return { label: label, from: startDate.getTime(), to: stopDate.getTime() };
    }
}
