import 'reflect-metadata';

import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ElbuildCoreComponentsModule } from 'elbuild-ui-componentslib';
import { AuthConfig, ElbuildUiLibModule } from 'elbuild-ui-lib-core';
import { MeetravelUser } from 'meetravel-ui-lib';
import { ToastrModule } from 'ngx-toastr';

import { CoreModule } from './@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackofficeAuthModule } from './backoffice-auth/backoffice-auth.module';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { ChatMsgComponent } from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ChatUserListComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import { FriendComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { SharedModule } from './theme/shared/shared.module';


/* Menu Items */

const authConfig: AuthConfig = {
	cookieprefix: 'toolbandi',
	usertable: 'user',
	loginpage: 'signin',
	userclass: MeetravelUser,
	enablefeatures: true
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
	declarations: [
		AppComponent,
		AdminComponent,
		AuthComponent,
		NavigationComponent,
		NavContentComponent,
		NavGroupComponent,
		NavCollapseComponent,
		NavItemComponent,
		NavBarComponent,
		NavLeftComponent,
		NavSearchComponent,
		NavRightComponent,
		ChatUserListComponent,
		FriendComponent,
		ChatMsgComponent,
		ConfigurationComponent,
		ToggleFullScreenDirective
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		SharedModule,
		NgbDropdownModule,
		NgbTooltipModule,
		NgbButtonsModule,
		NgbTabsetModule,
		FormsModule,
		CoreModule.forRoot(),
		ElbuildUiLibModule.forRoot(authConfig),
		BackofficeAuthModule,
		ElbuildCoreComponentsModule,
		ToastrModule.forRoot({
			preventDuplicates: true
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			},
			defaultLanguage: 'it'
		}),
	],
	providers: [NavigationItem],
	bootstrap: [AppComponent]
})
export class AppModule {}
