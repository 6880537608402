<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">

			<div class="col-md-4">
				<label>{{'labels.title' | translate}}</label>
				<input
					type="text"
					class="form-control"
					[(ngModel)]="searchparams.genericSearchTerm"
					name="generic">
			</div>

		</div>

		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
