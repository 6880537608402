<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4
			class="modal-title"
			*ngIf="!loading">
			{{'labels.' + title | translate}}
		</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div
			class="row"
			*ngIf="loading">
			<div class="col text-center">
				<app-loader [material]="false"></app-loader>
			</div>
		</div>

		<div
			class="row"
			*ngIf="!loading && travel">
			<div class="col-sm-12">
				<div class="row">
					<div class="form-group col-md-12">
						<label for="status">{{ 'labels.status' | translate }}*</label>
						<select
							[(ngModel)]="adminStatus"
							#status="ngModel"
							name="status"
							class="form-control"
							id="status"
							[ngClass]="{'is-invalid': editForm.submitted && status.invalid}">
							<option
								*ngIf="travel.isSubmitted || travel.isInProgress || travel.isDeleted || travel.isClosed || travel.isDraft"
								[ngValue]="EnumAdminStatus.STATUS_ACTIVE">
								{{'types.traveladminstatus.active' | translate}}
							</option>
							<option
								*ngIf="travel.isActive || travel.isInProgress || travel.isDeleted || travel.isDraft"
								[ngValue]="EnumAdminStatus.STATUS_CLOSED">
								{{'types.traveladminstatus.closed' | translate}}
							</option>
							<option
								*ngIf="travel.isSubmitted || travel.isActive || travel.isInProgress || travel.isDraft"
								[ngValue]="EnumAdminStatus.STATUS_DELETED">
								{{'types.traveladminstatus.deleted' | translate}}
							</option>
							<option [ngValue]="EnumAdminStatus.STATUS_DRAFT">
								{{'types.traveladminstatus.draft' | translate}}
							</option>
							<option
								*ngIf="travel.isActive || travel.isDeleted || travel.isClosed || travel.isDraft"
								[ngValue]="EnumAdminStatus.STATUS_INPROGRESS">
								{{'types.traveladminstatus.inprogress' | translate}}
							</option>
							<option
								*ngIf="travel.isSubmitted"
								[ngValue]="EnumAdminStatus.STATUS_REJECTED">
								{{'types.traveladminstatus.rejected' | translate}}
							</option>
							<option
								*ngIf=" travel.isRejected"
								[ngValue]="EnumAdminStatus.STATUS_SUBMITTED">
								{{'types.traveladminstatus.submitted' | translate}}
							</option>
						</select>
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && status.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>

				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">

		<button
			type="button"
			class="btn btn-secondary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
	</div>
</form>
