import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, download, MAX_TIME, MessageService, newDate, Page, ParamsService, Search } from 'elbuild-ui-lib-core';
import { CommonService, Destination, MeetravelUser, TravelStatuses, UserType } from 'meetravel-ui-lib';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
	selector: 'app-travel-list-filters',
	templateUrl: './travel-list-filters.component.html',
	styleUrls: ['./travel-list-filters.component.scss']
})
export class TravelListFiltersComponent extends ListFiltersComponent implements OnInit {

	keyword: string;
	active: boolean = true;

	searchUsers: Search;
	userClass: ClassType<MeetravelUser> = MeetravelUser;
	userSelected: MeetravelUser;
	loading: boolean = false;
	showOnlyHappened: boolean = false;
	shownational: boolean = false;
	showmedia: boolean = false;
	showincognito: boolean = false;
	showsecret: boolean = false;
	showsmart: boolean = false;

	maxdate: any

	@Input()
	showFilters: boolean = true;


	statuses: any[] = Object.keys(TravelStatuses).map(k => TravelStatuses[k]);
	selectedStatuses = [];
	region: any[] = [];
	selectedRegion: any[] = [];
	idregioneeprovince: number[] = [];
	regionSearch: Search = new Search('destination');
	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		selectAllText: 'Seleziona tutti',
		unSelectAllText: 'Deseleziona tutti',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};

	begindate = { from: undefined, to: undefined };
	enddate = { from: undefined, to: undefined };

	budgetmax = { from: undefined, to: undefined };
	budgetmin = { from: undefined, to: undefined };

	constructor(router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private crudService: CrudService<MeetravelUser>,
		private destService: CrudService<Destination>,
		private commonService: CommonService) {
		super(router, _route, paramsService);
		this.searchUsers = new Search('user');
		this.searchUsers.addSimpleFilter('utype', UserType.USER);
		this.maxdate = MAX_TIME;
		this.regionSearch.addSimpleFilter('regione', 1);
	}

	ngOnInit() {
		super.ngOnInit();
		this.destService.searchEntities(Destination, 'destination', this.regionSearch, new Page(100)).subscribe(res => {
			this.region = res;
		});
	}

	readQueryParams(params: string) {
		super.readQueryParams(params);
		this.initValues();
	}

	regionChange(event: any) {
		console.log(event);
		this.idregioneeprovince = [];
		this.regionSearch.removeSimpleFilter('regione');
		this.regionSearch.addInFilter('parentarea', this.selectedRegion);
		this.destService.searchEntities(Destination, 'destination', this.regionSearch, new Page(100)).subscribe(res => {
			this.idregioneeprovince.push(this.selectedRegion[0]);
			res.forEach(r => {
				this.idregioneeprovince.push(r.id);
			});
		});
	}

	initValues() {
		if (this.searchparams.simpleTmp['desttype'] == 'national') {
			this.shownational = true;
		}
		if (this.searchparams.simpleTmp['media'] == 1) {
			this.showmedia = true;
		}
		if (this.searchparams.simpleTmp['happened']) {
			this.showOnlyHappened = true;
		}
		if (this.searchparams.simpleTmp['incognito']) {
			this.showincognito = true;
		}
		if (this.searchparams.simpleTmp['secret']) {
			this.showsecret = true;
		}
		if (this.searchparams.simpleTmp['smart']) {
			this.showsmart = true;
		}
		if (this.searchparams.rangeTmp['budgetmin']) {
			this.budgetmin.from = this.searchparams.rangeTmp['budgetmin'].start;
			this.budgetmin.to = this.searchparams.rangeTmp['budgetmin'].stop != (Number.MAX_SAFE_INTEGER / 100) ? this.searchparams.rangeTmp['budgetmin'].stop : undefined;
		}

		if (this.searchparams.rangeTmp['budgetmax']) {
			this.budgetmax.from = this.searchparams.rangeTmp['budgetmax'].start;
			this.budgetmax.to = this.searchparams.rangeTmp['budgetmax'].stop != (Number.MAX_SAFE_INTEGER / 100) ? this.searchparams.rangeTmp['budgetmax'].stop : undefined;
		}

		if (this.searchparams.rangeTmp['begindate']) {
			this.begindate.from = this.searchparams.rangeTmp['begindate'].start * 1000;
			this.begindate.to =
				this.searchparams.rangeTmp['begindate'].stop * 1000 == this.maxdate
					? undefined
					: this.searchparams.rangeTmp['begindate'].stop * 1000;
		}

		if (this.searchparams.rangeTmp['enddate']) {
			this.enddate.from = this.searchparams.rangeTmp['enddate'].start * 1000;
			this.enddate.to =
				this.searchparams.rangeTmp['enddate'].stop * 1000 == this.maxdate
					? undefined
					: this.searchparams.rangeTmp['enddate'].stop * 1000;
		}

		if (this.searchparams.inTmp['status']) {
			this.selectedStatuses = this.searchparams.inTmp['status'];
		}

		if (this.searchparams.simpleTmp['hostid']) {
			this.getUser(this.searchparams.simpleTmp['hostid']);
		}
	}

	getUser(id: number) {
		this.crudService.getEntity(MeetravelUser, 'user', id).subscribe(res => {
			this.userSelected = res;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.user'));
			this.userSelected = undefined;
		});
	}

	transformDecimal(value) {
		if (value) {
			return value / 100;
		}
		return undefined;
	}

	resetSearch() {
		this.keyword = undefined;
		this.active = true;
		this.begindate.from = undefined;
		this.begindate.to = undefined;
		this.enddate.from = undefined;
		this.enddate.to = undefined;
		this.budgetmin.from = undefined;
		this.budgetmin.to = undefined;
		this.budgetmax.from = undefined;
		this.budgetmax.to = undefined;
		this.showOnlyHappened = false;
		this.shownational = false;
		this.userSelected = undefined
		this.showmedia = false;
		this.showincognito = false;
		this.showsecret = false;
		this.showsmart = false;
		this.selectedStatuses = [];
		this.selectedRegion = [];
		this.idregioneeprovince = [];
		this.search();

	}

	search(keeppage?: boolean) {
		if (this.budgetmin.from || this.budgetmin.to) {
			const start = this.budgetmin.from ? this.budgetmin.from : 0;
			const stop = this.budgetmin.to ? this.budgetmin.to : Number.MAX_SAFE_INTEGER / 100;
			this.searchparams.addRangeFilter('budgetmin', start, stop);
		} else this.searchparams.removeRangeFilter('budgetmin');

		if (this.budgetmax.from || this.budgetmax.to) {
			const start = this.budgetmax.from ? this.budgetmax.from : 0;
			const stop = this.budgetmax.to ? this.budgetmax.to : Number.MAX_SAFE_INTEGER / 100;
			this.searchparams.addRangeFilter('budgetmax', start, stop);
		} else this.searchparams.removeRangeFilter('budgetmax');

		if (this.selectedStatuses.length > 0) {
			this.searchparams.addInFilter('status', this.selectedStatuses);
		} else this.searchparams.removeInFilter('status')
		if (this.idregioneeprovince.length > 0) {
			console.log(this.idregioneeprovince);
			this.searchparams.addInFilter('destination.parentarea', this.idregioneeprovince);
		} else this.searchparams.removeInFilter('destination.parentarea');
		if (this.begindate.from || this.begindate.to) {
			const start = this.begindate.from ? this.begindate.from : newDate().getTime();
			const stop = this.begindate.to ? this.begindate.to : this.maxdate;
			this.searchparams.addRangeFilter('begindate', start / 1000, Math.round(stop / 1000));
		} else this.searchparams.removeRangeFilter('begindate');


		if (this.enddate.from || this.enddate.to) {
			const start = this.enddate.from ? this.enddate.from : newDate().getTime();
			this.enddate.to.setHours(23, 59, 59);
			const stop = this.enddate.to ? this.enddate.to : this.maxdate;
			this.searchparams.addRangeFilter('enddate', start / 1000, Math.round(stop / 1000));
		} else this.searchparams.removeRangeFilter('enddate');

		if (this.showOnlyHappened) {
			this.searchparams.addSimpleFilter("happened", 1);
		} else this.searchparams.removeSimpleFilter("happened");

		if (this.shownational) {
			this.searchparams.addSimpleFilter("desttype", 'national');
		} else this.searchparams.removeSimpleFilter("desttype");
		if (this.showmedia) {
			this.searchparams.addSimpleFilter("media", 1);
		} else this.searchparams.removeSimpleFilter("media");
		if (this.userSelected) {
			this.searchparams.addSimpleFilter('hostid', this.userSelected.id);
		} else this.searchparams.removeSimpleFilter('hostid');
		if (this.showincognito) {
			this.searchparams.addSimpleFilter("incognito", 1);
		} else this.searchparams.removeSimpleFilter("incognito");
		if (this.showsecret) {
			this.searchparams.addSimpleFilter("secret", 1);
		} else this.searchparams.removeSimpleFilter("secret");
		if (this.showsmart) {
			this.searchparams.addSimpleFilter("smart", 1);
		} else this.searchparams.removeSimpleFilter("smart");


		super.search(keeppage);

	}

	export() {
		this.loading = true;
		console.log(this.searchparams);
		this.commonService.export(this.searchparams).subscribe(res => {
			this.loading = false;
			const filename = this.searchparams.table + '_' + moment().format('DDMMyyyy') + '.xlsx';
			download(res, filename);
		}, error => {
			this.messageService.sendError(error);
			this.loading = false;
		});
	}

	print() {
		console.log(this.begindate)
	}

}
