<div class="row">
	<div class="col-sm-12">
		<app-regione-list-filters
			#listfilters
			[searchparams]="advancedSearch">
		</app-regione-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<a
				class="float-right link"
				(click)="edit()">
				<i
					container="body"
					ngbTooltip="{{'labels.create' | translate}}"
					class="feather icon-plus"></i>
			</a>

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
			emptyMessage: 'labels.empty-regioni' | translate,
			totalMessage: 'labels.total' | translate
		  }">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="descrizione"
						[flexGrow]="2"
						[name]="'labels.description' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">

								<a
									(click)="edit(row.id)"
									class="link">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<a
									(click)="delete(row)"
									class="link">
									<i
										container="body"
										ngbTooltip="{{'labels.delete' | translate}}"
										class="feather icon-trash"></i>
								</a>

							</div>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
			</div>
		</app-card>
	</div>
</div>
