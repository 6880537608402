<ng-template
	[ngIf]="chartStruct?.pies?.length"
	[ngIfElse]="nodata">
	<div class="pie">
		<div>
			<h3>{{title}}</h3>
		</div>
		<div
			[id]="id"
			#chartdiv
			style="width: 100%; height: 200px"></div>
	</div>

	<span (click)="more = !more">
		{{'labels.details' | translate}}
		<span *ngIf="!more">
			<i class="ft-plus"></i>
		</span>
		<span *ngIf="more">
			<i class="ft-minus"></i>
		</span>
	</span>
	<table
		*ngIf="more"
		class="table">
		<tbody>
			<tr *ngFor="let item of ordereddata">
				<td>{{item.label}}</td>
				<td>
					{{(item.value / total)* 100 | number: '1.2-2'}} %
				</td>
			</tr>
		</tbody>
	</table>
</ng-template>

<ng-template #nodata>
	<div class="pie">
		<div>
			<h3>{{title}}</h3>
		</div>
		<div class="d-block text-center">{{ 'labels.no-value' | translate }}</div>
	</div>
</ng-template>
