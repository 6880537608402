import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClassType } from 'class-transformer/ClassTransformer';
import { Search } from 'elbuild-ui-lib-core';
import { MeetravelUser } from 'meetravel-ui-lib';

@Component({
	selector: 'app-sendpushmodal',
	templateUrl: './sendpushmodal.component.html',
	styleUrls: ['./sendpushmodal.component.scss']
})
export class SendpushmodalComponent implements OnInit {

	img: string;
	userSearch: Search = new Search('user');
	selectedUser: MeetravelUser = undefined;
	message: string = '';
	title: string = '';
	all: false;
	genericSearch: undefined;
	userClass: ClassType<MeetravelUser> = MeetravelUser;

	constructor(private activeModal: NgbActiveModal) {}

	ngOnInit() {
	}

	dismiss() {
		this.activeModal.dismiss();
	}

	submit() {
		this.activeModal.close({ userid: (this.all || this.genericSearch) ? undefined : this.selectedUser.id, message: this.message, title: this.title, all: this.all, genericSearch: this.genericSearch });
	}

	close() {
		this.activeModal.close();
	}

}
