<div class="row">
	<div class="col-sm-12">
		<app-alert-list-filters
			#listfilters
			[searchparams]="advancedSearch"
			(searchEvent)="search($event)">
		</app-alert-list-filters>
	</div>
</div>
<div
	class="container"
	*ngFor="let item of rows; let i = index"
	[ngClass]="{'darker': i%2==0}">
	<span class="float-right">
		<button
			class="btn btn-outline-info"
			container="body"
			(click)="goToTravelChat(item, $event)"
			ngbTooltip="{{'labels.go-to-travel-chat' | translate}}">
			<i class="feather icon-arrow-right"></i>
		</button>
		<br> <br>
		<button
			class="btn btn-outline-success"
			container="body"
			(click)="makeHandled(item, $event)"
			ngbTooltip="{{'labels.handle' | translate}}">
			<i class="feather icon-check"></i>
		</button>
	</span>
	<div>
		<h5>
			<a [routerLink]="['/travel/edit/'+item.travelid]">{{item.travelname}}</a>
		</h5>
	</div>
	
	<div>
		
		<span class="font-weight-bold">
			<a [routerLink]="['/user/edit/'+item?.sender]">{{item?.user?.fullname}}</a>
			:
		</span>
		
		<img
			asyncimg
			[opaque]="item?.user?.img"
			[alt]
			placeholder="/assets/images/user-placeholder.png"
			size="md"
			alt="Avatar">
			
		<p>
			{{item.message}}
			
		</p>
		
		<span class="time-right">
			{{item.insdate | date : "dd/MM/yyyy HH:mm"}}
		</span>
	</div>
</div>
<div style="overflow-x: auto; padding-right: 20px" class="container">
    <ngb-pagination
            [page]="advancedSearch.page.pageNumber + 1"
            [pageSize]="advancedSearch.page.getSize()"
            [collectionSize]="advancedSearch.page.getTotalElements()"
            (pageChange)="onPageChange($event)"
    ></ngb-pagination>
</div>

