import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Search, CrudService, Page, MessageService, SharedDataService, Staff } from 'elbuild-ui-lib-core';
import { TravelMessage, MeetravelUser, TravelMessageService } from 'meetravel-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss']
})
export class AlertListComponent implements OnInit {

  advancedSearch: Search = new Search('alert');
  rows: TravelMessage[];
  loading: boolean;

  @ViewChild('listfilters', {}) listfilters: ListFiltersComponent;

  constructor(private crudService: CrudService<TravelMessage>,
    private travelMessageService: TravelMessageService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  loadItems() {

    this.loading = true;
    this.rows = [];
    this.cdr.detectChanges();

    const datarequest = this.crudService.searchEntities(TravelMessage, 'alert', this.advancedSearch, this.advancedSearch.page);
    const countrequest = this.crudService.countEntities(TravelMessage, 'alert', this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.advancedSearch.page.setTotal(results[1].ct);
      this.rows = results[0];
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
      this.loading = false;
    });
  }

  onPageChange(event: any) {
    this.advancedSearch.page.setPageNumber(event - 1);
    this.loadItems();
  }

  search(searchParams?: any) {
    if (searchParams) {
      this.advancedSearch = searchParams;

    }
    this.loadItems();
  }

  goToTravelChat(item: TravelMessage, event: any) {
    this.router.navigateByUrl(`/travel/edit/${item.travelid}?mid=${item.id}`);
  }

  makeHandled(item: TravelMessage, event: any) {
    this.dialogService.openConfirmModal({
      message: this.translateService.instant('labels.warning-alert-checked'),
      hasIcon: true,
      type: 'warning'
    }).then(result => {
      if (result) {
        this.travelMessageService.confirm(item).subscribe(alert => {
          this.loadItems();
          this.messageService.sendSuccess(this.translateService.instant('labels.alert-handled'), this.translateService.instant('labels.alert'));
        }, error => {
          this.messageService.sendError(error, this.translateService.instant('labels.alert'));
        });
      }
    });
  }

  get page() {
    if (this.advancedSearch) {
      return this.advancedSearch.page;
    } else {
      return undefined;
    }
  }

}
