<ul
	ngbNav
	#nav="ngbNav"
	class="nav-tabs">
	<li ngbNavItem>
		<a ngbNavLink>{{'labels.details' | translate}}</a>
		<ng-template ngbNavContent>
			<div class="card">
				<div class="card-body">
					<app-user-new-edit [meetraveluser]="user"></app-user-new-edit>
				</div>
			</div>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.profiling' | translate}}</a>
		<ng-template ngbNavContent>
			<div class="card">
				<div class="card-body">
					<app-user-profiling
						(saved)="refreshUser()"
						[meetraveluser]="user"></app-user-profiling>
				</div>
			</div>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.travel-joined' | translate}}</a>
		<ng-template ngbNavContent>
			<app-user-travel
				[user]="user"
				[isJoined]="true"></app-user-travel>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>
			{{'labels.travel-abandoned' | translate}}
		</a>
		<ng-template ngbNavContent>
			<app-user-travel
				[user]="user"
				[isAbandoned]="true"></app-user-travel>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>
			{{'labels.travel-organized' | translate}}
		</a>
		<ng-template ngbNavContent>
			<app-user-travel
				[user]="user"
				[isJoined]="false"></app-user-travel>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.active-inprogress' | translate}}</a>
		<ng-template ngbNavContent>
			<app-user-travel-partecipation-list [user]="user"></app-user-travel-partecipation-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.invites-sent' | translate}}</a>
		<ng-template ngbNavContent>
			<app-guest-list
				[guestList]="sentInvites"
				[isSent]="true"></app-guest-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>
			{{'labels.invites-recived' | translate}}
		</a>
		<ng-template ngbNavContent>
			<app-guest-list
				[guestList]="recivedInvites"
				[isRecived]="true"></app-guest-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.feedback-sent' | translate}}</a>
		<ng-template ngbNavContent>
			<app-feedback-list
				[sent]="true"
				[uid]="user?.id"></app-feedback-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>
			{{'labels.feedback-received' | translate}}
		</a>
		<ng-template ngbNavContent>
			<app-feedback-list
				[received]="true"
				[uid]="user?.id"></app-feedback-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>Follower</a>
		<ng-template ngbNavContent>
			<app-follower-list
				[uid]="user?.id"
				[followerct]="user?.followerct"></app-follower-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>Seguiti</a>
		<ng-template ngbNavContent>
			<app-following-list
				[uid]="user?.id"
				[followingct]="user?.followingct"></app-following-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>Incontrati</a>
		<ng-template ngbNavContent>
			<app-met-list [uid]="user?.id"></app-met-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.wishlist-mete' | translate}}</a>
		<ng-template ngbNavContent>
			<app-wishlist [uid]="user?.id"></app-wishlist>
		</ng-template>
	</li>

	<li
		ngbNavItem
		*ngIf="!user?.isStaff">
		<a ngbNavLink>{{'labels.gamification' | translate}}</a>
		<ng-template ngbNavContent>
			<app-user-gamification [uid]="user?.id"></app-user-gamification>
		</ng-template>
	</li>

</ul>
<div [ngbNavOutlet]="nav"></div>
