<div class="card">
	<div class="card-body">
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-6">
				<app-range-picker-filters (onRangeChange)="onRangeChange($event)"></app-range-picker-filters>
			</div>
		</div>
	</div>
</div>
<app-loading
	[full]="false"
	*ngIf="loading"></app-loading>
<div *ngIf="!loading">
	<div class="row">
		<div class="col-md-4">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.acceptedinvite"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.sentinvite"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.share"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totacceptedinvite"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totsentinvite"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totshare"></app-metric>
				</div>
			</div>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.acceptedinvitechart"
				divid="acceptedinvitechart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.sentinvitechart"
				divid="sentinvitechart"></app-line-chart>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<app-line-chart
				[chartStruct]="data?.sharechart"
				divid="sharechart"></app-line-chart>
		</div>
	</div>
</div>
