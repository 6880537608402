<div class="modal-header">
	<h4 class="modal-title">{{'labels.' + type + '-travels' | translate}}</h4>
	<button
		type="button"
		class="close"
		aria-label="Close"
		(click)="dismiss()">
		<span aria-hidden="true">×</span>
	</button>
</div>
<div
	class="modal-body"
	ngbAutofocus>
	<app-travel-list
		[type]="type"
		(closeModal)="dismiss()"
		[rangeFilter]="rangeFilter"
		[showFilters]="false"></app-travel-list>
</div>
