import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { cloneDeep } from 'lodash';
import { Rispostetestcreator } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

import { RispostetestModalAddEditComponent } from '../components/rispostetest-modal-add-edit/rispostetest-modal-add-edit/rispostetest-modal-add-edit.component';


@Component({
	selector: 'app-rispostetestcreatorlist',
	templateUrl: './rispostetestcreatorlist.component.html',
	styleUrls: ['./rispostetestcreatorlist.component.scss']
})
export class RispostetestcreatorlistComponent implements OnInit {

	loading: boolean = false;
	advancedSearch: Search = new Search('rispostetestcreator');
	showAdvancedSearch: boolean = true;
	rows: Rispostetestcreator[] = [];
	did: number = undefined

	constructor(
		private crudService: CrudService<Rispostetestcreator>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private modalService: NgbModal,
		private router: Router,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,) {}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];

			if (id) {
				this.did = id
				this.advancedSearch.addSimpleFilter('did', id);
				this.loadItems(id);
			}
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.loadItems(this.did);
	}

	onActivate(event: any) {
		if (event.type === 'dblclick') {
			this.router.navigateByUrl(`/testcreator/rispostetestcreator/${event.row.id}`);
		}
	}


	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	loadItems(id: number) {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();
		const datarequest = this.crudService.searchEntities(Rispostetestcreator, 'rispostetestcreator', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(Rispostetestcreator, 'rispostetestcreator', this.advancedSearch);
		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.rows = results[0];
			this.advancedSearch.page.setTotal(results[1].ct);
			this.loading = false;
			this.cdr.detectChanges();
		}
		);
	}

	edit(item?: Rispostetestcreator) {
		const modal = this.modalService.open(RispostetestModalAddEditComponent, { size: 'lg', backdrop: 'static', centered: true, keyboard: false });
		if (item) {
			var itemCloned = cloneDeep(item);
			modal.componentInstance.item = itemCloned;
		}
		modal.result.then((result: Rispostetestcreator) => {
			console.log(result);
			if (result) {
				result.did = this.did
				const observable = result?.id ? this.crudService.saveEntity(Rispostetestcreator, 'rispostetestcreator', result) : this.crudService.createEntity(Rispostetestcreator, 'rispostetestcreator', result);
				observable.subscribe((result: Rispostetestcreator) => {
					this.loadItems(this.did);
					this.messageService.sendSuccess(this.translateService.instant('labels.rispostetestcreator-saved'),
						this.translateService.instant('labels.success'));
				});
			} else {
				this.messageService.sendError(this.translateService.instant('rispostetestcreator.not-saved'));
			}
		});
	}

	delete(row: Rispostetestcreator, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-rispostetestcreator-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.crudService.deleteEntity(Rispostetestcreator, 'rispostetestcreator', row).subscribe(() => {
					this.translateService.get(['labels.rispostetestcreator-deleted', 'labels.rispostetestcreator']).subscribe(messages => {
						this.messageService.sendSuccess(this.translateService.instant('labels.rispostetestcreator-deleted'),
							this.translateService.instant('labels.success'));
						this.loadItems(this.did);
					});
				});
			}
		});
	}


}

