<app-card
	*ngIf="showFilters"
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group d-flex flex-row">
					<input
						type="text"
						class="form-control d-inline"
						name="keyword"
						[(ngModel)]="searchparams.genericSearchTerm">
					<button
						type="button"
						class="btn btn-link ml-2"
						(click)="toggleAdvanced()">
						<i class="feather icon-filter"></i>

					</button>
				</div>
			</div>

		</div>
		<div [ngbCollapse]="!showAdvancedSearch">
			<div class="row">
				<div class="col-md-2">
					<div class="form-group">
						<label>{{'labels.organizer' | translate}}</label>
						<app-entity-autocomplete
							entityName="user"
							entityField="fullname"
							entityFilterField="concat(fname, ' ', lname)"
							entityFilterType="like"
							[entitySearch]="searchUsers"
							[entityClass]="userClass"
							placement="bottom"
							name="customer"
							[(ngModel)]="userSelected"
							placeholder="{{ 'labels.organizer' | translate }}">
						</app-entity-autocomplete>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label>{{'labels.regione' | translate}}</label>
						<select
							[(ngModel)]="selectedRegion"
							(ngModelChange)="regionChange($event)"
							#regions="ngModel"
							name="regions"
							required
							class="form-control"
							id="regions"
							[multiple]="false"
							[disabled]="false">
							<option [defaultSelected]="true"></option>
							<option
								*ngFor="let r of region"
								[value]="r.id">
								{{r.name}}
							</option>
						</select>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label>
							{{'labels.budget' | translate}} {{'labels.minimum' | translate}} ({{'labels.min' | translate}})
						</label>
						<input
							type="number"
							name="budgetminfrom"
							class="form-control"
							[(ngModel)]="budgetmin.from">
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label>
							{{'labels.budget' | translate}} {{'labels.minimum' | translate}} ({{'labels.max' | translate}})
						</label>

						<input
							type="number"
							name="budgetminto"
							class="form-control"
							[(ngModel)]="budgetmin.to">
					</div>
				</div>

				<div class="col-md-2">
					<div class="form-group">
						<label>
							{{'labels.budget' | translate}} {{'labels.maximum' | translate}} ({{'labels.min' | translate}})
						</label>
						<input
							type="number"
							name="budgetmaxfrom"
							class="form-control"
							[(ngModel)]="budgetmax.from">
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label>
							{{'labels.budget' | translate}} {{'labels.maximum' | translate}} ({{'labels.max' | translate}})
						</label>
						<input
							type="number"
							name="budgetmaxto"
							class="form-control"
							[(ngModel)]="budgetmax.to">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<label>
						{{'labels.departure' | translate}} {{'labels.min' | translate}}
					</label>
					<app-datepicker
						name="begindatestart"
						[(ngModel)]="begindate.from">
					</app-datepicker>
				</div>
				<div class="col-md-2">
					<label>
						{{'labels.departure' | translate}} {{'labels.max' | translate}}
					</label>
					<app-datepicker
						name="begindatestop"
						[disabled]="!begindate.from"
						[min]="begindate.from"
						[(ngModel)]="begindate.to">
					</app-datepicker>
				</div>
				<div class="col-md-2">
					<label>
						{{'labels.return' | translate}} {{'labels.min' | translate}}
					</label>
					<app-datepicker
						name="enddatestart"
						[(ngModel)]="enddate.from">
					</app-datepicker>
				</div>
				<div class="col-md-2">
					<label>
						{{'labels.return' | translate}} {{'labels.max' | translate}}
					</label>
					<app-datepicker
						name="enddatestop"
						[disabled]="!enddate.from"
						[min]="enddate.from"
						[(ngModel)]="enddate.to">
					</app-datepicker>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<label>{{'labels.status' | translate}}</label>
						<ng-multiselect-dropdown
							name="statuses"
							[placeholder]="'labels.select' | translate"
							[data]="statuses"
							[(ngModel)]="selectedStatuses"
							[settings]="dropdownSettings">
						</ng-multiselect-dropdown>
					</div>
				</div>
			</div>
			<div class="row">

				<div class="col-md-2">
					<input
						type="checkbox"
						name="showonlyhappened"
						class="mt-5 mr-2"
						[(ngModel)]="showOnlyHappened">

					<label>
						{{'labels.show-only-happened' | translate}}
					</label>
				</div>
				<div class="col-md-2">
					<input
						type="checkbox"
						name="shownational"
						class="mt-5 mr-2"
						[(ngModel)]="shownational">

					<label>{{'labels.show-national' | translate}}</label>
				</div>
				<div class="col-md-2">
					<input
						type="checkbox"
						name="showmedia"
						class="mt-5 mr-2"
						[(ngModel)]="showmedia">

					<label>Solo viaggi con media</label>
				</div>
				<div class="col-md-2">
					<input
						type="checkbox"
						name="showincognito"
						class="mt-5 mr-2"
						[(ngModel)]="showincognito">

					<label>Solo Viaggi in incognito</label>
				</div>
				<div class="col-md-2">
					<input
						type="checkbox"
						name="showsecret"
						class="mt-5 mr-2"
						[(ngModel)]="showsecret">

					<label>Solo viaggi segreti</label>
				</div>
				<div class="col-md-2">
					<input
						type="checkbox"
						name="showsecret"
						class="mt-5 mr-2"
						[(ngModel)]="showsmart">

					<label>Solo viaggi smart</label>
				</div>
			</div>

		</div>
		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
				<a
					class="btn btn-success float-right"
					(click)="loading ? undefined : export()">
					<i
						*ngIf='loading'
						class="fas fa-spinner fa-spin"></i>
					{{'labels.export-xlsx' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
