import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RangeFilter } from 'elbuild-ui-lib-core';

@Component({
	selector: 'app-dashboard-travel-modal',
	templateUrl: './dashboard-travel-modal.component.html',
	styleUrls: ['./dashboard-travel-modal.component.scss']
})
export class DashboardTravelModalComponent implements OnInit {

	type: string;
	rangeFilter: RangeFilter;

	constructor(private activeModal: NgbActiveModal) {}

	ngOnInit() {
	}

	dismiss() {
		this.activeModal.dismiss();
	}

}
