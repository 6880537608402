<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4
			class="modal-title"
			*ngIf="!loading">
			{{'labels.' + title | translate}}
		</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div
			class="row"
			*ngIf="loading">
			<div class="col text-center">
				<app-loader [material]="false"></app-loader>
			</div>
		</div>

		<div
			class="row"
			*ngIf="!loading && item">
			<div class="col-sm-12">
				<div class="row">

					<!-- itemlabel -->
					<div class="form-group col-md-4">
						<label>{{ 'labels.itemlabel' | translate }}*</label>
						<input
							[(ngModel)]="item.itemlabel"
							#itemlabel="ngModel"
							name="itemlabel"
							required
							type="text"
							class="form-control"
							placeholder="{{ 'labels.itemlabel' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && itemlabel.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && itemlabel.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<!-- itemvalue -->
					<div class="form-group col-md-4">
						<label>{{ 'labels.itemvalue' | translate }}*</label>
						<input
							[(ngModel)]="item.itemvalue"
							#itemvalue="ngModel"
							name="itemvalue"
							required
							type="text"
							class="form-control"
							placeholder="{{ 'labels.itemvalue' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && itemvalue.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && itemvalue.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<!-- sort -->
					<div class="form-group col-md-4">
						<label>{{ 'labels.sort' | translate }}*</label>
						<input
							[(ngModel)]="item.sort"
							#sort="ngModel"
							name="sort"
							type="number"
							class="form-control"
							placeholder="{{ 'labels.sort' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && sort.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && sort.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">

		<button
			type="reset"
			class="btn btn-secondary">
			{{'labels.reset' | translate}}
		</button>
		<button
			type="button"
			class="btn btn-secondary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
	</div>
</form>
