import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Page, Search, SortDirection } from 'elbuild-ui-lib-core';
import { GamificationBalanceResponse, Gamificationpoints, GamificationService } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-user-gamification',
	templateUrl: './user-gamification.component.html',
	styleUrls: ['./user-gamification.component.scss']
})
export class UserGamificationComponent implements OnInit {

	@Input() uid: number;

	loading: boolean = false;
	rows: Gamificationpoints[] = [];
	page = new Page();
	search = new Search('gamificationpoints');

	pointsvalue: number;
	selectedPointsid: number;

	balance: number;

	constructor(
		private crudService: CrudService<Gamificationpoints>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private gamificationService: GamificationService,
		private router: Router,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef) {
		this.search.setSortDirection(SortDirection.DESCENDING);
		this.search.setSortField('id');

	}

	ngOnInit() {
		this.search.addSimpleFilter('userid', this.uid);
		this.loadItems();
	}


	loadItems() {
		this.loading = true;
		this.rows = [];
		this.getUserBalance();
		this.cdr.detectChanges();
		const datarequest = this.crudService.searchEntities(Gamificationpoints, 'gamificationpoints', this.search, this.page);
		const countrequest = this.crudService.countEntities(Gamificationpoints, 'gamificationpoints', this.search);


		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.page.setTotal((results[1] as any).ct);
			this.rows = results[0];
			console.log(this.rows);
			this.resetValues()
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}


	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}

	rowClass = (row: any) => {
		return '';
	}

	resetValues() {
		this.pointsvalue = undefined;
		this.selectedPointsid = undefined;

	}

	getUserBalance() {
		this.gamificationService.getUserBalance(this.uid).subscribe((res: GamificationBalanceResponse) => {
			this.balance = res.balance;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
		});
	}


	editInPlace(id: number) {
		this.pointsvalue = this.rows.find(a => a.id == id).points;
		this.selectedPointsid = id;
	}

	saveItem(item: Gamificationpoints) {
		item.points = this.pointsvalue;
		this.crudService.saveEntity(Gamificationpoints, 'gamificationpoints', item).subscribe(result => {
			console.log(result);
			this.messageService.sendSuccess(
				this.translateService.instant('labels.gami-save-success'),
				this.translateService.instant('labels.success')
			);
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
		});
		this.loadItems();
	}


}
