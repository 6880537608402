<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title">
			{{'labels.' + ((destination?.id)?'edit':'create') | translate}} {{'labels.destination' | translate}}
			<i
				class="feather icon-info"
				[ngbTooltip]="'labels.destination-info' | translate"></i>
		</h5>
		<button
			type="button"
			class="close"
			data-dismiss="modal"
			(click)="dismiss()"
			aria-label="Close">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		*ngIf="!loading" 
		class="modal-body">
		<form
			#destinationForm="ngForm"
			(ngSubmit)="destinationForm.valid && submit()">
			<div class="row form-group">
				<div class="col-md-4">
					<label for="name">{{'labels.name' | translate}}</label>
					<div class="input-group">
						<input
							id="name"
							class="form-control"
							name="name"
							[(ngModel)]="destination.name"
							#name="ngModel"
							[ngClass]="{'is-invalid': destinationForm.submitted && name.invalid}"
							required>
					</div>
				</div>
				<div class="col-md-4">
					<label for="latitude">{{'labels.latitude' | translate}}</label>
					<div class="input-group">
						<input
							type="number"
							id="latitude"
							class="form-control"
							name="latitude"
							[(ngModel)]="destination.lat"
							#latitude="ngModel"
							[ngClass]="{'is-invalid': destinationForm.submitted && latitude.invalid}">
					</div>
				</div>
				<div class="col-md-4">
					<label for="longitude">{{'labels.longitude' | translate}}</label>
					<div class="input-group">
						<input
							id="longitude"
							class="form-control"
							name="longitude"
							[(ngModel)]="destination.lng"
							#longitude="ngModel"
							[ngClass]="{'is-invalid': destinationForm.submitted && longitude.invalid}">
					</div>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-4">
					<label for="type">{{'labels.type' | translate}}</label>
					<div class="input-group">
						<select
							class="form-control"
							name="type"
							[(ngModel)]="destination.type">
							<option
								*ngFor="let d of destinationType"
								[ngValue]="d.value">
								{{'labels.destination-type.'+d.value | translate}}
							</option>
						</select>
					</div>
				</div>
				<div
					*ngIf="destination.type == DestinationType.NATIONAL"
					class="col-md-4">
					<label class="fake-label"></label>
					<div class="input-group">
						<input
							id="regione"
							class="bootstrap"
							name="regione"
							[(ngModel)]="destination.regione"
							#regione="ngModel"
							type="checkbox"
							[ngClass]="{'is-invalid': destinationForm.submitted && regione.invalid}">
						<label class="check-label" for="regione">{{'labels.regione' | translate}}</label>
					</div>
				</div>
				<div
					*ngIf="destination.type == DestinationType.NATIONAL"
					class="col-md-4">
					<label class="fake-label"></label>
					<div class="input-group">
						<input
							id="province"
							class="bootstrap"
							name="province"
							[(ngModel)]="destination.province"
							#province="ngModel"
							type="checkbox"
							[ngClass]="{'is-invalid': destinationForm.submitted && province.invalid}">
						<label class="ml-2" for="province">{{'labels.province' | translate}}</label>
					</div>
				</div>
				
			</div>
			<div class="row form-group">
				<div class="col-md-4">
					<div class="form-group">
						<label>{{'labels.parentarea' | translate}}</label>
						<app-entity-autocomplete
							entityName="destination"
							entityField="name"
							entityFilterType="like"
							[entitySearch]="searchDestination"
							[entityClass]="destinationClass"
							name="parentarea"
							[(ngModel)]="destinationSelected"
							placeholder="{{ 'labels.parentarea' | translate }}">
						</app-entity-autocomplete>
					</div>
				</div>
				<div class="col-md-4">
					<label class="fake-label"></label>
					<div class="input-group">
						<input
							id="toplevelarea"
							class="bootstrap"
							name="toplevelarea"
							[(ngModel)]="destination.toplevelarea"
							#toplevelarea="ngModel"
							type="checkbox"
							[ngClass]="{'is-invalid': destinationForm.submitted && toplevelarea.invalid}">
						<label class="ml-2" for="toplevelarea">{{'labels.toplevelarea' | translate}}</label>
					</div>
				</div>
			</div>

			<div cdkDropList>

				<div
					cdkDrag
					class="row">

					<div
						class="col-md-6">
						<app-media-upload
							(done)="update($event)"
							[info]
							[url]="'/ws/destination/upload/'+destination?.id"
							[entityClass]="destinationClass"
							[id]="destination?.id"></app-media-upload>
					</div>
					<div class="col-md-6">
						<img
							asyncimg
							[opaque]="destination?.stockimg"
							[alt]
							placeholder="/assets/images/travel-placeholder.png"
							size="md"
							class="img-fluid mt-3">
					</div>

				</div>

			</div>

			<div class="modal-footer">
				<button class="btn btn-primary">{{'labels.save' | translate}}</button>
				<button
					(click)="dismiss()"
					type="button"
					class="btn btn-secondary">
					{{'labels.close' | translate}}
				</button>
			</div>
		</form>
	</div>
</div>
