<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4
			*ngIf="!loading"
			class="modal-title">
			{{"labels."+title | translate}}
		</h4>
		<button
			(click)="dismiss()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div
			*ngIf="loading"
			class="row">
			<div class="col text-center">
				<app-loader
					[material]="false"
					[dark]="true">
				</app-loader>
			</div>
		</div>

		<div
			*ngIf="!loading && banner"
			class="row">

			<div class="form-group col-md-3">
				<label>{{ 'labels.title' | translate}}</label>
				<input
					[(ngModel)]="banner.title"
					#title="ngModel"
					name="title"
					type="text"
					class="form-control"
					maxlength="32"
					id="title"
					placeholder="{{ 'labels.title' | translate}}"
					[ngClass]="{'is-invalid': editForm.submitted && title.invalid}">
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && title.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="col-md-3">
				<label>{{'labels.startdate' | translate}}*</label>
				<app-datepicker
					#fromdate="ngModel"
					name="fromdate"
					required
					[(ngModel)]="banner.fromdate"
					[ngClass]="{'is-invalid': editForm.submitted && fromdate.invalid}">

				</app-datepicker>
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && fromdate.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="col-md-3">
				<label>{{'labels.enddate' | translate}}*</label>
				<app-datepicker
					#todate="ngModel"
					name="todate"
					required
					[disabled]="!banner.fromdate"
					[min]="banner.fromdate"
					[(ngModel)]="banner.todate"
					[ngClass]="{'is-invalid': editForm.submitted && todate.invalid}">

				</app-datepicker>
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && todate.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>

			<div class="form-group col-md-3">
				<label>{{ 'labels.priority' | translate}}*</label>
				<input
					[(ngModel)]="banner.priority"
					#priority="ngModel"
					name="priority"
					type="number"
					min="0"
					class="form-control"
					id="priority"
					placeholder="{{ 'labels.priority' | translate}}">
			</div>

			<div class="form-group col-md-12">
				<label>{{ 'labels.cta' | translate}}*</label>
				<input
					[(ngModel)]="banner.cta"
					#cta="ngModel"
					name="cta"
					type="text"
					class="form-control"
					id="cta"
					required="true"
					placeholder="{{ 'labels.cta' | translate}}"
					[ngClass]="{'is-invalid': editForm.submitted && cta.invalid}">
				<div
					class="invalid-feedback"
					*ngIf="editForm.submitted && cta.invalid">
					{{'errors.required' | translate}}
				</div>
			</div>
			<div class="form-group col-md-3">
				<input
					id="showtopremium"
					class="bootstrap"
					name="showtopremium"
					[(ngModel)]="banner.showtopremium"
					#showtopremium="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="showtopremium">
					{{'labels.showtopremium' | translate}}
				</label>
			</div>
			<div class="form-group col-md-3">
				<input
					id="jsonfilteractive_"
					class="bootstrap"
					name="jsonfilteractive_"
					[(ngModel)]="jsonfilteractive"
					#jsonfilteractive_="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="jsonfilteractive_">
					{{'labels.jsonfilteractive' | translate}}
				</label>
			</div>
			<div
				class="form-group col-md-12"
				*ngIf="!banner?.id">
				<label class="text-info">
					<i
						container="body"
						ngbTooltip="{{'labels.edit' | translate}}"
						class="feather icon-alert-triangle"></i>
					{{ 'labels.to-add-media' | translate}}
				</label>
			</div>

		</div>
		<div
			class="row"
			*ngIf="jsonfilteractive">
			<div class="col-md-4">
				<label>{{'labels.minage' | translate}}</label>
				<input
					[(ngModel)]="banner.bannerfilter.minage"
					#minage="ngModel"
					name="minage"
					type="number"
					class="form-control"
					id="minage"
					placeholder="{{ 'labels.minage' | translate}}">
			</div>
			<div class="col-md-4">
				<label>{{'labels.maxage' | translate}}</label>
				<input
					[(ngModel)]="banner.bannerfilter.maxage"
					#maxage="ngModel"
					name="maxage"
					type="number"
					class="form-control"
					id="maxage"
					placeholder="{{ 'labels.maxage' | translate}}">
			</div>
			<div class="col-md-4">
				<label>{{'labels.gender' | translate}}</label>
				<select
					class="form-control"
					name="type"
					[(ngModel)]="banner.bannerfilter.gender">
					<option ngValue="M">Uomo</option>
					<option ngValue="F">Donna</option>
				</select>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.interests' | translate}}</label>
					<ng-multiselect-dropdown
						name="interests"
						[placeholder]="'labels.select' | translate"
						[data]="interests"
						[(ngModel)]="selectedinterests"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.travelstyle' | translate}}</label>
					<ng-multiselect-dropdown
						name="travelstyles"
						[placeholder]="'labels.select' | translate"
						[data]="travelstyles"
						[(ngModel)]="selectedtravelstyles"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.province' | translate}}</label>
					<ng-multiselect-dropdown
						name="province"
						[placeholder]="'labels.select' | translate"
						[data]="province"
						[(ngModel)]="selectedprovince"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>
			</div>
		</div>
		<div class="row">
			<div cdkDropList>

				<div
					cdkDrag
					*ngIf="banner?.id"
					class="row">

					<div class="col-md-6">
						<app-media-upload
							(done)="update($event)"
							[info]
							[url]="'/ws/banner/'+banner?.id+'/upload/'"
							[entityClass]="bannerClass"
							[id]="banner?.id"></app-media-upload>
					</div>
					<div class="col-md-6">
						<img
							asyncimg
							[opaque]="banner?.media"
							[alt]
							placeholder="/assets/images/travel-placeholder.png"
							size="md"
							class="img-fluid mt-3">
					</div>

				</div>

			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button
			class="btn btn-primary"
			type="submit">
			{{'labels.save' | translate}}
		</button>

		<button
			(click)="dismiss()"
			class="btn btn-secondary"
			type="button">
			{{'labels.cancel' | translate}}
		</button>
	</div>
</form>
