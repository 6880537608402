import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassType } from 'class-transformer/ClassTransformer';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, dateEndofDayTimestamp, MAX_TIME, MessageService, num2Date, ParamsService, RangeFilter, Search } from 'elbuild-ui-lib-core';
import { MeetravelUser, Travel } from 'meetravel-ui-lib';

@Component({
	selector: 'app-purchase-list-filters',
	templateUrl: './purchase-list-filters.component.html',
	styleUrls: ['./purchase-list-filters.component.scss']
})
export class PurchaseListFiltersComponent extends ListFiltersComponent implements OnInit {

	keyword: string;
	active: boolean = true;
	userClass: ClassType<MeetravelUser> = MeetravelUser;
	travelClass: ClassType<Travel> = Travel;
	purchasedate: RangeFilter;
	includeSubs: boolean = true;
	includeCons: boolean = true;
	includePendings: boolean = true;

	@Input() loading: boolean;

	@Output() exportEv: EventEmitter<any> = new EventEmitter<any>();

	searchAuto: {
		user: Search,
		travel: Search
	};

	selected: {
		user: MeetravelUser,
		travel: Travel
	};

	constructor(router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService,
		private messageService: MessageService,
		private crudService: CrudService<MeetravelUser | Travel>) {
		super(router, _route, paramsService);

		this.searchAuto = {
			user: new Search('user'),
			travel: new Search('travel'),
		};

		this.setSelected();
	}

	ngOnInit() {
		this.resetSearch();
	}

	setSelected() {
		this.selected = {
			user: undefined,
			travel: undefined
		};
	}

	readQueryParams(params: string) {
		super.readQueryParams(params);
		this.setFilters();
	}

	setFilters() {
		const rangeFilter = new RangeFilter();
		if (this.searchparams && this.searchparams.rangeTmp && this.searchparams.rangeTmp['purchasedate']) {
			const date = this.searchparams.rangeTmp['purchasedate'];
			rangeFilter.from = num2Date(date.start);
			rangeFilter.to = num2Date(date.stop);
		}
		this.purchasedate = rangeFilter;

		if (this.searchparams.simpleTmp['userid']) {
			const id = this.searchparams.simpleTmp['userid'];
			this.getUser(id);
		}

		if (this.searchparams.simpleTmp['travelid']) {
			const id = this.searchparams.simpleTmp['travelid'];
			this.getTravel(id);
		}
	}

	getUser(id: number) {
		this.crudService.getEntity(MeetravelUser, 'user', id).subscribe(result => {
			this.selected.user = result;
		}, error => {
			this.messageService.sendError(error);
		});
	}

	getTravel(id: number) {
		this.crudService.getEntity(Travel, 'travel', id).subscribe(result => {
			this.selected.travel = result;
		}, error => {
			this.messageService.sendError(error);
		});
	}

	resetSearch() {
		this.keyword = undefined;
		this.active = true;
		this.includeCons = true;
		this.includeSubs = true;
		this.includePendings = true;
		this.setSelected();
		super.resetSearch();
	}

	search(keeppage?: boolean) {
		this.setValues();
		super.search(keeppage);
	}

	setValues() {
		if (this.purchasedate && (this.purchasedate.from || this.purchasedate.to)) {
			const start = this.purchasedate.from ? this.purchasedate.from / 1000 : 0;
			const stop = this.purchasedate.to ? dateEndofDayTimestamp(this.purchasedate.to) / 1000 : MAX_TIME;
			this.searchparams.addRangeFilter('purchasedate', start, stop);
		} else {
			this.searchparams.removeRangeFilter('purchasedate');
		}

		if (this.selected.user) {
			this.searchparams.addSimpleFilter('userid', this.selected.user.id);
		} else this.searchparams.removeSimpleFilter('userid');

		if (this.selected.travel) {
			this.searchparams.addSimpleFilter('travelid', this.selected.travel.id);
		} else this.searchparams.removeSimpleFilter('travelid');
		if (!this.includeCons && !this.includeSubs) {
			this.searchparams.addInFilter('productType', []);
		}
		if (!this.includeSubs && this.includeCons) {
			this.searchparams.addInFilter('productType', ["consumable"]);
		}
		if (this.includeSubs && !this.includeCons) {
			this.searchparams.addInFilter('productType', ["subscription"]);
		}
		if (this.includeSubs && this.includeCons) {
			this.searchparams.addInFilter('productType', ["consumable", "subscription"]);
		}
		if (!this.includePendings) {
			this.searchparams.addSimpleFilter('status', 'active');
		} else {
			this.searchparams.removeSimpleFilter('status');
		}
	}

	export() {
		this.exportEv.emit();
	}

}
