<div class="row">
	<div class="col-sm-12">
		<app-feedback-list-filters
			#listfilters
			[searchparams]="advancedSearch"
			[travelid]="travelid"
			[sent]="sent"
			[received]="received"
			[uid]="uid"
			(searchEvent)="applySearch($event)">
		</app-feedback-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="advancedSearch.page.totalElements"
					[offset]="advancedSearch.page.pageNumber"
					[limit]="advancedSearch.page.size"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					(activate)="onActivate($event)"
					[messages]="{
          emptyMessage: 'labels.empty-feedback' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!sent"
						prop="sender"
						[flexGrow]="1"
						[name]="'labels.sender' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value.fullname}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!received"
						prop="receiver"
						[flexGrow]="1"
						[name]="'labels.receiver' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value.fullname}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="present"
						[flexGrow]="1"
						[name]="'labels.present' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{((value) ? 'labels.yes' : 'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="approved"
						[flexGrow]="1"
						[name]="'labels.approved' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{((value) ? 'labels.yes' : 'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="rating"
						[flexGrow]="1"
						[name]="'labels.rating' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="mgmtrating"
						[flexGrow]="1"
						[name]="'labels.organization' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="note"
						[flexGrow]="0.5"
						[name]="'labels.note' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<i
								*ngIf="value"
								container="body"
								ngbTooltip="{{value}}"
								(click)="showFeedbackNote(row)"
								class="feather icon-clipboard"></i>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!travelid"
						prop="travelid"
						[flexGrow]="0.5"
						[name]="'labels.travel' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a
								[ngbTooltip]="'labels.go-to-travel'|translate"
								[routerLink]="'/travel/edit/'+value">
								{{row.travel?.name}}
							</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a
									*ngIf="!row.approved"
									(click)="approve(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.approve' | translate}}"
										class="feather icon-thumbs-up"></i>
								</a>
								<a *ngIf="row.approved">
									<i container="body"></i>
								</a>
								<!-- empty space ↑ -->

								<a (click)="edit(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<!-- <a (click)="delete(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.delete' | translate}}"
										class="feather icon-trash"></i>
								</a> -->

							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>

</div>
