import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from 'elbuild-ui-lib-core';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mergeMap';
import { map, filter, mergeMap } from 'rxjs/operators';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	@Input() type: string;

	public navigation: any;
	breadcrumbList: Array<any> = [];
	public navigationList: Array<any> = [];

	pageInfo: any;
	section: string;

	data: any;



	constructor(private router: Router, public nav: NavigationItem, private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private sharedDataService: SharedDataService) {
		this.navigation = this.nav.get();
		this.type = 'theme2';
		this.setBreadcrumb();
	}

	ngOnInit() {
		let routerUrl: string;
		routerUrl = this.router.url.split('?')[0];
		if (routerUrl && typeof routerUrl === 'string') {
			this.filterNavigation(routerUrl);
		}

	}

	setBreadcrumb() {
		this.router.events
			.filter(event => {
				const routerUrl = event['urlAfterRedirects'];
				if (routerUrl && typeof routerUrl === 'string') {
					this.breadcrumbList.length = 0;
					const activeLink = event['url'];
					this.filterNavigation(activeLink);
				}
				return event instanceof NavigationEnd;
			})
			.pipe(map(() => this.activatedRoute)).pipe(
				map(route => {
					while (route['firstChild']) {
						route = route['firstChild'];
					}
					return route;
				})).pipe(
					filter(route => route['outlet'] === 'primary'))
			.pipe(mergeMap(route => route['data']))
			.subscribe(data => {
				this.pageInfo = event;
				if (data['title']) {
					this.translateService.get(data['title']).subscribe(message => {
						this.titleService.setTitle(message);
						this.navigationList = [
							{
								url: false,
								title: message,
								breadcrumbs: true,
								type: undefined
							}
						];
					}, () => this.titleService.setTitle(event['title']));
				}

			});
	}

	setBreadcrumb2() {
		let routerUrl: string;
		this.router.events.subscribe((router: any) => {
			routerUrl = router.urlAfterRedirects;
			if (routerUrl && typeof routerUrl === 'string') {
				this.breadcrumbList.length = 0;
				const activeLink = router.url;
				this.filterNavigation(activeLink);
			}
		});
	}

	filterNavigation(activeLink) {
		let result = [];
		let title = 'Welcome';
		this.navigation.forEach((a) => {
			if (a.type === 'item' && 'url' in a && a.url === activeLink) {
				result = [
					{
						url: ('url' in a) ? a.url : false,
						title: a.title,
						breadcrumbs: ('breadcrumbs' in a) ? a.breadcrumbs : true,
						type: a.type
					}
				];
				title = a.title;
			} else {
				if (a.type === 'group' && 'children' in a) {
					a.children.forEach((b) => {
						if (b.type === 'item' && 'url' in b && b.url === activeLink) {
							result = [
								{
									url: ('url' in b) ? b.url : false,
									title: b.title,
									breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
									type: b.type
								}
							];
							title = b.title;
						} else {
							if (b.type === 'collapse' && 'children' in b) {
								b.children.forEach((c) => {
									if (c.type === 'item' && 'url' in c && c.url === activeLink) {
										result = [
											{
												url: ('url' in b) ? b.url : false,
												title: b.title,
												breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
												type: b.type
											},
											{
												url: ('url' in c) ? c.url : false,
												title: c.title,
												breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
												type: c.type
											}
										];
										title = c.title;
									} else {
										if (c.type === 'collapse' && 'children' in c) {
											c.children.forEach((d) => {
												if (d.type === 'item' && 'url' in d && d.url === activeLink) {
													result = [
														{
															url: ('url' in b) ? b.url : false,
															title: b.title,
															breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
															type: b.type
														},
														{
															url: ('url' in c) ? c.url : false,
															title: c.title,
															breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
															type: c.type
														},
														{
															url: ('url' in d) ? d.url : false,
															title: d.title,
															breadcrumbs: ('breadcrumbs' in c) ? d.breadcrumbs : true,
															type: d.type
														}
													];
													title = d.title;
												}
											});
										}
									}
								});
							}
						}
					});
				}
			}
		});
		if (result && result.length) {
			this.navigationList = result;
		}
		if (title) {
			this.titleService.setTitle(title + ' | meeTravel');

		}


	}

}
