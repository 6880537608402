<form #editForm="ngForm" [formReset]="editForm" (ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4 class="modal-title" *ngIf="!loading">
			<span *ngIf="editable">
				{{(user.fname && user.lname) ? user.fullname : 'Nuovo utente'}} - {{user.gamificationlevel}}
				<i [ngClass]="{'low-opacity': !user.certifiedhost}"
					[ngbTooltip]="user.certifiedhost ? ('labels.hostcertificatodal'| translate:{value:user.certifiedhostdate | date:'dd/MM/yyyy'}) :'labels.hostnoncertificato'| translate"
					class="feather icon-award"></i>
			</span>
			<span *ngIf="!editable">{{'labels.user-view' | translate}}</span>
		</h4>

		<div class="text-right">

			<button class="btn btn-link" *ngIf="user?.softblock">
				<i container="body" ngbTooltip="{{'labels.softblock' | translate}}"
					class="mr-2 fa-2x feather text-danger icon-lock"></i>
			</button>

			<button *ngIf="editable && user?.id && !user?.premium" type="button" class="btn btn-primary"
				(click)="open(activatepremium)">
				{{'labels.make-premium' | translate}}
			</button>

			<button *ngIf="editable && user?.id  && user?.isActive && !user?.isStaff" type="button"
				class="btn btn-danger ml-2" (click)="open(banmodal)">
				{{'labels.ban' | translate}}
			</button>

			<button *ngIf="editable && user?.id && user?.isBanned && !user?.isStaff" type="button"
				class="btn btn-success" (click)="unbanUser()">
				{{'labels.make-active' | translate}}
			</button>

			<button *ngIf="editable && user?.id && user?.status == 'draft' && !user?.isStaff" type="button"
				class="btn btn-warning" (click)="activateUser()">
				{{'labels.make-active' | translate}}
			</button>

		</div>

	</div>
	<div class="modal-body" ngbAutofocus>
		<div class="row" *ngIf="loading">
			<div class="col text-center">
				<app-loader [material]="false"></app-loader>
			</div>
		</div>

		<div class="row" *ngIf="!loading && user">
			<div class="col-sm-12">

				<div class="row mb-4">
					<div class="col-md-4">
						<label>
							<b>Presenze:</b>
							{{user?.leftctr}}
						</label>
					</div>
					<div class="col-md-4">
						<label>
							<b>Assenze:</b>
							{{user?.absencectr}}
						</label>
					</div>
				</div>
				<div class="row">
					<!-- fname -->
					<div class="form-group col-md-4">
						<label for="cname">{{ 'labels.fname' | translate }}*</label>
						<input [(ngModel)]="user.fname" #fname="ngModel" name="fname" [disabled]="!editable" required
							type="text" class="form-control" id="cname" placeholder="{{ 'labels.fname' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && fname.invalid}">
						<div class="invalid-feedback" *ngIf="editForm.submitted && fname.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<!-- lname -->
					<div class="form-group col-md-4">
						<label for="lname">{{ 'labels.lname' | translate }}*</label>
						<input [(ngModel)]="user.lname" #lname="ngModel" name="lname" [disabled]="!editable" required
							type="text" class="form-control" id="lname" placeholder="{{ 'labels.lname' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && lname.invalid}">
						<div class="invalid-feedback" *ngIf="editForm.submitted && lname.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<div class="form-group col-md-4">
						<label for="lname">{{ 'labels.gender' | translate }}*</label>
						<select [(ngModel)]="user.gender" #gender="ngModel" name="gender" [disabled]="!editable"
							required class="form-control" id="gender"
							[ngClass]="{'is-invalid': editForm.submitted && gender.invalid}">
							<option [value]="'M'">{{'types.gender.M' | translate}}</option>
							<option [value]="'F'">{{'types.gender.F' | translate}}</option>
						</select>
						<div class="invalid-feedback" *ngIf="editForm.submitted && lname.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<div class="col-md-4">
						<label>{{'labels.birthday' | translate}}</label>
						<app-datepicker name="bday" #bday="ngModel" [disabled]="!editable" required
							[(ngModel)]="user.birthdate" [ngClass]="{'is-invalid': editForm.submitted && bday.invalid}">
						</app-datepicker>
					</div>
					<!-- mobile -->
					<div class="form-group col-md-4">
						<label for="mobile">{{ 'labels.mobile' | translate }}*</label>
						<input [(ngModel)]="user.mobile" #mobile="ngModel" name="mobile" type="text"
							[disabled]="!editable" class="form-control" id="mobile"
							placeholder="{{ 'labels.mobile' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && mobile.invalid}">
						<div class="invalid-feedback" *ngIf="editForm.submitted && mobile.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<!-- email -->
					<div class="form-group col-md-4">
						<label for="email">{{ 'labels.email' | translate }}*</label>
						<input email [(ngModel)]="user.email" #email="ngModel" name="email" [disabled]="!editable"
							required type="email" class="form-control" id="email"
							placeholder="{{ 'labels.email' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && email.invalid}">
						<div class="invalid-feedback" *ngIf="editForm.submitted && email.invalid">
							{{'errors.invalid' | translate}}
						</div>
					</div>
					<!-- landile -->
					<div class="form-group col-md-4">
						<label for="landline">{{ 'labels.landline' | translate }}</label>
						<input [(ngModel)]="user.landline" #landline="ngModel" name="landline" [disabled]="!editable"
							class="form-control" id="landline" placeholder="{{ 'labels.landline' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && landline.invalid}">
						<div class="invalid-feedback" *ngIf="editForm.submitted && landline.invalid">
							{{'errors.invalid' | translate}}
						</div>
					</div>
					<!-- authtype -->
					<div *ngIf="false" class="form-group col-md-4">
						<label for="authtype">{{ 'labels.type' | translate }}*</label>

						<app-list-items-selector list="user.type" [disabled]="!editable" name="authtype"
							[(ngModel)]="user.authtype" #authtype="ngModel"
							[inputClass]="{'is-invalid': editForm.submitted && authtype.invalid}">
						</app-list-items-selector>

						<div class="invalid-feedback" *ngIf="editForm.submitted && authtype.invalid">
							{{'errors.invalid' | translate}}
						</div>
					</div>
					<!-- country -->
					<div class="form-group col-md-4">
						<label>{{'labels.country' | translate}}</label>

						<app-entity-autocomplete (onSelected)="updateSelects();" (onDeleted)="updateSelects()"
							entityName="country" [disabled]="!editable" entityField="name" entityFilterType="like"
							[entitySearch]="search.nazione" [entityClass]="nazioneClass" name="nazione"
							[(ngModel)]="selected.nazione" placeholder>
						</app-entity-autocomplete>
					</div>
					<!-- province -->
					<div class="form-group col-md-4">
						<label>{{'labels.province' | translate}}</label>
						<app-entity-autocomplete (onSelected)="updateSelects()" (onDeleted)="updateSelects()"
							[disabled]="selected.nazione === undefined || !selected.nazione?.isItalia || !editable"
							entityName="province" entityField="descrizione" entityFilterType="like"
							[entitySearch]="search.provincia" name="provincia" [(ngModel)]="selected.provincia"
							placeholder>
						</app-entity-autocomplete>
					</div>
					<!-- city -->
					<div class="form-group col-md-4">
						<label>{{'labels.comune' | translate}}</label>
						<app-entity-autocomplete
							[disabled]="selected.nazione === undefined || selected.provincia === undefined || !editable"
							entityName="comuni" entityField="descrizione" entityFilterType="like"
							[entitySearch]="search.comune" name="comune" [(ngModel)]="selected.comune" placeholder>
						</app-entity-autocomplete>
					</div>
					<!-- address -->
					<div class="form-group col-md-4">
						<label>{{'labels.address' | translate}}</label>
						<input type="text" class="form-control" name="address" [disabled]="!editable"
							[(ngModel)]="user.address" #address="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && address.invalid}">
					</div>
					<!-- streetno -->
					<div class="form-group col-md-4">
						<label>{{'labels.streetno' | translate}}</label>
						<input type="text" class="form-control" name="streetno" [disabled]="!editable"
							[(ngModel)]="user.streetno" #streetno="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && streetno.invalid}">
					</div>
					<!-- zipcode -->
					<div class="form-group col-md-4">
						<label>{{'labels.zipcode' | translate}}</label>
						<input type="text" class="form-control" name="zipcode" [disabled]="!editable"
							[(ngModel)]="user.zipcode" #zipcode="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && zipcode.invalid}">
					</div>
					<!-- certifiedhost -->
					<div class="form-group col-md-4">
						<div class="fake-label"></div>
						<input type="checkbox" class="bootstrap" name="cert" [disabled]="!editable"
							[(ngModel)]="user.certifiedhost" #cert="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && cert.invalid}">
						<label class="checkbox-label">
							{{'labels.hostcertificato' | translate}}
						</label>
					</div>

				</div>
				<!-- password -->
				<div class="row" *ngIf="editable">
					<div class="form-group col-md-4">
						<label>{{'labels.password' | translate}}*</label>
						<input [required]="!user.id" type="password" class="form-control" [disabled]="!editable"
							name="pass" [(ngModel)]="password.password" #pass="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && pass.invalid}">
					</div>

					<div class="form-group col-md-4">
						<label>
							{{'labels.repeat-password' | translate}}
						</label>
						<input type="password" class="form-control" name="repeatpass" [disabled]="!editable"
							[required]="password.password!==''" [(ngModel)]="password.repeatpassword"
							#repeatpass="ngModel" [ngClass]="{'is-invalid': editForm.submitted && repeatpass.invalid}">
					</div>
				</div>

				<!-- instagram e situazione sentimentale -->
				<div class="row">
					<div class="form-group col-md-6">
						<label>
							<a *ngIf="user.instagramaccount"
								[href]="'https://www.instagram.com/' + user?.instagramaccount.replace('@', '')"
								target="_blank" class="link">
								{{'labels.instagram' | translate}}
							</a>
							<span *ngIf="!user.instagramaccount">{{'labels.instagram' | translate}}</span>
						</label>
						<input type="text" class="form-control" name="instagramaccount" [disabled]="!editable"
							[(ngModel)]="user.instagramaccount" #instagramaccount="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && instagramaccount.invalid}">
					</div>

					<div class="form-group col-md-6">
						<label>
							{{'labels.situazsentimentale' | translate}}
						</label>
						<select class="form-control" name="situazsentimentale" [disabled]="!editable"
							[(ngModel)]="user.relationshipstatus" #situazsentimentale="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && situazsentimentale.invalid}">
							<option *ngFor="let s of situazsentimentali" [value]="s">
								{{s}}
							</option>
						</select>
					</div>
				</div>

				<!-- bio -->

				<div class="row">
					<div class="col-md-12">
						<label>Bio</label>
						<textarea class="form-control" name="bio" [disabled]="!editable" [(ngModel)]="user.bio"
							#bio="ngModel" [ngClass]="{'is-invalid': editForm.submitted && bio.invalid}"
							placeholder="{{'labels.bio' | translate}}"></textarea>
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-md-12">
						<textarea class="form-control" name="adminnote" [disabled]="!editable"
							[(ngModel)]="user.adminnote" #adminnote="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && adminnote.invalid}"
							placeholder="{{'labels.adminnote' | translate}}"></textarea>
					</div>
				</div>

				<!-- consents -->
				<div class="row mt-3">
					<div class="form-group col-md-4">
						<div class="fake-label"></div>
						<input type="checkbox" class="bootstrap" name="promotionConsent" [disabled]="!editable"
							[(ngModel)]="user.promotionConsent" #promotionConsent="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && promotionConsent.invalid}">
						<label class="checkbox-label">
							{{'labels.consent-promotional' | translate}}
						</label>
					</div>
					<div class="form-group col-md-4">
						<div class="fake-label"></div>
						<input type="checkbox" class="bootstrap" name="customPrefConsent" [disabled]="!editable"
							[(ngModel)]="user.customPrefConsent" #customPrefConsent="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && customPrefConsent.invalid}">
						<label class="checkbox-label">
							{{'labels.consent-custom-prefs' | translate}}
						</label>
					</div>
				</div>

				<div *ngIf="!user?.isActive && user?.id">
					<hr>
					<h5>
						<i class="feather icon-alert-triangle"></i>
						{{'labels.user-banned' | translate}}
					</h5>
					<br>
					<div class="form-group">
						<label>{{'labels.banreason' | translate}}</label>
						<input type="text" class="form-control" name="banreason" required [disabled]="!editable"
							[(ngModel)]="user.banreason" #banreason="ngModel"
							[ngClass]="{'is-invalid': editForm.submitted && banreason.invalid}">
					</div>
				</div>

			</div>
		</div>
	</div>
	<div cdkDropList *ngIf="user?.id && !user?.isStaff">

		<div cdkDrag class="row">

			<div class="col-md-6" *ngIf="editable">
				<app-media-upload (done)="update($event)" [info] [url]="'/ws/user/upload/'+user?.id"
					[entityClass]="userClass" [id]="user?.id"></app-media-upload>
			</div>
			<div class="col-md-6">
				<img asyncimg [opaque]="user?.img" [alt] placeholder="/assets/images/user-placeholder.png" size="md"
					class="img-fluid mt-3">

			</div>
		</div>
	</div>
	<i *ngIf="!editable" class="ml-2" ngbTooltip="{{'labels.show_organiser' | translate}}"
		[routerLink]="['/user/edit/'+user?.id]" class="fas fa-external-link-alt"></i>
	<div class="modal-footer" *ngIf="editable">
		<button type="submit" class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
		<button (click)="dismiss()" class="btn btn-secondary">
			{{'labels.close' | translate}}
		</button>

	</div>
</form>

<ng-template #banmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">
			{{'labels.banuser' | translate}}
		</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<form #banUserForm="ngForm" (ngSubmit)="banUserForm.valid && banUser()">
			<div class="form-group">
				<label for="banreason">{{'labels.banreason' | translate}}</label>
				<div class="input-group">
					<textarea id="banreason" class="form-control" name="dp" [(ngModel)]="banreason" rows="3"
						#br="ngModel" [ngClass]="{'is-invalid': banUserForm.submitted && br.invalid}" required>
					</textarea>
				</div>

			</div>
			<div class="modal-footer">
				<button class="btn btn-outline-dark">{{'labels.ban' | translate}}</button>
			</div>
		</form>
	</div>

</ng-template>

<ng-template #activatepremium let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">
			{{'labels.activate-premium' | translate}}
		</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<form #activateUserForm="ngForm" (ngSubmit)="activateUserForm.valid && activatePremium(modal)">
			<div class="form-group">
				<label for="productkey">
					{{ 'labels.premium-duration' | translate }}*
				</label>
				<select name="productkey" class="form-control" id="productkey" required [(ngModel)]="selectedProductKey"
					#productkey="ngModel" [ngClass]="{'is-invalid': editForm.submitted && productkey.invalid}">
					<option *ngFor="let s of productKeys" [value]="s">
						{{'labels.'+s | translate}}
					</option>
				</select>

			</div>
			<div class="modal-footer">
				<button class="btn btn-outline-dark">{{'labels.activate' | translate}}</button>
			</div>
		</form>
	</div>

</ng-template>