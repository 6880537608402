<div class="modal-header">
	<h4 class="modal-title">
		{{(item?.id ? 'labels.edit-product' : 'labels.new-product') | translate}}
	</h4>
	<button
		type="button"
		class="close"
		aria-label="Close"
		(click)="dismiss()">
		<span aria-hidden="true">×</span>
	</button>
</div>
<div
	class="modal-body"
	ngbAutofocus>
	<div class="row">
		<div class="col-md-4">
			<div class="form-group">
				<label>{{'labels.name' | translate}}</label>
				<input
					type="text"
					class="form-control"
					placeholder="{{'labels.name' | translate}}"
					[(ngModel)]="item.name">
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group">
				<label>{{'labels.productkey' | translate}}</label>
				<i
					*ngIf="item.id"
					class="feather icon-info"
					ngbTooltip="Attenzione! Non si hanno i permessi per modificare la chiave prodotto."></i>
				<input
					type="text"
					class="form-control"
					placeholder="{{'labels.name' | translate}}"
					[disabled]="item.id"
					[(ngModel)]="item.productKey">
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group mt-4 pt-2">
				<input
					id="active"
					class="bootstrap"
					name="active"
					[(ngModel)]="item.active"
					#active="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="active">
					{{'labels.active' | translate}}
				</label>
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group">
				<label>{{'labels.groupname' | translate}}</label>
				<input
					type="text"
					class="form-control"
					placeholder="{{'labels.groupname' | translate}}"
					[(ngModel)]="item.groupname">
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group">
				<label>{{'labels.groupid' | translate}}</label>
				<input
					type="number"
					class="form-control"
					placeholder="{{'labels.groupid' | translate}}"
					[(ngModel)]="item.gruppo">
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group mt-4 pt-2">
				<input
					id="groupmaster"
					class="bootstrap"
					name="groupmaster"
					[(ngModel)]="item.groupmaster"
					#groupmaster="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="groupmaster">
					{{'labels.groupmaster' | translate}}
				</label>
			</div>
		</div>
	</div>

	<div
		cdkDrag
		*ngIf="item?.id"
		class="row">

		<div class="col-md-6">
			<app-media-upload
				(done)="update($event)"
				[info]
				[url]="'/ws/upload/product/'+item.id"
				[entityClass]="productClass"
				[id]="item?.id"></app-media-upload>
		</div>
		<div class="col-md-6">
			<img
				asyncimg
				[opaque]="item?.image"
				[alt]
				placeholder="/assets/images/travel-placeholder.png"
				size="md"
				class="img-fluid mt-3">
		</div>

	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="form-group">
				<label>{{'labels.description' | translate}}</label>
				<textarea
					class="form-control"
					placeholder="{{'labels.description' | translate}}"
					rows="4"
					[(ngModel)]="item.description"></textarea>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button
		class="btn btn-primary"
		(click)="submit()">
		{{'labels.save' | translate}}
	</button>

	<button
		(click)="dismiss()"
		class="btn btn-secondary"
		type="button">
		{{'labels.cancel' | translate}}
	</button>
</div>
