import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CrudService, ListItem, MessageService, Page, Search } from 'elbuild-ui-lib-core';
import { Banner, BannerFilter, Provincia } from 'meetravel-ui-lib';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
	selector: 'app-banner-add-edit-modal',
	templateUrl: './banner-add-edit-modal.component.html',
	styleUrls: ['./banner-add-edit-modal.component.scss']
})
export class BannerAddEditModalComponent implements OnInit {

	banner: Banner;
	title: string;
	loading: boolean = false;
	bannerClass = Banner;
	jsonfilteractive = false;
	interests: any;
	selectedinterests = [];
	searchInterest: Search = new Search('selistitem');
	travelstyles: any;
	selectedtravelstyles = [];
	searchTravelstyle: Search = new Search('selistitem');
	province: any;
	selectedprovince = [];
	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		selectAllText: 'Seleziona tutti',
		unSelectAllText: 'Deseleziona tutti',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};


	constructor(private activeModal: NgbActiveModal,
		private crudService: CrudService<Banner>,
		private messageService: MessageService,
		private listService: CrudService<ListItem>,
		private provinceService: CrudService<Provincia>,
		private translateService: TranslateService,) {
		this.searchInterest.addSimpleFilter('lid', 1); //ID 1 = Interessi
		this.searchTravelstyle.addSimpleFilter('lid', 2); //ID 2 = Stili di viaggio
	}

	ngOnInit() {
		if (!this.banner) {
			this.banner = new Banner();
			this.title = 'banner-create';
		} else {
			this.title = 'banner-edit';
		}
		this.jsonfilteractive = (this.banner?.jsonfilter != null) ? true : false;
		this.banner.bannerfilter = new BannerFilter(this.banner?.jsonfilter);
		if (this.jsonfilteractive) {
			this.selectedinterests = this.banner.bannerfilter?.interest;
			this.selectedtravelstyles = this.banner.bannerfilter?.traveltstyle;
			this.selectedprovince = this.banner.bannerfilter?.province;
		}
		//interests
		this.listService.searchEntities(ListItem, 'selistitem', this.searchInterest, new Page(100)).subscribe((item: ListItem) => {
			this.interests = item;
			this.interests = this.interests.map((e) => e.itemvalue);
			this.loading = false;
		});
		//travelstyles
		this.listService.searchEntities(ListItem, 'selistitem', this.searchTravelstyle, new Page(100)).subscribe((item: ListItem) => {
			this.travelstyles = item;
			this.travelstyles = this.travelstyles.map((e) => e.itemvalue);
			this.loading = false;
		}
		);
		//province
		this.provinceService.getAll(Provincia, 'province').subscribe((item: Provincia) => {
			this.province = item;
			this.province = this.province.map((e) => e.descrizione);
			this.loading = false;
		}
		);
	}

	dismiss() {
		this.activeModal.close();
	}

	verifyBanner() {
		return this.banner.cta.startsWith('https');
	}

	update(event: any): void {
		if (event && event.success && event.response) {
			this.banner.media = event.response.media;

		} else {
			this.messageService.sendError(
				this.translateService.instant('labels.event-upload-error')
			);
		}
	}

	submit() {
		if (this.verifyBanner()) {
			let observable;
			if (this.jsonfilteractive) {
				this.banner.bannerfilter.interest = this.selectedinterests;
				this.banner.bannerfilter.traveltstyle = this.selectedtravelstyles;
				this.banner.bannerfilter.province = this.selectedprovince;
				this.banner.jsonfilter = this.banner.bannerfilter.json;
			} else {
				this.banner.bannerfilter = undefined;
				this.banner.jsonfilter = undefined;
			}
			if (!this.banner.id) {
				observable = this.crudService.createEntity(Banner, 'banner', this.banner);
			} else {
				observable = this.crudService.saveEntity(Banner, 'banner', this.banner);
			}
			observable.subscribe(result => {
				this.messageService.sendSuccess(this.translateService.instant(this.banner.id ? "labels.banner-edit-completed" : "labels.banner-add-completed"));
				this.activeModal.close();
			}, ({ error }) => {
				this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			});
		} else {
			this.messageService.sendWarn('Il link non è https');
		}

	}



}
