<div class="row">
	<div class="col-sm-12">
		<app-notification-list-filters
			#listfilters
			[searchparams]="advancedSearch">
		</app-notification-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">
			<a
				class="float-right link"
				(click)="openSendPushModal()">
				<i
					container="body"
					ngbTooltip="Genera notifica push"
					class="feather icon-plus"></i>
			</a>
			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
			emptyMessage: 'labels.empty-regioni' | translate,
			totalMessage: 'labels.total' | translate
		  }">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="2"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="message"
						[flexGrow]="3"
						[name]="'labels.message' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="2"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value | date: "dd/MM/yyyy HH:mm"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">

								<button
									class="btn btn-outline-info"
									container="body"
									(click)="goToTravel(row, $event)"
									ngbTooltip="{{'labels.go-to-travel' | translate}}">
									<i class="feather icon-arrow-right"></i>
								</button>

							</div>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
			</div>
		</app-card>
	</div>
</div>
