<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label>{{'labels.user' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedSender($event)"
						(onDelete)="onDeleteSender()"
						name="customer"
						[(ngModel)]="senderSelected"
						placeholder="{{ 'labels.user' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>
		</div>
		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
