import { Component, OnInit, OnDestroy } from '@angular/core';
import { Conf } from 'elbuild-ui-lib-core';
import { CrudService, SharedDataService, MessageService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-conf-edit',
	templateUrl: './conf-edit.component.html',
	styleUrls: ['./conf-edit.component.scss']
})
export class ConfEditComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	productid: number;
	conf: Conf = new Conf();

	get title(): string {
		return this.conf?.id ? 'conf-edit' : 'conf-create';
	}


	constructor(
		private confService: CrudService<Conf>,
		private sharedDataService: SharedDataService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal
	) { }

	ngOnInit() { }

	ngOnDestroy(): void {
		this.sharedDataService.addCommonData('route', undefined);
	}

	load(confid: number): void {
		this.loading = true;
		this.confService.getEntity(Conf, 'conf', confid).subscribe((c: Conf) => {
			this.conf = c;
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.confs'));
			this.conf = undefined;
			this.loading = false;
		});
	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	submit(): void {
		if (!this.conf.id) {
			this.confService.createEntity(Conf, 'conf', this.conf).subscribe((response: Conf) => {
				this.conf = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.conf-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.conf);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {
			this.confService.saveEntity(Conf, 'conf', this.conf).subscribe((response: Conf) => {
				this.conf = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.conf-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.conf);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

}
