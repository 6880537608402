import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search, User } from 'elbuild-ui-lib-core';
import { ReportService, UserReport, UserService } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-report-list',
	templateUrl: './report-list.component.html',
	styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

	loading: boolean = false;
	rows: UserReport[] = [];
	advancedSearch: Search = new Search('userreport join user as reporter on userreport.reporterid = reporter.id');

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;

	constructor(private crudService: CrudService<UserReport>,
		private userService: UserService,
		private reportService: ReportService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.loadItems();
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.crudService.searchEntities(UserReport, 'userreport', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(UserReport, 'userreport', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			console.log(this.rows);
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {

		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.loadItems();
	}


	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;

		}
		this.loadItems();
	}


	applySearch(event: any) {
		this.advancedSearch = event;
		this.loadItems();
	}

	accept(report: UserReport, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-report-accept'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.reportService.accept(report).subscribe(results => {
					this.loadItems();
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.report'));
				});
			}
		});
	}

	decline(report: UserReport, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-report-decline'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.reportService.decline(report).subscribe(results => {
					this.loadItems();
					this.messageService.sendSuccess(this.translateService.instant('labels.report-declined'), this.translateService.instant('labels.report-declined'));
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.report'));
				});
			}
		});
	}

	unblock(row: User) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-user-unblock'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.userService.unblock(row.id).subscribe(() => {
					this.translateService.get(['labels.user-unblocked', 'labels.centri']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.user-unblocked'], messages['labels.users']);
						this.loadItems();
					});
				});
			}
		});
	}

	showReportNote(item: UserReport) {
		this.dialogService.openAlertModal({
			message: item.reportreason + ' - ' + item.reportnote,
			hasIcon: true,
			type: 'warning'
		});
	}

}
