import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, AfterViewChecked, OnDestroy } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { orderBy, cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { GenericChart } from 'meetravel-ui-lib';
/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
	selector: 'app-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

	chart: any;
	@Input() chartStruct: GenericChart;
	@Input() id: string;
	title: string;
	ordereddata: any;
	total: number = 0;
	more: boolean = false;
	viewInitialized = false;

	constructor(private translateService: TranslateService) { }

	ngOnInit() {

	}

	ngOnDestroy(): void {
		if (this.chart)
			this.chart.dispose();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.chartStruct) {
			this.title = this.chartStruct.chartname;
			if (this.chartStruct.pies && this.chartStruct.pies.length && this.viewInitialized)
				this.initChart();
		}

	}

	ngAfterViewInit() {
		this.viewInitialized = true;
		if (this.chartStruct && this.chartStruct.pies && this.chartStruct.pies.length) {
			this.initChart();
		}
	}

	initChart() {
		setTimeout(() => this.createChart());
	}

	createChart() {
		if (this.chart) this.chart.dispose();
		this.chart = am4core.create(this.id, am4charts.PieChart);

		const pieSeries = this.chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = 'value';
		pieSeries.dataFields.category = 'label';
		pieSeries.slices.template.stroke = am4core.color('#fff');
		pieSeries.slices.template.strokeWidth = 2;
		pieSeries.slices.template.strokeOpacity = 1;

		this.ordereddata = orderBy(this.chartStruct.pies, 'value', 'desc');

		const otherlabel = this.translateService.instant('labels.others');
		const other = { label: otherlabel, value: 0 };
		for (let index = 0; index < this.ordereddata.length; index++) {
			const element = this.ordereddata[index];
			if (index > 4) {
				other.value = other.value + element.value;
			}
			this.total = this.total + element.value;
		}

		if (this.chartStruct.pies.length > 5) {
			this.chart.data = cloneDeep(this.ordereddata.slice(0, 5));
			this.chart.data.push(other);
		} else {
			this.chart.data = this.ordereddata;
		}

		// This creates initial animation
		pieSeries.hiddenState.properties.opacity = 1;
		pieSeries.hiddenState.properties.endAngle = -90;
		pieSeries.hiddenState.properties.startAngle = -90;
	}
}
