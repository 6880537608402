<h4 class="mt-3">
	<span>{{ "labels.profiling" | translate }}</span>
</h4>
<hr>
<table class="table table-borderless table-sm">
	<tbody>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.bio" | translate }}</label>
			</td>
			<td
				style="white-space: pre-line"
				class="col-md-12">
				<div style="display: flex; flex-direction: row">

					<p
						style="width: '100%';"
						*ngIf="!editMode"
						(click)="editMode = true">
						{{ meetraveluser?.bio }}
					</p>

					<textarea
						*ngIf="editMode"
						name="bio"
						style="padding: 10px; width: '100%';"
						rows="5"
						[(ngModel)]="meetraveluser.bio"></textarea>
					<div
						class="icon-buttons"
						*ngIf="!editMode && feature_editable">
						<a
							(click)="editMode = true"
							class="link">
							<i
								container="body"
								ngbTooltip="{{ 'labels.edit' | translate }}"
								class="feather icon-edit"></i>
						</a>
					</div>
					<div
						class="icon-buttons"
						*ngIf="editMode">
						<a
							(click)="editMode = false"
							class="link">
							<i
								container="body"
								ngbTooltip="{{ 'labels.cancel' | translate }}"
								class="feather icon-x-circle"></i>
						</a>
					</div>
				</div>
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.itatravel" | translate }}</label>
			</td>
			<td>
				<select
					class="form-control"
					[disabled]="!feature_editable"
					[(ngModel)]="meetraveluser.itatravel">
					<option
						*ngFor="let opt of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
						[value]="opt"
						[selected]="meetraveluser?.itatravel == opt">
						{{ opt }}
					</option>
				</select>
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.eutravel" | translate }}</label>
			</td>
			<td>
				<select
					class="form-control"
					[disabled]="!feature_editable"
					[(ngModel)]="meetraveluser.eutravel">
					<option
						*ngFor="let opt of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
						[value]="opt"
						[selected]="meetraveluser?.eutravel == opt">
						{{ opt }}
					</option>
				</select>
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.intltravel" | translate }}</label>
			</td>
			<td>
				<select
					class="form-control"
					[disabled]="!feature_editable"
					[(ngModel)]="meetraveluser.intltravel">
					<option
						*ngFor="let opt of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
						[value]="opt"
						[selected]="meetraveluser?.intltravel == opt">
						{{ opt }}
					</option>
				</select>
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.leftctr" | translate }}</label>
			</td>
			<td class="col-md-2">
				<input
					name="leftctr"
					[disabled]="!feature_editable"
					min="0"
					class="form-control"
					[(ngModel)]="leftctr"
					type="number">
			</td>
			<td class="col-md-1">
				<i
					*ngIf="meetraveluser.note"
					container="body"
					ngbTooltip="{{meetraveluser.note}}"
					class="fas fa-exclamation-triangle notetool"></i>
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.absencectr" | translate }}</label>
			</td>
			<td class="col-md-2">
				<input
					name="absencectr"
					[disabled]="!feature_editable"
					min="0"
					class="form-control"
					[(ngModel)]="absencectr"
					type="number">
			</td>
			<td class="col-md-1">
				<i
					*ngIf="meetraveluser.note"
					container="body"
					ngbTooltip="{{meetraveluser.note}}"
					class="fas fa-exclamation-triangle notetool"></i>
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.avgtravel" | translate }}</label>
			</td>
			<td>
				<input
					name="avgtravel"
					min="0"
					class="form-control"
					[(ngModel)]="meetraveluser.avgtravel"
					type="number">
				<!-- {{ meetraveluser?.avgtravel | number: "1.2-2" }} -->

			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.avgplanning" | translate }}</label>
			</td>
			<td>
				<input
					name="avgtravel"
					min="0"
					class="form-control"
					[(ngModel)]="meetraveluser.avgplanning"
					type="number">
				<!-- {{ meetraveluser?.avgplanning | number: "1.2-2" }} -->
			</td>
		</tr>
		<tr class="row">
			<td class="col-md-4">
				<label class="font-weight-bold">{{ "labels.travelstyle" | translate }}</label>
			</td>
			<td>
				<app-list-items-selector
					*ngIf="traveltype"
					list="user.types"
					[disabled]="!feature_editable"
					name="traveltype"
					entity="selistitem"
					parent="selist"
					maxRange="100"
					(ngModelChange)="travelTypeChange($event)"
					[(ngModel)]="traveltype.traveltype">
				</app-list-items-selector>
			</td>
		</tr>
	</tbody>
</table>
<br>
<br>
<h4 class="mt-3">
	<span>{{ "labels.interests" | translate }}</span>
</h4>
<hr>

<div *ngIf="interestsList">
	<div *ngIf="feature_editable">
		<tag-input
			[placeholder]="'labels.add-new-tag' | translate"
			[secondaryPlaceholder]="'labels.add-interests' | translate"
			[(ngModel)]="userInterestsChips"
			name="contact"
			required
			#contacts="ngModel"
			[onlyFromAutocomplete]="true"
			[inputClass]="'is-invalid'"
			(onAdd)="addInterest($event)"
			(onRemove)="removeInterest($event)">
			<tag-input-dropdown
				[showDropdownIfEmpty]="true"
				[keepOpen]="true"
				[dynamicUpdate]="false"
				[autocompleteItems]="interestsList">
			</tag-input-dropdown>
		</tag-input>
	</div>
</div>
<br>
<div class="text-right">
	<button
		class="btn btn-primary"
		*ngIf="feature_editable"
		(click)="save()">
		{{ "labels.save" | translate }}
	</button>
</div>
