import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Search, ParamsService } from 'elbuild-ui-lib-core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
	selector: 'app-destination-list-filters',
	templateUrl: './destination-list-filters.component.html',
	styleUrls: ['./destination-list-filters.component.scss']
})
export class DestinationListFiltersComponent extends ListFiltersComponent implements OnInit {

	keyword: string;
	active: boolean = true;

	@Output() searchEvent = new EventEmitter<Search>();


	constructor(router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService) {
		super(router, _route, paramsService);
	}

	ngOnInit() {
		this.resetSearch();
	}

	resetSearch() {
		this.keyword = undefined;
		this.active = true;
		this.searchparams.reset();
		this.search();
	}

	search(keeppage?: boolean) {
		this.searchEvent.emit(this.searchparams);
	}

}
