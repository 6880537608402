<div class="row ">
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<app-loading
				[full]="false"
				*ngIf="loading"></app-loading>

			<div
				*ngIf="!loading"
				class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows_level"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[rowClass]="rowClass"
					[messages]="{
          emptyMessage: 'labels.empt-points' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="levelname"
						[flexGrow]="1"
						[name]="'labels.levelname' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
							<i
								*ngIf="row.description"
								container="body"
								ngbTooltip="{{row.description}}"
								(click)="showNote(row.description)"
								class="feather icon-clipboard"></i>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="threshold"
						[flexGrow]="1"
						[name]="'labels.sogliapunteggio' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<label *ngIf="row.id!=selectedThresholdid">{{value}}</label>
							<input
								*ngIf="row.id==selectedThresholdid"
								type="number"
								[(ngModel)]="thresholdvalue"
								[min]="0"
								[max]="100000"
								(keyup)="thresholdvalue > 100000 ? thresholdvalue = 100000 :thresholdvalue <1 ?thresholdvalue =undefined : ''"
								name="threshold{{row.id}}"
								class="form-control"
								id="threshold"
								placeholder="Inserisci threshold">

						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : "dd/MM/yyyy"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a
									(click)="editInPlace(row.id)"
									*ngIf="row.id != selectedThresholdid">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<a
									(click)="saveLevel(row)"
									*ngIf="row.id == selectedThresholdid">
									<i
										container="body"
										ngbTooltip="{{'labels.save' | translate}}"
										class="feather icon-check"></i>
								</a>
							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>
</div>
<hr>
<div class="row">

	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">
			<app-loading
				[full]="false"
				*ngIf="loading"></app-loading>

			<div
				*ngIf="!loading"
				class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows_metric"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[rowClass]="rowClass"
					[messages]="{
                    emptyMessage: 'labels.empty-points' | translate,
                    totalMessage: 'labels.total' | translate
		            }">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="code"
						[flexGrow]="1"
						[name]="'labels.code' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="metric"
						[flexGrow]="1"
						[name]="'labels.attivita' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="points"
						[flexGrow]="1.5"
						[sortable]="false"
						[name]="'labels.punteggioassegnato' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<label *ngIf="row.id!=selectedPointsid">{{value}}</label>
							<input
								*ngIf="row.id==selectedPointsid"
								type="number"
								[(ngModel)]="pointsvalue"
								[min]="0"
								[max]="100000"
								(keyup)="pointsvalue > 100000 ? pointsvalue = 100000 :pointsvalue <1 ?pointsvalue =undefined : ''"
								name="points{{row.id}}"
								class="form-control"
								id="points"
								placeholder="Inserisci punti">
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="disclaimer"
						[flexGrow]="1"
						[name]="'labels.modalita' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : "dd/MM/yyyy"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a
									*ngIf="row.id != selectedPointsid"
									(click)="editInPlace(row.id,'points')">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<a
									(click)="saveMatric(row)"
									*ngIf="row.id == selectedPointsid">
									<i
										container="body"
										ngbTooltip="{{'labels.save' | translate}}"
										class="feather icon-check"></i>
								</a>
							</div>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
			</div>
		</app-card>
	</div>
</div>
