<div class="row">
	<div class="col-sm-12">
		<app-purchase-list-filters
			#listfilters
			(exportEv)="export()"
			[loading]="exporting"
			[searchparams]="advancedSearch">
		</app-purchase-list-filters>
	</div>
</div>

<div class="row ">
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<app-loading
				[full]="false"
				*ngIf="loading"></app-loading>

			<div
				*ngIf="!loading"
				class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-puchase' | translate,
          totalMessage: 'labels.total' | translate
		}">

					<ngx-datatable-column
						prop="checked"
						[flexGrow]="0.4"
						[name]="''"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<input
								type="checkbox"
								class="bootstrap mt-1"
								(click)="toggleCheckbox(row)"
								[checked]="value">
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="productKey"
						[flexGrow]="1"
						[name]="'labels.productkey' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="userid"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.user' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a routerLink="/user/edit/{{value}}">{{row.user?.fullname}} ({{value}})</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.purchasedate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date:"dd/MM/yyyy HH:mm"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="productType"
						[flexGrow]="1"
						[name]="'labels.producttype' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{"labels." + value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="expireDate"
						[flexGrow]="1"
						[name]="'labels.expiredate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date:"dd/MM/yyyy HH:mm"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="travelid"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.travel' | translate"
						[resizeable]="false">
						<ng-template
							let-row="row"
							let-value="value"
							ngx-datatable-cell-template>
							<a
								*ngIf="value"
								routerLink="/travel/edit/{{value}}">
								{{row.travel?.name}} ({{value}})
							</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="platform"
						[flexGrow]="1"
						[name]="'labels.platform' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{"labels." + value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="status"
						[flexGrow]="1"
						[name]="'labels.status' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="receipt"
						[flexGrow]="1"
						[headerClass]="'text-center'"
						[cellClass]="'text-center'"
						[name]="'labels.receipt' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<ng-template [ngIf]="value">
								<i
									(click)="openModal(row)"
									container="body"
									ngbTooltip="{{'labels.view' | translate}}"
									class="fas fa-receipt link"></i>
								<i
									(click)="copy(row)"
									container="body"
									ngbTooltip="{{'labels.copy' | translate}}"
									class="far fa-copy ml-3 link"></i>
							</ng-template>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>
</div>
