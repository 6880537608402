import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Search, User, CrudService, MessageService, SharedDataService, SortDirection } from 'elbuild-ui-lib-core';
import { Subscription, Observable, combineLatest, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfEditComponent } from '../../conf/conf-edit/conf-edit.component';
import { Conf } from 'elbuild-ui-lib-core';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
	selector: 'app-conf-list',
	templateUrl: './conf-list.component.html',
	styleUrls: ['./conf-list.component.scss']
})
export class ConfListComponent implements OnInit, AfterViewInit, OnDestroy {

	loading: boolean = false;
	rows: Conf[];

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
	showAdvancedSearch: boolean = true;

	advancedSearch: Search = new Search('conf', `concat(confkey,' ',confval)`, 'conf');

	loggedUser: User;
	subcription: Subscription;

	constructor(
		private confService: CrudService<Conf>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private sharedDataService: SharedDataService
	) {

		this.advancedSearch.setSortDirection(SortDirection.ASCENDING);
		this.advancedSearch.setSortField('id');
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams();

		const requests: Observable<any>[] = [urlparamsreq];

		if (!this.loggedUser) {
			const userrequest = this.sharedDataService.currentLoggedUser;
			requests.push(userrequest);
		}

		this.subcription = combineLatest(requests).subscribe(data => {

			if (data[1]) {
				this.loggedUser = data[1];
			}

			this.search(data[0]);

		});
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		if (this.subcription) {
			this.subcription.unsubscribe();
		}
	}

	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;
		}
		this.load();
	}

	load() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		this.advancedSearch.addSimpleFilter('pub', 1);

		const datarequest = this.confService.searchEntities(Conf, 'conf', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.confService.countEntities(Conf, 'conf', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];

			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.load();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.load();
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	/* delete(row: Conf) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-conf-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.confService.deleteEntity(Conf, 'conf', row).subscribe(() => {
					this.translateService.get(['labels.conf-deleted', 'labels.confs']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.conf-deleted'], messages['labels.confs']);
						this.load();
					});
				});
			}
		});
	} */


	edit(confid?: number) {
		const modal = this.modalService.open(ConfEditComponent, { centered: true, size: 'md' });

		if (confid) {
			modal.componentInstance.load(confid);
		}
		modal.result.then((newItem: Conf) => {
			this.load();
		}).catch(() => { });
	}

}
