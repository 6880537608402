import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search, SharedDataService, SortDirection, User } from 'elbuild-ui-lib-core';
import { forkJoin, Subscription } from 'rxjs';
import { DiscountAddEditModalComponent } from '../discount-add-edit-modal/discount-add-edit-modal.component';

interface DiscountFilterModel {
    gender: string;
    minage: number;
    maxage: number;
    interest: string[];
    traveltstyle: string[];
    province: string[];
}

export class DiscountFilter implements DiscountFilterModel {
    gender: string;
    minage: number;
    maxage: number;
    interest: string[];
    traveltstyle: string[];
    province: string[];

    constructor(json?: string) {
        let obj: DiscountFilterModel | undefined;

        if (json) {
            obj = JSON.parse(json);
        }
        console.log(obj);
        this.gender = (obj === null || obj === void 0 ? void 0 : obj.gender) ? obj.gender : undefined;
        this.minage = (obj === null || obj === void 0 ? void 0 : obj.minage) ? obj.minage : 18;
        this.maxage = (obj === null || obj === void 0 ? void 0 : obj.maxage) ? obj.maxage : 99;
        this.interest = (obj === null || obj === void 0 ? void 0 : obj.interest) ? obj.interest : [];
        this.traveltstyle = (obj === null || obj === void 0 ? void 0 : obj.traveltstyle) ? obj.traveltstyle : [];
        this.province = (obj === null || obj === void 0 ? void 0 : obj.province) ? obj.province : [];
    }

    get json() {
        return JSON.stringify(this);
    }
}

export class Discount {
  id?: number;
  name: string;
  description: string;
  link: string;
  image: string;
  desttype: string;
  destinationid: number;
  traveltype: string;
  travelaccomodation: string;
  jsonfilter: string;
  insdate: number;
  active: boolean;
  region: boolean;
  discountfilter: DiscountFilter;
}

@Component({
  selector: 'app-discount-list',
  templateUrl: './discount-list.component.html',
  styleUrls: ['./discount-list.component.scss']
})
export class DiscountListComponent implements OnInit {

  rows: Discount[] = [];
  loading: boolean = false;

  advancedSearch: Search = new Search('discount', `concat(name,' ',description,' ',desttype,' ',destinationid,' ',traveltype,' ',travelaccomodation)`, 'contains');
  @ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
  showAdvancedSearch: boolean = true;
  loggedUser: User;
  subcription: Subscription;

  constructor(private crudService: CrudService<Discount>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private modalService: NgbModal,
    private router: Router) {
    this.advancedSearch.setSortDirection(SortDirection.DESCENDING);
    this.advancedSearch.setSortField('id');
  }

  ngOnInit() {
    // this.advancedSearch.setSortField('priority');
    this.advancedSearch.setSortDirection('asc');
    this.search();
  }


  loadItems() {
    this.loading = true;
    this.rows = [];
    this.cdr.detectChanges();

    const datarequest = this.crudService.searchEntities(Discount, 'discount', this.advancedSearch, this.advancedSearch.page);
    const countrequest = this.crudService.countEntities(Discount, 'discount', this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.advancedSearch.page.setTotal(results[1].ct);
      this.rows = results[0];
      this.rows.forEach(row => {
      })
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
      this.loading = false;
    });

  }

  search(searchParams?: any) {
    if (searchParams) {
      this.advancedSearch = searchParams;
    }
    this.loadItems();
  }

  rowClass = (row: any) => {
    return '';
  }

  get page() {
    if (this.advancedSearch) {
      return this.advancedSearch.page;
    } else {
      return undefined;
    }
  }

  onPageChange(pageInfo: any) {

    this.advancedSearch.page.setPageNumber(pageInfo.offset);
    this.listfilters.addQueryParams();
    this.loadItems();
  }

  onSortChange(sortInfo: any) {
    const sort = sortInfo.sorts[0];
    this.advancedSearch.setSortField(sort.prop);
    this.advancedSearch.setSortDirection(sort.dir);
    this.listfilters.addQueryParams();
    this.loadItems();
  }

  onActivate(event: any) {
    if (true /*this.aclService.hasPermission('centri.detail')*/) {
      if (event.type === 'dblclick') {
        this.router.navigate([`/discount/edit/${event.row.id}`], { queryParamsHandling: 'merge' });
      }
    }
  }

  edit(discount?: Discount) {
    const modal = this.modalService.open(DiscountAddEditModalComponent, { centered: true, size: 'lg' });
    modal.componentInstance.discount = discount;
    modal.result.then((newItem: Discount) => {
      this.loadItems();
    }).catch(() => { });
  }

  delete(row: Discount, event: any) {
    event.target.closest('datatable-body-cell').blur();
    this.dialogService.openConfirmModal({
      message: this.translateService.instant('labels.warning-discount-delete'),
      hasIcon: true,
      type: 'warning'
    }).then(result => {
      if (result) {
        this.crudService.deleteEntity(Discount, 'discount', row).subscribe(() => {
          this.translateService.get(['labels.discount-deleted', 'labels.discount']).subscribe(messages => {
            this.messageService.sendSuccess(messages['labels.discount-deleted'], messages['labels.discount']);
            this.loadItems();
          });
        });
      }
    });
  }

}
