import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, ListOptions, ListService, MAX_TIME, MessageService, newDate, ParamsService } from 'elbuild-ui-lib-core';
import { CommonService, Provincia, UserService, UserStatus, UserType } from 'meetravel-ui-lib';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { SendpushmodalComponent } from '../../../sendpushmodal/sendpushmodal.component';

@Component({
	selector: 'app-user-list-filters',
	templateUrl: './user-list-filters.component.html',
	styleUrls: ['./user-list-filters.component.scss']
})
export class UserListFiltersComponent extends ListFiltersComponent implements OnInit {

	@Input() isStaff: boolean;
	keyword: string;
	active: boolean = true;
	onlyactive = true;
	onlysoftblocked = false;
	onlycertified = false;
	testcreator = false;
	onlymedia = false;
	onlybanned = false;
	onlypremium = false;
	loading: boolean;
	maxdate: any
	subscribed = { from: undefined, to: undefined };
	joined = { from: undefined, to: undefined };
	provinceList: Provincia[] = [];
	selectedInterest: [] = [];
	selectedTravelstyle: [] = [];
	selectedProvince: string;
	birth = { from: undefined, to: undefined };
	lastlogin = { from: undefined, to: undefined };
	travelstyleList: any[] = [];
	interestList: any[] = [];
	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		selectAllText: 'Seleziona tutti',
		unSelectAllText: 'Deseleziona tutti',
		itemsShowLimit: 3,
		allowSearchFilter: true
	};
	constructor(router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService,
		private messageService: MessageService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		private userService: UserService,
		private modalService: NgbModal,
		private listService: ListService,
		private provinceService: CrudService<Provincia>,
		private commonService: CommonService) {
		super(router, _route, paramsService);
		this.maxdate = 2000000000000;
	}

	ngOnInit() {
		const options = new ListOptions();
		options.listitemname = "selistitem";
		options.listname = "selist";
		options.numitem = 100;
		this.provinceService.getAll(Provincia, 'province').subscribe((res) => {
			this.provinceList = res;
		});

		this.listService.getListItemsBySlug('user.types', options).subscribe((res) => {
			//creating a string array based on res.itemvalue

			this.travelstyleList = res.map((item) => {
				return item.itemvalue;
			});
		});

		this.listService.getListItemsBySlug('user.interest', options).subscribe((res) => {
			this.interestList = res.map((item) => {
				return item.itemvalue;
			});
		});
		this.resetSearch();
	}

	readQueryParams(params: string) {
		super.readQueryParams(params);

		if (this.searchparams.rangeTmp['insdate']) {
			this.subscribed.from = this.searchparams.rangeTmp['insdate'].start * 1000;
			this.subscribed.to =
				this.searchparams.rangeTmp['insdate'].stop * 1000 == this.maxdate
					? undefined
					: this.searchparams.rangeTmp['insdate'].stop * 1000;
		}
		this.interestList.forEach((item, index) => {
			if (this.searchparams.simpleTmp[`ui${index + 2}`]) {
				console.log(item);
			}
		});

		//
	}

	resetSearch() {
		this.keyword = undefined;
		this.active = true;
		this.onlyactive = true;
		this.onlysoftblocked = false;
		this.onlycertified = false;
		this.testcreator = false;
		this.onlymedia = false;
		this.onlybanned = false;
		this.onlypremium = false;
		this.subscribed.from = undefined;
		this.subscribed.to = undefined;
		this.birth.from = undefined;
		this.birth.to = undefined;
		this.lastlogin.from = undefined;
		this.lastlogin.to = undefined;
		this.joined.from = undefined;
		this.joined.to = undefined;
		this.selectedInterest = undefined;
		this.selectedTravelstyle = undefined;
		this.selectedProvince = undefined;
		this.searchparams.table = 'user';
		this.searchparams.jointable = undefined;
		this.searchparams.reset();
		if (this.isStaff) {
			this.searchparams.addSimpleFilter('utype', UserType.ADMIN);
		} else {
			this.searchparams.addNotEqualFilter('utype', UserType.ADMIN);
		}
		this.searchparams.addSimpleFilter('status', UserStatus.ACTIVE);
		this.searchparams.addNotEqualFilter('status', UserStatus.DELETED);
		super.search();
	}

	search(keeppage?: boolean) {
		let table = '';
		if (this.subscribed.from || this.subscribed.to) {
			const start = this.subscribed.from ? this.subscribed.from : newDate().getTime();
			this.subscribed.to = moment(this.subscribed.to).endOf('day').unix() * 1000;
			const stop = this.subscribed.to ? this.subscribed.to : this.maxdate;
			this.searchparams.addRangeFilter('insdate', start / 1000, Math.round(stop / 1000));
		} else {
			this.searchparams.removeRangeFilter('insdate');
		}
		if (this.onlypremium) {
			this.searchparams.addRangeFilter('premiumexpirydate', Math.round(new Date().getTime() / 1000), MAX_TIME);
		} else {
			this.searchparams.removeRangeFilter('premiumexpirydate');
		}
		if (this.birth.from || this.birth.to) {
			const start = this.birth.from ? this.birth.from : newDate().getTime();
			this.birth.to = moment(this.birth.to).endOf('day').unix() * 1000;
			const stop = this.birth.to ? this.birth.to : this.maxdate;
			this.searchparams.addRangeFilter('birthdate', start / 1000, Math.round(stop / 1000));
		} else {
			this.searchparams.removeRangeFilter('birthdate');
		}
		if (this.lastlogin.from || this.lastlogin.to) {
			const start = this.lastlogin.from ? this.lastlogin.from : newDate().getTime();
			this.lastlogin.to = moment(this.lastlogin.to).endOf('day').unix() * 1000;
			const stop = this.lastlogin.to ? this.lastlogin.to : this.maxdate;
			this.searchparams.addRangeFilter('lastdate', start / 1000, Math.round(stop / 1000));
		} else {
			this.searchparams.removeRangeFilter('lastdate');
		}
		if (this.selectedProvince) {
			this.searchparams.addLikeFilter('user.province', this.selectedProvince[0]);
		} else {
			this.searchparams.removeLikeFilter('user.province');
		}
		if (this.joined.from || this.joined.to || this.onlymedia) {
			const start = this.joined.from ? this.joined.from : newDate().getTime();
			this.joined.to = moment(this.joined.to).endOf('day').unix() * 1000;
			const stop = this.joined.to ? this.joined.to : this.maxdate;
			this.searchparams.addRangeFilter('travelguest.insdate', start / 1000, Math.round(stop / 1000));
			this.searchparams.addSimpleFilter('travel.happened', this.onlymedia ? 1 : 0);
			this.searchparams.table = 'distinct user';
			this.searchparams.jointable = `user left join travelguest on user.id = travelguest.userid join travel on travel.id = travelguest.travelid join attach on attach.eid = travel.id and attach.entity = 'travel'`
			this.searchparams.addSimpleFilter('travel.happened', 1);
		} else {
			this.searchparams.table = 'user';
			this.searchparams.jointable = this.searchparams.jointable ? this.searchparams.jointable : undefined;
			this.searchparams.removeRangeFilter('travelguest.insdate');
			this.searchparams.removeSimpleFilter('travel.happened');
		}

		if (this.selectedInterest || this.selectedTravelstyle) {
			this.searchparams.table = 'distinct user';
			this.searchparams.jointable = `user`
			if (this.selectedInterest) {
				this.selectedInterest.forEach((interest, index) => {
					this.searchparams.jointable += ` join userinterest ui${index + 2} on ui${index + 2}.userid = user.id `;
					this.searchparams.addSimpleFilter(`ui${index + 2}.interest`, interest);
				});
			}
			if (this.selectedTravelstyle) {
				this.searchparams.jointable += ` join usertraveltype  on usertraveltype.userid = user.id `;
				this.searchparams.addInFilter('usertraveltype.traveltype', this.selectedTravelstyle);
			}
		}

		super.search(keeppage);
		super.search(keeppage);
	}

	export() {
		this.loading = true;
		this.commonService.asyncexport(this.searchparams).subscribe(res => {
			this.loading = false;
			if (res) {
				this.dialogService.openAlertModal({
					message: 'Il processo di export è stato schedulato correttamente.Consultare la sezione Export Asincroni per scaricare il file una volta pronto',
					hasIcon: true,
					type: 'warning'
				});
			}
		}, error => {
			this.messageService.sendError(error);
			this.loading = false;
		});
	}

	openSendPushModal() {
		const modal = this.modalService.open(SendpushmodalComponent, { centered: true, size: 'lg' });
		modal.componentInstance.genericSearch = this.searchparams;
		modal.result.then((res) => {
			console.log(res);
			this.userService.sendPushNotification(res.userid, res.message, res.title, res.all, res.genericSearch).subscribe(() => {
				this.translateService.get(['labels.notification-sent', 'labels.notification']).subscribe(messages => {
					this.messageService.sendSuccess(messages['labels.notification-sent'], messages['labels.notification']);
				});
			}, ({ error }) => {
				this.translateService.get(['labels.notification-error', 'labels.notification']).subscribe(messages => {
					this.messageService.sendError(messages['labels.notification-error'], messages['labels.notification']);
				});
			});
		});
	}


}
