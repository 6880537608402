import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GuestStatus, GuestJoinType, MeetravelUser } from 'meetravel-ui-lib';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Search } from 'elbuild-ui-lib-core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-guest-list-filters',
  templateUrl: './guest-list-filters.component.html',
  styleUrls: ['./guest-list-filters.component.scss']
})
export class GuestListFiltersComponent implements OnInit {

  @Input() isInvite: boolean = false;
  @Input() isRecived: boolean = false;
  @Input() isSent: boolean = false;
  selectedstatus = {};
  filtersReady = false;
  lastStatus: string = 'joined';

  showAdvancedSearch = true;
  guestsearch: any = {};
  statuses: any[];
  jointypes: any[] = Object.keys(GuestJoinType).map(k => GuestJoinType[k]);

  searchUsers: Search;
  userClass: ClassType<MeetravelUser> = MeetravelUser;
  userSelected: MeetravelUser;


  @Output() onSearch = new EventEmitter<any>();

  selectedStatuses = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Seleziona tutti',
    unSelectAllText: 'Deseleziona tutti',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  constructor(private translateService: TranslateService) {
    this.statuses = Object.keys(GuestStatus).map(k => this.translateService.instant(GuestStatus[k]));
    this.factoryStatusFilter();
    this.filtersReady = true;

  }

  ngOnInit() {
    if (!this.isInvite)
      this.selectedStatuses = [GuestStatus.JOINED];
    this.guestsearch = this.factoryFilter();
    this.search();
  }

  factoryStatusFilter() {
    this.statuses.forEach(status => {
      this.selectedstatus[status] = false;
    });
    this.selectedstatus[GuestStatus.JOINED] = true;
  }

  toggleAdvanced() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  onStatusSelect(item: any) {
    console.log(item);
  }
  onStatusSelectAll(items: any) {
    console.log(items);
  }

  search() {
    this.guestsearch.statuses = this.selectedStatuses.map(item => {
      return Object.keys(GuestStatus).filter(status => {
        return item === status.toLocaleLowerCase();
      })[0].toLocaleLowerCase();
    });
    this.onSearch.emit(this.guestsearch);
  }

  resetSearch() {
    this.guestsearch = this.factoryFilter();
    this.selectedStatuses = [];
    this.userSelected = undefined;
    this.search();
  }

  onSelectedInviter(event: MeetravelUser) {
    this.guestsearch.invitedby = event.id;
  }

  onDeleteInviter() {
    this.guestsearch.invitedby = undefined;
  }

  factoryFilter() {
    if (this.isInvite) {
      return {
        jointype: GuestJoinType.INVITE
      };
    } else {
      return {};
    }
  }

  onToggle(event: any, status?: string) {
    if (event) {
      if (this.lastStatus) {
        this.selectedstatus[this.lastStatus] = false;
      }
      this.lastStatus = status;
      if (status) {
        this.selectedStatuses = [status];
      } else {
        this.selectedStatuses = [];
      }
    }
    this.search();
  }

}
