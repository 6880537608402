import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeItExtra from '@angular/common/locales/extra/it';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { VersionCheckService } from './@core/data/version-check.service';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(private router: Router,
		private sharedDataService: SharedDataService,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private versionCheckService: VersionCheckService) {
		// register locale for pipe
		registerLocaleData(localeIt, 'it', localeItExtra);
		registerLocaleData(localeEn, 'en', localeEnExtra);
	}


	ngOnInit() {
		if (environment.production) {
			this.versionCheckService.initVersionCheck('/version.json');
		}

		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
	}
}
