<div
	class="container"
	*ngIf="this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'; else mainHeader">
	<ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
	<div class="m-header">
		<a
			href="javascript:"
			class="mobile-menu"
			id="mobile-collapse"
			(click)="navCollapse()">
			<span></span>
		</a>

		<a
			class="mob-toggler"
			[ngClass]="{'on' : this.menuClass}"
			href="javascript:"
			(click)="toggleMobOption()">
			<i class="feather icon-more-vertical"></i>
		</a>
	</div>
	<div
		class="collapse navbar-collapse"
		[style.display]="this.collapseStyle">
		<app-nav-left class="mr-auto"></app-nav-left>
		<app-nav-right class="ml-auto"></app-nav-right>
	</div>
</ng-template>
