import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { CrudService, MessageService, Page, Search } from 'elbuild-ui-lib-core';
import { TravelMessage } from 'meetravel-ui-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ImageModalComponent } from '../../../image-modal/image-modal.component';
import { DialogService } from 'elbuild-ui-componentslib';

@Component({
  selector: 'app-travel-chat',
  templateUrl: './travel-chat.component.html',
  styleUrls: ['./travel-chat.component.scss']
})
export class TravelChatComponent implements OnInit {

  @Input() travelid: number;
  rows: TravelMessage[];
  search = new Search('travelmessage');
  @Input() selectedId: number;
  animated: boolean = false;
  constructor(private crudService: CrudService<TravelMessage>,
    private modalService: NgbModal,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.search.addSimpleFilter('travelid', this.travelid);
    this.crudService.countEntities(TravelMessage, 'travelmessage', this.search).subscribe(result => {
      this.search.page.setTotal(result.ct);
    }, error => {
      console.log('error', error);
    });
    this.loadItems();
  }

  loadItems() {


    this.crudService.searchEntities(TravelMessage, 'travelmessage', this.search, this.search.page).subscribe(result => {
      this.rows = result;
      if (this.selectedId && !this.animated) {
        if (result.filter(item => item.id == this.selectedId).length === 0) {
          this.search.page.setPageNumber(this.search.page.pageNumber + 1);
          this.loadItems();
          return;
        } else {
          this.animated = true;
          setTimeout(() => this.applyScrollFlash(), 700);
        }

      }
    }, error => {
      console.log('error', error);
    });
  }

  applyScrollFlash() {
    const element = document.getElementById('chat' + this.selectedId);
    const y = element.getBoundingClientRect().top + window.pageYOffset - 70;
    window.scrollTo({ top: y, behavior: 'smooth' });
    this.renderer.addClass(element, 'flash');
  }

  delete(item: TravelMessage, event: any) {
    this.dialogService.openConfirmModal({
      message: this.translateService.instant('labels.warning-message-delete'),
      hasIcon: true,
      type: 'warning'
    }).then(result => {
      if (result) {
        this.crudService.deleteEntity(TravelMessage, 'travelmessage', item).subscribe(alert => {
          this.loadItems();
          this.messageService.sendSuccess(this.translateService.instant('labels.message-deleted'), this.translateService.instant('labels.alert'));
        }, error => {
          this.messageService.sendError(error, this.translateService.instant('labels.alert'));
        });
      }
    });
  }



  openImg(img: string) {
    const modal = this.modalService.open(ImageModalComponent, { centered: true, size: 'lg' });
    modal.componentInstance.img = img;
  }

  onPageChange(event: any) {
    this.search.page.setPageNumber(event - 1);
    this.loadItems();
  }

}
