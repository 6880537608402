<div class="auth-wrapper">
	<div class="auth-content">
		<div class="card">
			<div class="row align-items-center text-center">
				<div class="col-md-12">
					<div class="card-body">
						<form (ngSubmit)="login()">
							<img
								src="assets/images/logo-dark.png"
								alt
								class="img-fluid mb-4 logo">
							<h4 class="mb-3 f-w-400">Accedi</h4>
							<div class="input-group mb-3">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<i class="feather icon-mail"></i>
									</span>
								</div>
								<input
									name="user"
									[(ngModel)]="logindata.username"
									type="email"
									class="form-control"
									placeholder="Email">
							</div>
							<div class="input-group mb-4">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<i class="feather icon-lock"></i>
									</span>
								</div>
								<input
									name="password"
									[(ngModel)]="logindata.md5password"
									type="password"
									class="form-control"
									placeholder="Password">
							</div>
							<div class="form-group text-left mt-2">
								<div class="checkbox checkbox-primary d-inline">
									<input
										type="checkbox"
										name="checkbox-fill-1"
										id="checkbox-fill-a1"
										checked>
									<label
										for="checkbox-fill-a1"
										class="cr">
										Ricorda il mio accesso
									</label>
								</div>
							</div>
							<button
								type="submit"
								class="btn btn-block btn-primary mb-4">
								Accedi
							</button>
							<p
								class="mb-2 text-muted"
								*ngIf="false">
								Password dimenticata?
								<a
									[routerLink]="['/auth/reset-password']"
									class="f-w-400">
									Reimposta
								</a>
							</p>

						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
