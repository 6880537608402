<div class="row">
	<div class="col-sm-12">
		<app-user-list-filters
			#listfilters
			[searchparams]="advancedSearch"
			[isStaff]="isStaff">
		</app-user-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<a
				class="float-right"
				(click)="edit(null)">
				<i
					container="body"
					ngbTooltip="{{'labels.create' | translate}}"
					class="feather icon-plus"></i>
			</a>

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					(activate)="onActivate($event)"
					[messages]="{
          emptyMessage: 'labels.empty-users' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="fname"
						[flexGrow]="1"
						[name]="'labels.fname' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="lname"
						[flexGrow]="1"
						[name]="'labels.lname' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="email"
						[flexGrow]="1"
						[name]="'labels.email' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!isStaff"
						prop="gender"
						[flexGrow]="1"
						[name]="'labels.gender' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{'types.gender.' + value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!isStaff"
						[flexGrow]="1"
						prop="gamificationlevel"
						[name]="'labels.livello' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<i
								container="body"
								[ngbTooltip]="'labels.punteggioattuale'| translate:{value:row.gamificationpoints}">
								{{value}}
							</i>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!isStaff"
						prop="birthdate"
						[flexGrow]="1"
						[name]="'labels.birthdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ value | date: 'dd/MM/yyyy' }}
						</ng-template>
					</ngx-datatable-column>

					<!-- <ngx-datatable-column
						prop="leftctr"
						[flexGrow]="1"
						[name]="'labels.partenze' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ value ? value : 0 }}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="absencectr"
						[flexGrow]="1"
						[name]="'labels.assenze' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ value ? value : 0}}
						</ng-template>
					</ngx-datatable-column> -->

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.userinsdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ value | date: 'dd/MM/yyyy HH:mm' }}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!isStaff"
						prop="status"
						[flexGrow]="0.5"
						[name]="'labels.active' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{(value === 'active' ? 'labels.yes' : 'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="premiumexpirydate"
						[flexGrow]="1"
						[name]="'labels.premium' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{(value > today ? 'labels.yes' : 'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						*ngIf="!isStaff"
						prop="certifiedhost"
						[flexGrow]="0.5"
						[name]="'labels.cert' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<i
								*ngIf="row.certifiedhost"
								container="body"
								[ngbTooltip]="'labels.hostcertificatodal'| translate:{value:row.certifiedhostdate|date:'dd/MM/yyyy'}"
								class="feather icon-award">
							</i>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons gx-2">
								<a
									class="link mt-n1"
									*ngIf="isStaff">
									<i
										(click)="role(row.id, row.fullname)"
										container="body"
										ngbTooltip="{{'labels.roles' | translate}}"
										class="far fa-clipboard "></i>
								</a>
								<a
									[routerLink]="'/user/edit/'+row.id"
									*aclFeatures="FEATURE_EDIT_UTENTI">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<a
									class="link"
									*aclFeatures="FEATURE_EDIT_UTENTI">
									<i
										(click)="delete(row, $event)"
										container="body"
										ngbTooltip="{{'labels.delete' | translate}}"
										[ngClass]="{'text-danger':isStaff}"
										class="feather icon-trash"></i>
								</a>
								<a
									*ngIf="row.softblock"
									(click)="unblock(row)"
									class="link">
									<i
										container="body"
										ngbTooltip="{{'labels.softblock' | translate}}"
										class="feather text-danger icon-lock"></i>
								</a>

							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>

</div>
