import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClassType } from 'class-transformer/ClassTransformer';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';
import { ParamsService, Search, SortDirection } from 'elbuild-ui-lib-core';
import { NotificationTargets, Travel } from 'meetravel-ui-lib';

@Component({
  selector: 'app-notification-list-filters',
  templateUrl: './notification-list-filters.component.html',
  styleUrls: ['./notification-list-filters.component.scss']
})
export class NotificationListFiltersComponent extends ListFiltersComponent implements OnInit {

  searchTravel: Search;
  travelClass: ClassType<Travel> = Travel;
  constructor(
    router: Router,
    _route: ActivatedRoute,
    paramsService: ParamsService
  ) {
    super(router, _route, paramsService);
    this.searchTravel = new Search('travel');
  }

  ngOnInit() {
    this.factoryFilter();
  }

  onSelectedTravel(event: Travel) {
    if (event)
      this.searchparams.addSimpleFilter('travelid', event.id);
  }

  onDeleteTravel() {
    this.searchparams.removeSimpleFilter('travelid');
  }

  readQueryParams(params: string) {
    super.readQueryParams(params);
  }

  resetSearch() {
    this.searchparams.reset();
    this.factoryFilter();
    this.search();
  }

  factoryFilter() {
    this.searchparams.addSimpleFilter('target', NotificationTargets.ADMIN);
    this.searchparams.setSortDirection(SortDirection.DESCENDING);
    this.searchparams.setSortField('id');
  }

  search(keeppage?: boolean) {

    super.search(keeppage);
  }


}
