<app-card hidHeader="true">
	<div class="row">
		<app-media-box
			*ngFor="let attach of rows"
			[guest]="getGuest(attach)"
			(reload)="loadItems()"
			[attach]="attach"></app-media-box>
		<span
			*ngIf="!loading && rows.length == 0"
			style="margin-left: 20px;">
			Non sono presenti media per questo viaggio
		</span>
	</div>
</app-card>
