import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CrudService, Page } from 'elbuild-ui-lib-core';
import { MeetravelUser, UserService } from 'meetravel-ui-lib';

@Component({
	selector: 'app-follower-list',
	templateUrl: './follower-list.component.html',
	styleUrls: ['./follower-list.component.scss']
})
export class FollowerListComponent implements OnInit {

	@Input() uid: number;
	@Input() followerct: number;

	loading: boolean = false;
	rows: any[] = [];
	page: Page = new Page(20);

	constructor(private crudService: CrudService<MeetravelUser>,
		private userService: UserService,
		private cdr: ChangeDetectorRef) {

	}

	ngOnInit() {

		this.page.setPageNumber(0);
		this.page.setTotal(this.followerct);
		this.loadItems();
	}

	ngAfterViewInit() {

	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();
		this.userService.getFollowers(this.uid, this.page.start, this.page.stop).subscribe(data => {
			this.rows = data;
			console.log(this.rows);
			this.loading = false;
			this.cdr.detectChanges();
		});
	}

	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}

}
