<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group d-flex flex-row">
					<input
						type="text"
						class="form-control d-inline"
						name="keyword"
						placeholder="Cerca..."
						[(ngModel)]="searchparams.genericSearchTerm">

					<button
						type="button"
						class="btn btn-link ml-2"
						(click)="toggleAdvanced()">
						<i class="feather icon-filter"></i>

					</button>

				</div>
			</div>

		</div>
		<div [ngbCollapse]="showAdvancedSearch">
			<div class="row">
				<!-- fname -->
				<div class="col-md-4">
					<div class="form-group">
						<label>{{'labels.travel' | translate}}</label>
						<app-entity-autocomplete
							entityName="travel"
							entityField="name"
							entityFilterField="name"
							entityFilterType="like"
							[entitySearch]="searchTravel"
							[entityClass]="travelClass"
							(onSelected)="onSelectedTravel($event)"
							(onDelete)="onDeleteTravel()"
							name="travel"
							placeholder="{{ 'labels.travel' | translate }}">
						</app-entity-autocomplete>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
			</div>

		</div>
	</form>
</app-card>
