import { Component, OnInit } from '@angular/core';
import { ListItem, List, Search, CrudService, MessageService } from 'elbuild-ui-lib-core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { ListItemEditComponent } from '../list-item-edit/list-item-edit.component';
import { DialogService } from 'elbuild-ui-componentslib';

@Component({
	selector: 'app-list-item-list',
	templateUrl: './list-item-list.component.html',
	styleUrls: ['./list-item-list.component.scss']
})
export class ListItemListComponent implements OnInit {

	rows: ListItem[];
	list: List;
	loading = false;
	advancedSearch: Search = new Search('selistitem');

	get editable(): number {
		return this.list ? this.list.editable : 0;
	}

	constructor(
		private route: ActivatedRoute,
		private listitemService: CrudService<ListItem>,
		private listService: CrudService<List>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbModal,
		private dialogService: DialogService
	) { }

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];
			this.loadList(id);
			this.loadItems(id);
		});
	}

	loadList(id: number): void {
		this.listService.getEntity(List, 'selist', id).subscribe((l: List) => this.list = l);
	}

	loadItems(id: number): void {
		this.advancedSearch.addSimpleFilter('lid', id);
		this.loading = true;
		this.rows = [];

		const datarequest = this.listitemService.searchEntities(ListItem, 'selistitem', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.listitemService.countEntities(ListItem, 'selistitem', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];

			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});


	}

	edit(id?: number): void {
		const modal = this.modalService.open(ListItemEditComponent, { centered: true, size: 'lg' });
		modal.componentInstance.lid = this.list.id;
		if (id) {
			modal.componentInstance.load(id);
		}

		modal.result.then((newItem: ListItem) => {
			this.loadItems(this.list.id);
		}).catch(() => { });
	}

	delete(item: ListItem): void {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-listitem-delete'),
			hasIcon: true,
			type: 'warning'
		}).then((result) => {
			if (result) {
				this.listitemService.deleteEntity(ListItem, 'selistitem', item).subscribe(() => {
					this.translateService.get(['labels.item-deleted', 'labels.lists']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.item-deleted'], messages['labels.lists']);
						this.loadItems(this.list.id);
					});
				});
			}
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.loadItems(this.list.id);
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.loadItems(this.list.id);
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

}
