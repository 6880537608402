<div class="card">
	<div class="card-body">
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-6">
				<app-range-picker-filters (onRangeChange)="onRangeChange($event)"></app-range-picker-filters>
			</div>
		</div>
	</div>
</div>
<app-loading
	[full]="false"
	*ngIf="loading"></app-loading>
<div *ngIf="!loading">
	<div class="row">
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.totmessage"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.periodmessage"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<app-line-chart
						[chartStruct]="data?.messagechart"
						divid="messagechart"></app-line-chart>
				</div>
			</div>
		</div>
	</div>
</div>
