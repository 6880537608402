<div class="row">
	<div class="col-sm-12">
		<app-report-list-filters
			#listfilters
			[searchparams]="advancedSearch"
			(searchEvent)="applySearch($event)">
		</app-report-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="advancedSearch.page.totalElements"
					[offset]="advancedSearch.page.pageNumber"
					[limit]="advancedSearch.page.size"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-report' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="reporter"
						[flexGrow]="1"
						[name]="'labels.reporter' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							<a [routerLink]="[ '/user/edit/' + value.id  ]">{{value.fullname}}</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="user"
						[flexGrow]="1"
						[name]="'labels.reported' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							<a [routerLink]="[ '/user/edit/' + value.id  ]">{{value.fullname}}</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="status"
						[flexGrow]="1"
						[name]="'labels.status' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{'types.user-report.'+value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date: 'dd/MM/yyyy HH:mm'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="reviewdate"
						[flexGrow]="1"
						[name]="'labels.reviewdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date: 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="reportnote"
						[flexGrow]="1.5"
						[name]="'labels.reportnote' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="reportreason"
						[flexGrow]="1"
						[name]="'labels.reason' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}} - {{row.reportnote}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div
								class="icon-buttons"
								*ngIf="row.isPending">
								<a (click)="accept(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.accept' | translate}}"
										class="feather icon-check"></i>
								</a>
								<a (click)="decline(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.decline' | translate}}"
										class="feather icon-x"></i>
								</a>
							</div>

							<a
								*ngIf="row.user.softblock"
								(click)="unblock(row.user)"
								class="link">
								<i
									container="body"
									ngbTooltip="{{'labels.softblock' | translate}}"
									class="feather text-danger icon-lock"></i>
							</a>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>
</div>
