<div class="modal-header">
	<h4 class="modal-title">{{'labels.travels' | translate}}</h4>
	<button
		type="button"
		class="close"
		aria-label="Close"
		(click)="dismiss()">
		<span aria-hidden="true">×</span>
	</button>
</div>
<div
	class="row"
	*ngIf="loading">
	<div class="col text-center">
		<app-loading></app-loading>
	</div>
</div>
<div
	*ngIf="!loading"
	class="modal-body"
	ngbAutofocus>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="travels"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="false"
					[externalSorting]="false"
					[loadingIndicator]="loading"
					[rowClass]="rowClass"
					(activate)="onActivate($event)"
					[messages]="{
          emptyMessage: 'labels.empty-travel' | translate,
          totalMessage: 'labels.total' | translate
		}">

					<ngx-datatable-column
						prop="name"
						[flexGrow]="1.5"
						[name]="'labels.name' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a	
								(click)="dismiss()"
								[routerLink]="['/travel/edit/'+row.id]">
								{{value}}
							</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="destination"
						[flexGrow]="1"
						[name]="'labels.destination' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="status"
						[flexGrow]="1"
						[name]="'labels.status' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ 'types.travel-status.' + value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="begindate"
						[flexGrow]="1"
						[name]="'labels.arrive' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{(value) | date:'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="enddate"
						[flexGrow]="1"
						[name]="'labels.return' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{(value) | date:'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="happened"
						[flexGrow]="1"
						[name]="'labels.happened' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ 'types.happened.' + value | translate}}
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</div>
	</div>
</div>