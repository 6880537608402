<div
	class="container"
	*ngFor="let item of rows; let i = index"
	[ngClass]="{'darker': i%2==0}"
	[id]="'chat'+item.id">

	<div class="row">
		<div class="col-md-4">
			<span class="font-weight-bold">{{item?.user?.fullname}}:</span>
			<img
				class="img-round"
				asyncimg
				[opaque]="item?.user?.img"
				[alt]
				placeholder="/assets/images/user-placeholder.png"
				size="md"
				alt="Avatar">
			<p>{{item.message}}</p>

		</div>
		<div class="col-md-6">
			<img
				[ngbTooltip]="'labels.open' | translate"
				class="media"
				*ngIf="item?.media"
				asyncimg
				[opaque]="item?.media"
				[alt]
				(click)="openImg(item.media)"
				placeholder="/assets/images/user-placeholder.png"
				size="md"
				alt="Avatar">
		</div>
		<div class="col-md-2">

			<span class="time-right">
				{{item.insdate | date : "dd/MM/yyyy HH:mm"}}
			</span>
			<br>
			<span class="float-right mt-2">
				<button
					class="btn btn-outline-danger"
					container="body"
					(click)="delete(item, $event)"
					ngbTooltip="{{'labels.delete' | translate}}">
					<i class="feather icon-trash"></i>
				</button>
			</span>
		</div>
	</div>
</div>
<ngb-pagination
	class="d-flex 
		justify-content-center"
	[page]="search.page.pageNumber + 1"
	[pageSize]="search.page.getSize()"
	[collectionSize]="search.page.getTotalElements()"
	(pageChange)="onPageChange($event)"></ngb-pagination>
