import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Domandetestcreator } from 'meetravel-ui-lib';

@Component({
	selector: 'app-domandetest-modal-add-edit',
	templateUrl: './domandetest-modal-add-edit.component.html',
	styleUrls: ['./domandetest-modal-add-edit.component.scss']
})
export class DomandetestModalAddEditComponent implements OnInit {

	item: Domandetestcreator;
	productClass = Domandetestcreator;
	constructor(private activeModal: NgbActiveModal) {}

	ngOnInit() {
		if (!this.item) {
			this.item = new Domandetestcreator();
		}
	}

	submit() {
		this.activeModal.close(this.item);
	}

	dismiss() {
		this.activeModal.dismiss();
	}

	close() {
		this.activeModal.close();
	}

}
