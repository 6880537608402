import { User } from 'elbuild-ui-lib-core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/Observable';
import { SharedDataService, AuthService } from 'elbuild-ui-lib-core';
import { AclService } from '../@core/data/acl.service';
import { map } from 'rxjs/operators';



@Injectable()
export class AuthenticationGuardService implements CanActivate, CanActivateChild {

	loggedUser: User;

	constructor(private sharedDataService: SharedDataService,
		private router: Router,
		private authService: AuthService,
		private aclService: AclService) {
		this.sharedDataService.currentLoggedUser.subscribe(loggedUser => { this.loggedUser = loggedUser as unknown as User; });
	}


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (this.loggedUser) {
			if (this.checkAclFeatures(route)) {
				return of(true);
			} else {
				return of(false);
			}
		} else {
			if (this.authService.hasCookie()) {
				return this.authService.getLoggedUser().pipe(map((user) => {
					if (this.checkAclFeatures(route)) {
						return true;
					} else {
						return false;
					}
				}, error => { console.log(error); }));
			} else {
				const loginpage = 'signin';
				this.router.navigate([loginpage], {});
				return of(false);
			}
		}
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		if (this.checkAclFeatures(route)) {
			return of(true);
		} else {
			return of(false);
		}
	}

	checkAclFeatures(route: ActivatedRouteSnapshot) {

		if (!this.loggedUser) {
			return false;
		}

		return true;
	}
}
