import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search, User } from 'elbuild-ui-lib-core';
import { Product } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

import { ProductModalEditComponent } from '../components/product-modal-edit/product-modal-edit.component';

@Component({
	selector: 'app-products-list',
	templateUrl: './products-list.component.html',
	styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

	loading: boolean = false;
	advancedSearch: Search = new Search('product');
	showAdvancedSearch: boolean = true;
	loggedUser: User;
	rows: Product[] = [];

	constructor(
		private crudService: CrudService<Product>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private modalService: NgbModal,
		private cdr: ChangeDetectorRef,) {}

	ngOnInit() {
		this.loadItems();
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}


	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();
		const datarequest = this.crudService.searchEntities(Product, 'product', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(Product, 'product', this.advancedSearch);
		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.rows = results[0];
			this.advancedSearch.page.setTotal(results[1].ct);
			this.loading = false;
			this.cdr.detectChanges();
		}
		);
	}

	edit(item?: Product) {
		const modal = this.modalService.open(ProductModalEditComponent, { size: 'lg', backdrop: 'static', centered: true, keyboard: false });
		if (item)
			modal.componentInstance.item = item;
		modal.result.then((result: Product) => {
			console.log(result);
			if (result) {
				const observable = result?.id ? this.crudService.saveEntity(Product, 'product', result) : this.crudService.createEntity(Product, 'product', result);
				observable.subscribe((result: Product) => {
					this.loadItems();
					this.messageService.sendSuccess(this.translateService.instant('product.saved'));
				});
			} else {
				this.messageService.sendError(this.translateService.instant('product.not-saved'));
			}
		});
	}


}
