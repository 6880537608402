import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search, SortDirection, User } from 'elbuild-ui-lib-core';
import { Gamificationlevel, Gamificationmetric } from 'meetravel-ui-lib';
import { forkJoin, Subscription } from 'rxjs';

@Component({
	selector: 'app-gamification-list',
	templateUrl: './gamification-list.component.html',
	styleUrls: ['./gamification-list.component.scss']
})
export class GamificationListComponent implements OnInit {


	loading: boolean = false;
	rows_level: Gamificationlevel[] = [];
	rows_metric: Gamificationmetric[] = [];
	advLevel: Search = new Search('gamificationlevel');
	advMetric: Search = new Search('gamificationmetric');
	showAdvancedSearch: boolean = true;
	loggedUser: User;

	thresholdvalue: number;
	pointsvalue: number;
	selectedThresholdid: number;
	selectedPointsid: number;

	subcription: Subscription;


	constructor(private metricService: CrudService<Gamificationmetric>,
		private levelService: CrudService<Gamificationlevel>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef,
	) {
		this.advLevel.setSortDirection(SortDirection.ASCENDING);
		this.advLevel.setSortField('id');
		this.advMetric.setSortDirection(SortDirection.ASCENDING);
		this.advMetric.setSortField('id');


	}
	ngOnInit() {
		this.loadItems();

	}
	loadItems() {
		this.loading = true;
		this.rows_metric = []
		this.rows_level = [];
		this.cdr.detectChanges();

		const levelrequest = this.levelService.searchEntities(Gamificationlevel, 'gamificationlevel', this.advLevel, this.advLevel.page);
		const metricrequest = this.metricService.searchEntities(Gamificationmetric, 'gamificationmetric', this.advMetric, this.advMetric.page);


		forkJoin([levelrequest, metricrequest]).subscribe(results => {
			this.rows_metric = results[1];
			this.rows_level = results[0];
			this.resetAllSelected()
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	editInPlace(id: number, type?: string) {
		if (type == 'points') {
			this.pointsvalue = this.rows_metric.find(a => a.id == id).points;
			this.selectedPointsid = id;

		} else {
			this.thresholdvalue = this.rows_level.find(a => a.id == id).threshold;
			this.selectedThresholdid = id;
		}

	}

	saveLevel(item: Gamificationlevel) {
		item.threshold = this.thresholdvalue;
		this.levelService.saveEntity(Gamificationlevel, 'gamificationlevel', item).subscribe(result => {
			console.log(result);
			this.messageService.sendSuccess(
				this.translateService.instant('labels.gami-save-success'),
				this.translateService.instant('labels.success')
			);
		});
		this.loadItems();
	}

	saveMatric(item: Gamificationmetric) {
		item.points = this.pointsvalue;
		this.metricService.saveEntity(Gamificationmetric, 'gamificationmetric', item).subscribe(result => {
			console.log(result);
			this.messageService.sendSuccess(
				this.translateService.instant('labels.gami-save-success'),
				this.translateService.instant('labels.success')
			);
		});
		this.loadItems();
	}

	resetAllSelected() {
		this.selectedPointsid = undefined;
		this.selectedThresholdid = undefined;
		this.pointsvalue = undefined;
		this.thresholdvalue = undefined;
	}

	rowClass = (row: any) => {
		return '';
	}


	showNote(value: string) {
		this.dialogService.openAlertModal({
			message: value,
			type: 'info',
		});
	}

}
