<app-breadcrumb></app-breadcrumb>
<div class="row ">
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<app-loading
				[full]="false"
				*ngIf="loading"></app-loading>
			<a
				class="float-right link"
				(click)="edit()">
				<i
					container="body"
					ngbTooltip="{{'labels.create' | translate}}"
					class="feather icon-plus"></i>
			</a>

			<div
				*ngIf="!loading"
				class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[rowClass]="rowClass"
					[limit]="page.size"
					(page)="onPageChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-answers' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[sortable]="false"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="answer"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.answer' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="correctanswer"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.correctanswer' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{(value ? 'labels.yes' : 'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="sort"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.sort' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a (click)="edit(row)">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>

								<a (click)="delete(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.delete' | translate}}"
										class="feather icon-trash"></i>
								</a>
							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>
</div>
