
	<div class="modal-header">
		<h4 class="modal-title">
			{{"labels.receipt" | translate}}
			<i 
				(click)="copy()"
				ngbTooltip="{{'labels.copy' | translate}}"
				class="far fa-copy ml-3 link"></i>
		</h4>
		<button
			(click)="dismiss()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>

		<div class="row">
			<div class="col-md-12 receipt">
				{{item.receipt}}
			</div>
		</div>
	</div>