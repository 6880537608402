import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Regione } from 'meetravel-ui-lib';
import { Search, User, CrudService, MessageService, SharedDataService, SortDirection } from 'elbuild-ui-lib-core';
import { Subscription, Observable, combineLatest, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegioneEditComponent } from '../../regione/regione-edit/regione-edit.component';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
	selector: 'app-regione-list',
	templateUrl: './regione-list.component.html',
	styleUrls: ['./regione-list.component.scss']
})

export class RegioneListComponent implements OnInit, AfterViewInit, OnDestroy {

	loading: boolean = false;
	rows: Regione[];

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
	showAdvancedSearch: boolean = true;

	advancedSearch: Search = new Search('regioni', 'descrizione', 'regioni');

	loggedUser: User;
	subcription: Subscription;

	constructor(
		private regioneService: CrudService<Regione>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private sharedDataService: SharedDataService
	) {

		this.advancedSearch.setSortDirection(SortDirection.ASCENDING);
		this.advancedSearch.setSortField('id');
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams();

		const requests: Observable<any>[] = [urlparamsreq];

		if (!this.loggedUser) {
			const userrequest = this.sharedDataService.currentLoggedUser;
			requests.push(userrequest);
		}

		this.subcription = combineLatest(requests).subscribe(data => {

			if (data[1]) {
				this.loggedUser = data[1];
			}

			this.search(data[0]);

		});
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		if (this.subcription) {
			this.subcription.unsubscribe();
		}
	}

	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;
		}
		this.load();
	}

	load() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.regioneService.searchEntities(Regione, 'regioni', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.regioneService.countEntities(Regione, 'regioni', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];

			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.load();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.load();
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	delete(row: Regione) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-regione-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.regioneService.deleteEntity(Regione, 'regioni', row).subscribe(() => {
					this.translateService.get(['labels.regione-deleted', 'labels.regione']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.regione-deleted'], messages['labels.regione']);
						this.load();
					});
				});
			}
		});
	}


	edit(regioneid?: number) {
		const modal = this.modalService.open(RegioneEditComponent, { centered: true, size: 'lg' });

		if (regioneid) {
			modal.componentInstance.load(regioneid);
		}
		modal.result.then((newItem: Regione) => {
			this.load();
		}).catch(() => { });
	}

}
