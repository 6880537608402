import { Component, NgZone, OnInit } from '@angular/core';
import { GradientConfig } from '../../../app-config';
import { Location } from '@angular/common';
import { VERSION } from 'src/environments/version';
import { environment } from 'src/environments/environment';

import { ProviderAst } from '@angular/compiler';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
	public gradientConfig: any;
	public navCollapsed: boolean;
	public navCollapsedMob: boolean;
	public windowWidth: number;

	currentDate: Date = new Date();
	version = VERSION;
	env: string;

	constructor(private zone: NgZone, private location: Location) {
		this.gradientConfig = GradientConfig.config;
		let currentURL = this.location.path();
		const baseHerf = this.location['_baseHref'];
		if (baseHerf) {
			currentURL = baseHerf + this.location.path();
		}

		this.windowWidth = window.innerWidth;

		if (currentURL === baseHerf + '/layout/collapse-menu'
			|| currentURL === baseHerf + '/layout/box'
			|| (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
			this.gradientConfig.collapseMenu = true;
		}

		this.navCollapsed = (this.windowWidth >= 992) ? this.gradientConfig.collapseMenu : false;
		this.navCollapsedMob = false;

	}

	ngOnInit() {
		if (this.windowWidth < 992) {
			this.gradientConfig.layout = 'vertical';
			setTimeout(() => {
				document.querySelector('.pcoded-navbar').classList.add('menupos-static');
				(document.querySelector('#nav-ps-gradient-able') as HTMLElement).style.maxHeight = '100%'; // 100% amit
			}, 500);
		}
		this.env = environment.production ? 'prod' : 'dev';
	}

	navMobClick() {
		if (this.windowWidth < 992) {
			if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
				this.navCollapsedMob = !this.navCollapsedMob;
				setTimeout(() => {
					this.navCollapsedMob = !this.navCollapsedMob;
				}, 100);
			} else {
				this.navCollapsedMob = !this.navCollapsedMob;
			}
		}
	}

}
