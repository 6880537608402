<div class="modal-header">
	<h4
		class="modal-title"
		*ngIf="!loading">
		{{'labels.roles' | translate}}: {{name}}
	</h4>
	<button
		type="button"
		class="close"
		aria-label="Close"
		(click)="dismiss()">
		<span aria-hidden="true">×</span>
	</button>
</div>
<div
	class="modal-body"
	ngbAutofocus>
	<div
		class="row"
		*ngIf="loading">
		<div class="col text-center">
			<app-loader [material]="false"></app-loader>
		</div>
	</div>

	<ngx-datatable
		#datatable
		class="bootstrap no-footer"
		[rows]="rows"
		[columnMode]="'flex'"
		[rowHeight]="'auto'"
		[footerHeight]="30"
		[headerHeight]="50"
		[rowClass]="rowClass"
		[loadingIndicator]="loading"
		[selected]="selected"
		[selectionType]="'checkbox'"
		[selectAllRowsOnPage]="false"
		(select)="onSelect($event)"
		[limit]="20"
		[messages]="{
		emptyMessage: 'labels.empty-roles' | translate,
		totalMessage: 'labels.total' | translate
	}">
		<ngx-datatable-column
			[width]="30"
			[sortable]="false"
			[canAutoResize]="false"
			[draggable]="false"
			[resizeable]="false"
			[headerCheckboxable]="false"
			[checkboxable]="canCheck">
		</ngx-datatable-column>

		<!-- Detail -->
		<ngx-datatable-row-detail [rowHeight]="'auto'">
			<ng-template
				let-row="row"
				ngx-datatable-row-detail-template>
				<span *ngFor="let f of row.features; let i = index;">
					<small>
						{{ f.feature.name }}
						<span *ngIf="i+1 != row.features.length">,</span>
					</small>
				</span>
			</ng-template>
		</ngx-datatable-row-detail>

		<ngx-datatable-column
			prop="rolename"
			[flexGrow]="2"
			[name]="'labels.role' | translate"
			[draggable]="false"
			[resizeable]="false">
			<ng-template
				let-value="value"
				ngx-datatable-cell-template>
				{{value}}
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column
			prop="description"
			[flexGrow]="2"
			[name]="'labels.description' | translate"
			[draggable]="false"
			[resizeable]="false">
			<ng-template
				let-value="value"
				ngx-datatable-cell-template>
				{{value}}
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-footer></ngx-datatable-footer>
	</ngx-datatable>
</div>
