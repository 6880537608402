import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search, SharedDataService, SortDirection, User } from 'elbuild-ui-lib-core';
import { MeetravelUser, UserService, UserStatus, UserType } from 'meetravel-ui-lib';
import { forkJoin, Subscription } from 'rxjs';

import { AdminFeatures } from '../../conf/features';
import { UserRoleModalComponent } from '../../user-role-modal/user-role-modal.component';
import { UserNewEditComponent } from '../user-new-edit/user-new-edit.component';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {

	FEATURE_EDIT_UTENTI = AdminFeatures.ADMIN_EDIT_USER;
	@Input() isStaff: boolean;


	loading: boolean = false;
	rows: MeetravelUser[] = [];

	advancedSearch: Search = new Search('user', `concat(user.fname,' ',user.lname)`, 'user');

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
	showAdvancedSearch: boolean = true;
	loggedUser: User;
	subcription: Subscription;
	today: number = new Date().getTime();
	firsttime: boolean = true;


	constructor(private crudService: CrudService<MeetravelUser>,
		private userService: UserService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private sharedDataService: SharedDataService,
		private modalService: NgbModal) {
		this.advancedSearch.setSortDirection(SortDirection.DESCENDING);
		this.advancedSearch.setSortField('id');
		this.advancedSearch.addSimpleFilter('status', UserStatus.ACTIVE);
		this.advancedSearch.addNotEqualFilter('status', UserStatus.DELETED);
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams().subscribe(data => {

			if (data[1]) {
				this.loggedUser = data[1];
			}
			console.log(data);
			//INDAGARE PERCHE' IL PRIMO CARICAMENTO NON FUNZIONA

			this.search(data[0]);

		});
	}

	ngOnInit() {

		if (this.isStaff) {
			this.advancedSearch.addSimpleFilter('utype', UserType.ADMIN);
		} else {
			this.advancedSearch.addNotEqualFilter('utype', UserType.ADMIN);
		}

	}

	ngOnDestroy() {
		if (this.subcription) {
			this.subcription.unsubscribe();
		}

	}




	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.crudService.searchEntities(MeetravelUser, 'user', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(MeetravelUser, 'user', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			console.log(this.rows[0]);
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}


	onPageChange(pageInfo: any) {

		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onActivate(event: any) {
		if (true /*this.aclService.hasPermission('centri.detail')*/) {
			if (event.type === 'dblclick') {
				this.router.navigate([`/user/edit/${event.row.id}`], { queryParamsHandling: 'merge' });
			}
		}
	}


	search(searchParams?: any, reset?: boolean) {
		if (!this.firsttime && searchParams) {
			this.advancedSearch = searchParams;
		}
		if (reset)
			this.loadItems();
		if (this.firsttime)
			this.firsttime = false;
		else
			this.loadItems();
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	delete(row: User, event: any) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-user-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.crudService.deleteEntity(MeetravelUser, 'user', row).subscribe(() => {
					this.translateService.get(['labels.user-deleted', 'labels.centri']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.user-deleted'], messages['labels.users']);
						this.loadItems();
					});
				});
			}
		});
	}

	unblock(row: User) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-user-unblock'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.userService.unblock(row.id).subscribe(() => {
					this.translateService.get(['labels.user-unblocked', 'labels.centri']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.user-unblocked'], messages['labels.users']);
						this.loadItems();
					});
				});
			}
		});
	}

	role(uid: number, name: string): void {
		const modal = this.modalService.open(UserRoleModalComponent, { centered: true, size: 'lg' });

		modal.componentInstance.uid = uid;
		modal.componentInstance.name = name;
		modal.result.then((newItem: User) => {
			this.loadItems();
		}).catch(() => {});
	}

	edit(uid: number): void {
		const modal = this.modalService.open(UserNewEditComponent, { centered: true, size: 'lg' });
		if (uid) {

			modal.componentInstance.load(uid);
		} else {

			modal.componentInstance.utype = (this.isStaff) ? UserType.ADMIN : UserType.USER;
		}
		modal.result.then((newItem: MeetravelUser) => {
			this.loadItems();
		}).catch(() => {});
	}









}
