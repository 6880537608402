import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search, SharedDataService, User } from 'elbuild-ui-lib-core';
import { Notification, UserService } from 'meetravel-ui-lib';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';

import { SendpushmodalComponent } from '../../sendpushmodal/sendpushmodal.component';

@Component({
	selector: 'app-notification-list',
	templateUrl: './notification-list.component.html',
	styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, AfterViewInit, OnDestroy {

	loading: boolean = false;
	rows: Notification[];

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
	showAdvancedSearch: boolean = true;

	advancedSearch: Search = new Search('notification', 'message', 'notification');

	loggedUser: User;
	subcription: Subscription;

	constructor(
		private provinceService: CrudService<Notification>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private router: Router,
		private sharedDataService: SharedDataService,
		private userService: UserService,
	) {


	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams();

		const requests: Observable<any>[] = [urlparamsreq];

		if (!this.loggedUser) {
			const userrequest = this.sharedDataService.currentLoggedUser;
			requests.push(userrequest);
		}

		this.subcription = combineLatest(requests).subscribe(data => {

			if (data[1]) {
				this.loggedUser = data[1];
			}

			this.search(data[0]);

		});
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		if (this.subcription) {
			this.subcription.unsubscribe();
		}
	}

	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;
		}
		this.load();
	}

	load() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.provinceService.searchEntities(Notification, 'notification', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.provinceService.countEntities(Notification, 'notification', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];

			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.load();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.load();
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	goToTravel(item: Notification, event: any) {
		this.router.navigateByUrl(`/travel/edit/${item.travelid}`);
	}

	openSendPushModal() {
		const modal = this.modalService.open(SendpushmodalComponent, { centered: true, size: 'lg' });
		modal.result.then((res) => {
			console.log(res);
			this.userService.sendPushNotification(res.userid, res.message, res.title, res.all).subscribe(() => {
				this.translateService.get(['labels.notification-sent', 'labels.notification']).subscribe(messages => {
					this.messageService.sendSuccess(messages['labels.notification-sent'], messages['labels.notification']);
				});
			}, ({ error }) => {
				this.translateService.get(['labels.notification-error', 'labels.notification']).subscribe(messages => {
					this.messageService.sendError(messages['labels.notification-error'], messages['labels.notification']);
				});
			});
		});
	}

	delete(row: Notification) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-notification-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.provinceService.deleteEntity(Notification, 'notification', row).subscribe(() => {
					this.translateService.get(['labels.notification-deleted', 'labels.notification']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.notification-deleted'], messages['labels.notification']);
						this.load();
					});
				});
			}
		});
	}


}
