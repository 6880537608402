import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbCollapseModule, NgbDatepickerModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbTabsetModule, NgbTimepickerModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgToggleModule } from '@nth-cloud/ng-toggle';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DialogService, ElbuildCoreComponentsModule } from 'elbuild-ui-componentslib';
import { BaseAuthModule, ElbuildUiLibModule, PipesModule } from 'elbuild-ui-lib-core';
import { CommonService, GamificationService, MeetravelUiLibModule, UserService } from 'meetravel-ui-lib';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TagInputModule } from 'ngx-chips';
import { SharedModule } from 'src/app/theme/shared/shared.module';

import { AlertListFiltersComponent } from './alert/alert-list/alert-list-filters/alert-list-filters.component';
import { AlertListComponent } from './alert/alert-list/alert-list.component';
import { BannerAddEditModalComponent } from './banner/banner-add-edit-modal/banner-add-edit-modal.component';
import { BannerListFiltersComponent } from './banner/banner-list/banner-list-filters/banner-list-filters.component';
import { BannerListComponent } from './banner/banner-list/banner-list.component';
import { DiscountAddEditModalComponent } from './discount/discount-add-edit-modal/discount-add-edit-modal.component';
import { DiscountListComponent } from './discount/discount-list/discount-list.component';
import { DiscountListFiltersComponent } from './discount/discount-list/discount-list-filters/discount-list-filters.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { MetricComponent } from './charts/metric/metric.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { CityEditComponent } from './city/city-edit/city-edit.component';
import { CityListFiltersComponent } from './city/city-list/city-list-filters/city-list-filters.component';
import { CityListComponent } from './city/city-list/city-list.component';
import { ConfEditComponent } from './conf/conf-edit/conf-edit.component';
import { ConfListFiltersComponent } from './conf/conf-list/conf-list-filters/conf-list-filters.component';
import { ConfListComponent } from './conf/conf-list/conf-list.component';
import { CountryEditComponent } from './country/country-edit/country-edit.component';
import { CountryListFiltersComponent } from './country/country-list/country-list-filters/country-list-filters.component';
import { CountryListComponent } from './country/country-list/country-list.component';
import { DashboardChatComponent } from './dashboard/dashboard-chat/dashboard-chat.component';
import { DashboardCustomerComponent } from './dashboard/dashboard-customer/dashboard-customer.component';
import { DashboardFeedbackComponent } from './dashboard/dashboard-feedback/dashboard-feedback.component';
import { FeedbackModalComponent } from './dashboard/dashboard-feedback/feedback-modal/feedback-modal.component';
import { DashboardInviteComponent } from './dashboard/dashboard-invite/dashboard-invite.component';
import { DashboardTravelModalComponent } from './dashboard/dashboard-travel/dashboard-travel-modal/dashboard-travel-modal.component';
import { DashboardTravelComponent } from './dashboard/dashboard-travel/dashboard-travel.component';
import { RangePickerFiltersComponent } from './dashboard/range-picker-filters/range-picker-filters.component';
import { DestinationListFiltersComponent } from './destination/destination-list/destination-list-filters/destination-list-filters.component';
import { DestinationListComponent } from './destination/destination-list/destination-list.component';
import { DestinationNewEditComponent } from './destination/destination-new-edit/destination-new-edit.component';
import { EditFeedbackModalComponent } from './feedback/edit-feedback-modal/edit-feedback-modal.component';
import { FeedbackListFiltersComponent } from './feedback/feedback-list/feedback-list-filters/feedback-list-filters.component';
import { FeedbackListComponent } from './feedback/feedback-list/feedback-list.component';
import { FollowerListFiltersComponent } from './follower/follower-list/follower-list-filters/follower-list-filters.component';
import { FollowerListComponent } from './follower/follower-list/follower-list.component';
import { FollowingListFiltersComponent } from './following/following-list/following-list-filters/following-list-filters.component';
import { FollowingListComponent } from './following/following-list/following-list.component';
import { GamificationListComponent } from './gamification/gamification-list/gamification-list.component';
import { GuestCardComponent } from './guest/guest-card/guest-card.component';
import { GuestListFiltersComponent } from './guest/guest-list/guest-list-filters/guest-list-filters.component';
import { GuestListComponent } from './guest/guest-list/guest-list.component';
import { GuestStatusBadgeComponent } from './guest/guest-status-badge/guest-status-badge.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ListItemEditComponent } from './list/list-item-edit/list-item-edit.component';
import { ListItemListComponent } from './list/list-item-list/list-item-list.component';
import { ListListComponent } from './list/list-list/list-list.component';
import { LoadingComponent } from './loading/loading.component';
import { MediaBoxComponent } from './media-box/media-box.component';
import { NotificationListFiltersComponent } from './notification/notification-list/notification-list-filters/notification-list-filters.component';
import { NotificationListComponent } from './notification/notification-list/notification-list.component';
import { ProductModalEditComponent } from './products/components/product-modal-edit/product-modal-edit.component';
import { ProductListFiltersComponent } from './products/products-list/product-list-filters/product-list-filters.component';
import { ProductsListComponent } from './products/products-list/products-list.component';
import { ProductsComponent } from './products/products.component';
import { ProvinceEditComponent } from './province/province-edit/province-edit.component';
import { ProvinceListFiltersComponent } from './province/province-list/province-list-filters/province-list-filters.component';
import { ProvinceListComponent } from './province/province-list/province-list.component';
import { PurchaseListFiltersComponent } from './purchase/purchase-list/purchase-list-filters/purchase-list-filters.component';
import { PurchaseListComponent } from './purchase/purchase-list/purchase-list.component';
import { PurchaseModalComponent } from './purchase/purchase-modal/purchase-modal.component';
import { SmartTransactionsListComponent } from './smart-transactions/smart-transactions-list/smart-transactions-list.component';
import { SmartTransactionsListFiltersComponent } from './smart-transactions/smart-transactions-list/smart-transactions-list-filters/smart-transactions-filters.component';
import { RegioneEditComponent } from './regione/regione-edit/regione-edit.component';
import { RegioneListFiltersComponent } from './regione/regione-list/regione-list-filters/regione-list-filters.component';
import { RegioneListComponent } from './regione/regione-list/regione-list.component';
import { ReportListFiltersComponent } from './report/report-list/report-list-filters/report-list-filters.component';
import { ReportListComponent } from './report/report-list/report-list.component';
import { NewEditFeatureComponent } from './role-features/new-edit-feature/new-edit-feature.component';
import { RoleFeaturesComponent } from './role-features/role-features.component';
import { SendpushmodalComponent } from './sendpushmodal/sendpushmodal.component';
import { TravelFeedbackListFiltersComponent } from './travel-feedback/travel-feedback-list/travel-feedback-list-filters/travel-feedback-list-filters.component';
import { TravelFeedbackListComponent } from './travel-feedback/travel-feedback-list/travel-feedback-list.component';
import { TravelListFiltersComponent } from './travel/travel-list/travel-list-filters/travel-list-filters.component';
import { TravelListComponent } from './travel/travel-list/travel-list.component';
import { TravelAccomodationComponent } from './travel/travel/travel-accomodation/travel-accomodation.component';
import { TravelChatComponent } from './travel/travel/travel-chat/travel-chat.component';
import { StatusAdminModalComponent } from './travel/travel/travel-detail/status-admin-modal/status-admin-modal.component';
import { TravelDetailComponent } from './travel/travel/travel-detail/travel-detail.component';
import { TravelMediaComponent } from './travel/travel/travel-media/travel-media.component';
import { TravelRatingComponent } from './travel/travel/travel-rating/travel-rating.component';
import { TravelTypesComponent } from './travel/travel/travel-types/travel-types.component';
import { TravelComponent } from './travel/travel/travel.component';
import { UserRoleModalComponent } from './user-role-modal/user-role-modal.component';
import { MeetravelUserListComponent } from './user/meetravel-user-list/meetravel-user-list.component';
import { StaffUserListComponent } from './user/staff-user-list/staff-user-list.component';
import { UserGamificationComponent } from './user/user-gamification/user-gamification.component';
import { UserListFiltersComponent } from './user/user-list/user-list-filters/user-list-filters.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserNewEditComponent } from './user/user-new-edit/user-new-edit.component';
import { UserProfilingComponent } from './user/user-profiling/user-profiling.component';
import { UserTravelComponent } from './user/user-travel/user-travel.component';
import { UserComponent } from './user/user/user.component';
import { WishlistComponent } from './user/wishlist/wishlist.component';
import { MetListComponent } from './met/met-list/met-list.component';
import { TestcreatorComponent } from './test/testcreator/testcreator.component';
import { DomandetestcreatorlistComponent } from './test/testcreator/domandetestcreatorlist/domandetestcreatorlist.component';
import { RispostetestcreatorlistComponent } from './test/testcreator/rispostetestcreatorlist/rispostetestcreatorlist.component';
import { DomandetestModalAddEditComponent } from './test/testcreator/components/domandetest-modal-add-edit/domandetest-modal-add-edit.component';
import { RispostetestModalAddEditComponent } from './test/testcreator/components/rispostetest-modal-add-edit/rispostetest-modal-add-edit/rispostetest-modal-add-edit.component';
import { UserTravelPartecipationListComponent } from './user/user-travel-partecipation-list/user-travel-partecipation-list.component';
import { TravelSmartComponent } from './travel/travel/travel-smart/travel-smart.component';
import { SmartTravelService } from '../data/smart-travel.service';



// tslint:disable-next-line:max-line-length



@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgbModule,
		NgbTooltipModule,
		NgbPaginationModule,
		NgbTypeaheadModule,
		NgbDatepickerModule,
		NgbTimepickerModule,
		RouterModule,
		ElbuildUiLibModule,
		PipesModule,
		ElbuildCoreComponentsModule,
		SharedModule,
		NgxDatatableModule,
		TranslateModule.forChild(),
		NgbCollapseModule,
		NgbTabsetModule,
		NgToggleModule,
		MeetravelUiLibModule,
		NgbModalModule,
		BaseAuthModule,
		ReactiveFormsModule,
		NgMultiSelectDropDownModule.forRoot(),
		TagInputModule
	],
	exports: [
		UserListComponent,
		UserListFiltersComponent,
		UserNewEditComponent,
		ListListComponent,
		ListItemListComponent,
		ListItemEditComponent,
	],
	declarations: [
		UserListComponent,
		UserListFiltersComponent,
		UserNewEditComponent,
		ListListComponent,
		ListItemListComponent,
		ListItemEditComponent,
		NewEditFeatureComponent,
		RoleFeaturesComponent,
		ConfEditComponent,
		ConfListComponent,
		ConfListFiltersComponent,
		CityEditComponent,
		CityListFiltersComponent,
		CityListComponent,
		CountryEditComponent,
		CountryListFiltersComponent,
		CountryListComponent,
		ProvinceEditComponent,
		ProvinceListFiltersComponent,
		ProvinceListComponent,
		RegioneEditComponent,
		RegioneListFiltersComponent,
		RegioneListComponent,
		UserComponent,
		TravelListComponent,
		TravelListFiltersComponent,
		TravelComponent,
		TravelDetailComponent,
		TravelTypesComponent,
		TravelAccomodationComponent,
		GuestCardComponent,
		GuestListComponent,
		GuestListFiltersComponent,
		TravelChatComponent,
		FeedbackListComponent,
		FeedbackListFiltersComponent,
		ReportListComponent,
		ReportListFiltersComponent,
		MeetravelUserListComponent,
		StaffUserListComponent,
		UserTravelComponent,
		AlertListComponent,
		AlertListFiltersComponent,
		DestinationListComponent,
		DestinationListFiltersComponent,
		DestinationNewEditComponent,
		WishlistComponent,
		DashboardCustomerComponent,
		LineChartComponent,
		RangePickerFiltersComponent,
		LoadingComponent,
		MetricComponent,
		DashboardTravelComponent,
		PieChartComponent,
		DashboardInviteComponent,
		DashboardFeedbackComponent,
		DashboardChatComponent,
		TravelRatingComponent,
		TravelFeedbackListComponent,
		TravelFeedbackListFiltersComponent,
		ImageModalComponent,
		GuestStatusBadgeComponent,
		NotificationListComponent,
		NotificationListFiltersComponent,
		UserProfilingComponent,
		EditFeedbackModalComponent,
		GamificationListComponent,
		UserGamificationComponent,
		StatusAdminModalComponent,
		TravelMediaComponent,
		MediaBoxComponent,
		FeedbackModalComponent,
		BannerListComponent,
		BannerListFiltersComponent,
		BannerAddEditModalComponent,
		DiscountListComponent,
		DiscountListFiltersComponent,
		DiscountAddEditModalComponent,
		UserRoleModalComponent,
		DashboardTravelModalComponent,
		ProductsComponent,
		ProductsListComponent,
		ProductListFiltersComponent,
		PurchaseListComponent,
		PurchaseListFiltersComponent,
		ProductModalEditComponent,
		PurchaseModalComponent,
		SmartTransactionsListComponent,
		SmartTransactionsListFiltersComponent,
		FollowerListComponent,
		FollowerListFiltersComponent,
		FollowingListComponent,
		FollowingListFiltersComponent,
		SendpushmodalComponent,
		MetListComponent,
		TestcreatorComponent,
		DomandetestcreatorlistComponent,
		RispostetestcreatorlistComponent,
		DomandetestModalAddEditComponent,
		RispostetestModalAddEditComponent,
		UserTravelPartecipationListComponent,
		TravelSmartComponent,
	],

	providers: [DialogService, NgbActiveModal, UserService, GamificationService, CommonService, SmartTravelService],
	entryComponents: [
	]
})
export class CoreComponentsModule {
}
