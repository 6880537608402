import { Component, OnInit, OnDestroy } from '@angular/core';
import { Provincia, Regione, Comune } from 'meetravel-ui-lib';
import { Search, CrudService, SharedDataService, MessageService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-city-edit',
	templateUrl: './city-edit.component.html',
	styleUrls: ['./city-edit.component.scss']
})
export class CityEditComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	city: Comune = new Comune();
	province: {
		search: Search,
		selected: Provincia
	};


	get title(): string {
		return this.city?.id ? 'city-edit' : 'city-create';
	}


	constructor(
		private provinceService: CrudService<Provincia>,
		private sharedDataService: SharedDataService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private cityService: CrudService<Comune>
	) {
		this.province = {
			search: new Search('province'),
			selected: undefined
		};
	}

	ngOnInit() { }

	ngOnDestroy(): void {
		this.sharedDataService.addCommonData('route', undefined);
	}

	load(cityid: number): void {
		this.loading = true;
		this.cityService.getEntity(Comune, 'comuni', cityid).subscribe((comune: Comune) => {
			this.city = comune;
			this.findItems();
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.city'));
			this.city = undefined;
			this.loading = false;
		});
	}
	findItems() {
		this.provinceService.getEntity(Provincia, 'province', this.city.pid).subscribe((res: Provincia) => this.province.selected = res);
	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	submit(): void {

		this.setSelectedFields();

		if (!this.city.id) {
			this.cityService.createEntity(Comune, 'comuni', this.city).subscribe((response: Comune) => {
				this.city = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.city-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.city);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {
			this.cityService.saveEntity(Comune, 'comuni', this.city).subscribe((response: Comune) => {
				this.city = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.city-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.city);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

	setSelectedFields(): void {
		if (this.province.selected) {
			this.city.pid = this.province.selected.id;
			this.city.provincia = this.province.selected.descrizione;
		}
	}

}
