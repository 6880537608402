import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassType } from 'class-transformer/ClassTransformer';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';
import { newDate, ParamsService, Search, SortDirection } from 'elbuild-ui-lib-core';
import { MeetravelUser, Travel, UserType } from 'meetravel-ui-lib';

@Component({
	selector: 'app-feedback-list-filters',
	templateUrl: './feedback-list-filters.component.html',
	styleUrls: ['./feedback-list-filters.component.scss']
})
export class FeedbackListFiltersComponent extends ListFiltersComponent implements OnInit {


	@Input() travelid: number;
	@Input() sent: boolean = false;
	@Input() received: boolean = false;
	@Input() uid: number;

	keyword: string;
	active: boolean = true;

	searchUsers: Search;
	userClass: ClassType<MeetravelUser> = MeetravelUser;
	senderSelected: MeetravelUser;
	receiverSelected: MeetravelUser;

	searchTravel: Search;
	travelClass: ClassType<Travel> = Travel;
	travelSelected: Travel;

	@Output() searchEvent = new EventEmitter<Search>();

	toBeApproved: boolean = false;
	mediaonly: boolean = false;
	partiti: boolean = false;

	insdate = { from: undefined, to: undefined };


	constructor(router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService) {
		super(router, _route, paramsService);
		this.searchUsers = new Search('user');
		this.searchUsers.addSimpleFilter('utype', UserType.USER);

		this.searchTravel = new Search('travel');


	}

	ngOnInit() {
		this.resetSearch();
	}

	onSelectedreceiver(event: MeetravelUser) {
		this.searchparams.addSimpleFilter('receiverid', event.id);
	}

	onDeletereceiver() {
		this.searchparams.removeSimpleFilter('receiverid');
	}

	onSelectedSender(event: MeetravelUser) {
		this.searchparams.addSimpleFilter('senderid', event.id);
	}

	onDeleteSender() {
		this.searchparams.removeSimpleFilter('senderid');
	}

	onSelectedtravel(event: Travel) {
		this.searchparams.addSimpleFilter('travelid', event.id);
	}

	onDeletetravel() {
		this.searchparams.removeSimpleFilter('travelid');
	}

	readQueryParams(params: string) {
		super.readQueryParams(params);
	}

	resetSearch() {
		this.keyword = undefined;
		this.active = true;
		this.searchparams.reset();
		this.receiverSelected = undefined;
		this.senderSelected = undefined;
		this.travelSelected = undefined;
		this.toBeApproved = false;
		this.mediaonly = false;
		this.partiti = false;
		this.insdate.from = undefined;
		this.insdate.to = undefined;
		this.searchparams.removeRangeFilter('insdate');

		this.searchparams.setSortDirection(SortDirection.DESCENDING);
		this.searchparams.setSortField('id');
		if (this.travelid) {
			this.searchparams.addSimpleFilter('travelid', this.travelid);
		} else if (this.sent) {
			this.searchparams.addSimpleFilter('senderid', this.uid);
		} else if (this.received) {
			this.searchparams.addSimpleFilter('receiverid', this.uid);
		}
		this.search();

	}

	search(keeppage?: boolean) {
		if (this.senderSelected) {
			this.searchparams.addSimpleFilter('senderid', this.senderSelected.id);
		}
		if (this.receiverSelected) {
			this.searchparams.addSimpleFilter('receiverid', this.receiverSelected.id);
		}
		if (this.travelSelected) {
			this.searchparams.addSimpleFilter('travelid', this.travelSelected.id);
		}
		if (this.insdate.from || this.insdate.to) {
			if (typeof (this.insdate.from) != 'number') {
				this.insdate.from = (this.insdate.from as Date).getTime();
			}

			if (typeof (this.insdate.to) != 'number') {
				this.insdate.to = (this.insdate.to as Date).getTime();
			}
			const start = this.insdate.from ? this.insdate.from : newDate().getTime();
			const stop = this.insdate.to ? this.insdate.to : newDate(true).getTime();
			this.searchparams.addRangeFilter('insdate', start / 1000, Math.round(stop / 1000));
		} else this.searchparams.removeRangeFilter('insdate');
		this.searchEvent.emit(this.searchparams);
	}
}
