<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.reporter' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedReporter($event)"
						(onDelete)="onDeleteReporter()"
						name="reporter"
						[(ngModel)]="reporterSelected"
						placeholder="{{ 'labels.reporter' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.reported' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedReported($event)"
						(onDelete)="onDeleteReported()"
						name="reported"
						[(ngModel)]="reportedSelected"
						placeholder="{{ 'labels.reported' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.status' | translate}}</label>
					<select
						class="form-control"
						name="status"
						[(ngModel)]="searchparams.simpleTmp['status']">
						<option></option>
						<option
							*ngFor="let item of statuses"
							[value]="item">
							{{'types.user-report.'+item | translate}}
						</option>
					</select>
				</div>
			</div>
			<div class="col-md-4">
				<label>
					{{'labels.insdate' | translate}} {{'labels.min' | translate}}
				</label>
				<app-datepicker
					name="insdatestart"
					[millis]="true"
					[(ngModel)]="searchparams.rangeTmp['insdate'].start">
				</app-datepicker>
			</div>
			<div class="col-md-4">
				<label>
					{{'labels.insdate' | translate}} {{'labels.max' | translate}}
				</label>
				<app-datepicker
					name="insdatestop"
					[millis]="true"
					[(ngModel)]="searchparams.rangeTmp['insdate'].stop">
				</app-datepicker>
			</div>

		</div>

		<div class="row">
			<div class="col-md-4">
				<div>
					<div class="form-check">
						<label class="fake-label"></label>
						<input
							id="blockuser"
							type="checkbox"
							name="blockuser"
							[(ngModel)]="blockuser"
							(click)="searchparams.simpleTmp['reporter.utype'] = (($event.target.checked) ? 'user' : ''); searchparams.simpleTmp['reportreason'] = (($event.target.checked) ? 'Blocca utente' : '');">
						<label
							for="blockuser"
							class="ml-1">
							Solo bloccati dagli utenti
						</label>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div>
					<div class="form-check">
						<label class="fake-label"></label>
						<input
							id="blockadmin"
							type="checkbox"
							name="blockadmin"
							[(ngModel)]="blockadmin"
							(click)="searchparams.simpleTmp['reporter.utype'] = (($event.target.checked) ? 'admin' : '');">
						<label
							for="blockadmin"
							class="ml-1">
							Solo bloccati dagli admin
						</label>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div>
					<div class="form-check">
						<label class="fake-label"></label>
						<input
							id="temporaryblock"
							type="checkbox"
							name="temporaryblock"
							[(ngModel)]="temporaryblock"
							(click)="searchparams.likeTmp['reportreason'] = (($event.target.checked) ? 'Ban temporaneo' : '')">
						<label
							for="temporaryblock"
							class="ml-1">
							Solo ban temporanei
						</label>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
				<a
					class="btn btn-success float-right"
					(click)="loading ? undefined : export()">
					<i
						*ngIf='loading'
						class="fas fa-spinner fa-spin"></i>
					{{'labels.export-xlsx' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
