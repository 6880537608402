import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-testcreator',
	templateUrl: './testcreator.component.html',
	styleUrls: ['./testcreator.component.scss']
})
export class TestcreatorComponent implements OnInit {

	constructor() {}

	ngOnInit(): void {

	}

}