<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4 class="modal-title">{{'labels.' + title | translate}}</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>

		<div class="row">
			<div class="col-sm-12">
				<div class="row">
					<!-- fname -->
					<div class="form-group col-md-6">
						<label for="name">{{ 'labels.name' | translate }}*</label>
						<input
							[(ngModel)]="feature.name"
							#name="ngModel"
							name="name"
							required
							type="text"
							class="form-control"
							id="name"
							placeholder="{{ 'labels.fname' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && name.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && name.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md-6">
						<label for="tag">{{ 'labels.tag' | translate }}*</label>
						<input
							[(ngModel)]="feature.tag"
							#tag="ngModel"
							name="tag"
							required
							type="text"
							class="form-control"
							id="cname"
							placeholder="{{ 'labels.tag' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && tag.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && tag.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
					<div class="form-group col-md-6">
						<label for="slug">{{ 'labels.slug' | translate }}*</label>
						<input
							[(ngModel)]="feature.slug"
							#slug="ngModel"
							name="slug"
							required
							type="text"
							class="form-control"
							id="cname"
							placeholder="{{ 'labels.slug' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && slug.invalid}">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && slug.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class="modal-footer">

		<button
			type="reset"
			class="btn btn-secondary">
			{{'labels.reset' | translate}}
		</button>
		<button
			type="button"
			class="btn btn-secondary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
	</div>
</form>
