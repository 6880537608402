import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AclService, CrudService, ListItem, ListOptions, ListService, MessageService, Page, Search } from 'elbuild-ui-lib-core';
import { MeetravelUser, UserInterest, UserTravelType } from 'meetravel-ui-lib';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

import { AdminFeatures } from '../../conf/features';

@Component({
	selector: "app-user-profiling",
	templateUrl: "./user-profiling.component.html",
	styleUrls: ["./user-profiling.component.scss"],
})
export class UserProfilingComponent implements OnInit {
	@Input() meetraveluser: MeetravelUser;

	interestsList: any[] = [];
	userInterestsChips: any[] = [];
	userInterests: UserInterest[];
	interestsToAdd: UserInterest[] = [];
	interestsIdToRemove: UserInterest[] = [];
	traveltype: UserTravelType;
	savetraveltype: boolean;
	editMode: boolean;
	leftctr: number;
	absencectr: number;
	FEATURE_EDIT_UTENTI = AdminFeatures.ADMIN_EDIT_USER;
	feature_editable = false;
	editavgtravel = false;



	@Output() saved = new EventEmitter<any>();

	constructor(
		private userInterestService: CrudService<UserInterest>,
		private userService: CrudService<MeetravelUser>,
		private listService: ListService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private userTravelTypeService: CrudService<UserTravelType>,
		private aclService: AclService,
	) {
		this.loadList();
	}

	ngOnInit() {
		this.leftctr = this.meetraveluser.leftctr;
		this.absencectr = this.meetraveluser.absencectr;
		this.loadUserInterests();
		this.aclService.hasPermission(this.FEATURE_EDIT_UTENTI).subscribe(res => {
			this.feature_editable = res;
		})
		this.traveltype = this.meetraveluser.style
			? this.meetraveluser.style
			: new UserTravelType(this.meetraveluser.id);
	}

	loadList() {
		const options = new ListOptions();
		options.listitemname = "selistitem";
		options.listname = "selist";
		options.numitem = 100;
		this.listService.getListItemsBySlug("user.interest", options).subscribe(
			(result) => {
				this.interestsList = result.map((c: ListItem) => {
					return { display: c.itemlabel, value: c.itemvalue };
				});
			},
			(error) => {
				this.messageService.sendError(error.error.msg);
			}
		);
	}

	loadUserInterests() {
		const search = new Search("userinterest");
		search.addSimpleFilter("userid", this.meetraveluser.id);
		this.userInterestService
			.searchEntities(UserInterest, "userinterest", search, new Page(100))
			.subscribe(
				(result) => {
					this.userInterests = result;
					this.userInterestsChips = result.map((c: UserInterest) => {
						return { display: c.interest, value: c.interest };
					});
				},
				(error) => {
					this.messageService.sendError(error.error.msg);
				}
			);
	}

	addInterest(event: any) {
		let interest = this.userInterests.filter(
			(e) => e.interest === event.value
		)[0];
		if (!interest) {
			interest = new UserInterest();
			interest.userid = this.meetraveluser.id;
			interest.interest = event.value;
			this.interestsToAdd.push(interest);
		}
	}

	removeInterest(event: any) {
		const interest = this.userInterests.filter(
			(e) => e.interest === event.value
		)[0];
		if (interest) {
			this.interestsIdToRemove.push(interest);
		}
	}

	travelTypeChange(event: any) {
		this.savetraveltype = true;
	}

	save() {
		if (this.meetraveluser.leftctr != this.leftctr) {
			this.meetraveluser.leftctr = this.leftctr;
			const today = moment().format('D/MM/yyyy');
			this.meetraveluser.note = 'Modificate da admin in data: ' + today;
		}
		if (this.meetraveluser.absencectr != this.absencectr) {
			this.meetraveluser.absencectr = this.absencectr;
			const today = moment().format('D/MM/yyyy');
			this.meetraveluser.note = 'Modificate da admin in data: ' + today;
		}
		const calls = [];
		this.interestsToAdd.forEach((i) =>
			calls.push(
				this.userInterestService.createEntity(UserInterest, "userinterest", i)
			)
		);
		this.interestsIdToRemove.forEach((i) =>
			calls.push(
				this.userInterestService.deleteEntity(UserInterest, "userinterest", i)
			)
		);
		if (this.savetraveltype) {
			calls.push(
				this.traveltype.id
					? this.userTravelTypeService.saveEntity(
						UserTravelType,
						"usertraveltype",
						this.traveltype
					)
					: this.userTravelTypeService.createEntity(
						UserTravelType,
						"usertraveltype",
						this.traveltype
					)
			);
		}

		calls.push(
			this.userService.saveEntity(MeetravelUser, "user", this.meetraveluser)
		);
		forkJoin(calls).subscribe(
			(result) => {
				this.messageService.sendSuccess(
					this.translateService.instant("labels.interests-saved"),
					this.translateService.instant("labels.user")
				);
				this.interestsToAdd = [];
				this.interestsIdToRemove = [];
				this.loadUserInterests();
				this.saved.emit();
			},
			(error) => {
				this.messageService.sendError(error.error.msg);
			}
		);
		this.editMode = false;
	}
}
