import { UserService } from './user.service';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AclService } from './acl.service';
import { RoleService } from './role.service';
import { WindowService } from './window.service';
import { VersionCheckService } from './version-check.service';

const SERVICES = [
	AclService,
	RoleService,
	UserService,
	WindowService,
	VersionCheckService
];

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		...SERVICES,
	],
})
export class DataModule {
	static forRoot(): ModuleWithProviders<DataModule> {
		return {
			ngModule: DataModule,
			providers: [
				...SERVICES,
			],
		};
	}
}
