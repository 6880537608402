<div class="row">
	<div class="col-sm-12">
		<app-travel-list-filters
			#listfilters
			[showFilters]="showFilters"
			[searchparams]="advancedSearch">
		</app-travel-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					(activate)="onActivate($event)"
					[messages]="{
          emptyMessage: 'labels.empty-travel' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="name"
						[flexGrow]="1"
						[name]="'labels.name' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a
								(click)="close()"
								[routerLink]="['/travel/edit/'+row.id]">
								{{value}}
							</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="destination"
						[flexGrow]="1"
						[name]="'labels.destination' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="organizer"
						[flexGrow]="1.5"
						[sortable]="false"
						[name]="'labels.organizer' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value.fullname}}
							<i
								[routerLink]="['/user/edit/'+row.hostid]"
								class="fas fa-external-link-alt"></i>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[name]="'labels.guests' | translate"
						[sortable]="false"
						[resizeable]="false">
						<ng-template
							let-row="row"
							ngx-datatable-cell-template>
							{{row.currentguests}} / {{row.maxguests}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[sortable]="false"
						[flexGrow]="1"
						[name]="'labels.budgetrange' | translate"
						[resizeable]="false">
						<ng-template
							let-row="row"
							ngx-datatable-cell-template>
							{{row.budgetmin | currency : '€'}} - {{row.budgetmax | currency : '€'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[sortable]="false"
						[flexGrow]="1"
						[name]="'labels.smart' | translate"
						[resizeable]="false">
						<ng-template
							let-row="row"
							ngx-datatable-cell-template>
							{{(row.smart ? 'labels.yes':'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="status"
						[flexGrow]="1"
						[name]="'labels.status' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ 'types.travel-status.' + value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="begindate"
						[flexGrow]="1"
						[name]="'labels.arrive' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="enddate"
						[flexGrow]="1"
						[name]="'labels.return' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy HH:mm'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="premium"
						[flexGrow]="1"
						[name]="'labels.premium' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ value ? ('labels.yes' | translate) :  ('labels.no'| translate)}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="happened"
						[flexGrow]="1"
						[name]="'labels.happened' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{ 'types.happened.' + value | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ng-template [ngIf]="showFilters">
						<ngx-datatable-column
							[flexGrow]="1"
							[sortable]="false"
							[resizeable]="false"
							[cellClass]="'text-center'">
							<ng-template
								let-value="value"
								let-row="row"
								ngx-datatable-cell-template>
								<div class="icon-buttons">
									<a [routerLink]="'/travel/edit/'+row.id">
										<i
											container="body"
											ngbTooltip="{{'labels.edit' | translate}}"
											class="feather icon-edit"></i>
									</a>
									<a
										*ngIf="row.isSubmitted"
										(click)="accept(row, $event)">
										<i
											container="body"
											ngbTooltip="{{'labels.accept' | translate}}"
											class="feather icon-check"></i>
									</a>
									<a
										*ngIf="row.isSubmitted"
										(click)="decline(row, $event)">
										<i
											container="body"
											ngbTooltip="{{'labels.decline' | translate}}"
											class="feather icon-x"></i>
									</a>
									<a (click)="delete(row, $event)">
										<i
											container="body"
											ngbTooltip="{{'labels.delete' | translate}}"
											class="feather icon-trash"></i>
									</a>
									<a>
										<i
											*ngIf="row.softblock"
											container="body"
											ngbTooltip="{{'labels.softblock' | translate}}"
											class="feather text-danger icon-lock"></i>
									</a>

								</div>
							</ng-template>
						</ngx-datatable-column>
					</ng-template>

				</ngx-datatable>

			</div>
		</app-card>
	</div>

</div>
