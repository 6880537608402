<div class="row">
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h5 class="card-title">{{"labels.roles" | translate}}</h5>
				<br>
				<div
					class="rolerow"
					*ngFor="let role of roles; let i = index"
					[ngClass]="{'firstrow': i === 0, 'selectedRid': selectedRid === role.id}"
					(click)="setSelectedRid(role)">
					<p>{{role.rolename}}</p>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">

				<h5 class="row card-title">
					<div class="col-md-10">{{"labels.features" | translate}}</div>
					<!-- <div class="col-md-2 float-right">
						<select
							class="form-control"
							name="tag"
							(change)="loadFeatures()"
							[(ngModel)]="selectedSearchTag">
							<option [ngValue]="null">{{"labels.all" | translate}}</option>
							<option
								*ngFor="let tag of tagSelect"
								[ngValue]="tag">
								{{tag}}
							</option>
						</select>
					</div> -->
					<div class="col-md-1 float-right">
						<button
							class="btn btn-primary btn-small "
							(click)="openFeatureModal()">
							{{"labels.new" | translate}}
						</button>
					</div>

				</h5>
				<br>
				<div
					class="rolerow"
					*ngFor="let feature of features; let i = index"
					[ngClass]="{'firstrow': i === 0}">
					<p>
						{{feature.name}}
						<a
							(click)="openFeatureModal(feature)"
							class="link float-right ml-1">
							<i
								container="body"
								ngbTooltip="{{'labels.edit' | translate}}"
								class="feather icon-edit"></i>
						</a>
						<a
							(click)="delete(feature)"
							class="link float-right ml-1">
							<i
								container="body"
								ngbTooltip="{{'labels.delete' | translate}}"
								class="feather icon-trash"></i>
						</a>
						<input
							type="checkbox"
							class="float-right"
							(click)="addRemoveFeatureToRole(selectedRid, feature.id, $event)"
							[checked]="isFeatureChecked(feature.id)"
							*ngIf="!loadingFeature">
					</p>
					<span>
						<strong>{{feature.tag}}</strong>
						{{feature.slug}}
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
