
export class Feature {
    id: number;
    slug: string;
    tag: string;
    name: string;
    available: boolean;

    filter(filter: string) {
        return !filter || !this.name || this.name.toLowerCase().includes(filter.toLowerCase())
    }

    isInSection(section: Feature) {
        const s = section.getSection();
        if (s) {
            return this.slug.startsWith(s + '.');
        } else {
            return false;
        }

    }


    getSection() {
        if (this.slug.includes('section.')){
            return this.slug.replace('section.', '');
        } else {
            return undefined;
        }
    }
}
