import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, newDate, RangeFilter, Search, SharedDataService, SortDirection, User } from 'elbuild-ui-lib-core';
import { Travel, TravelService } from 'meetravel-ui-lib';
import { forkJoin, Subscription } from 'rxjs';

@Component({
	selector: 'app-travel-list',
	templateUrl: './travel-list.component.html',
	styleUrls: ['./travel-list.component.scss']
})
export class TravelListComponent implements OnInit {


	loading: boolean = false;
	rows: Travel[] = [];

	@Input()
	showFilters: boolean = true;

	@Input()
	type: string;

	@Input()
	rangeFilter: RangeFilter;

	@Output() closeModal = new EventEmitter<any>();


	advancedSearch: Search = new Search('travel', `concat(travel.name,' ',travel.destination)`, 'travel');

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
	showAdvancedSearch: boolean = true;
	loggedUser: User;
	subcription: Subscription;


	constructor(private crudService: CrudService<Travel>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private travelService: TravelService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private sharedDataService: SharedDataService,
		private modalService: NgbModal) {
		this.advancedSearch.jointable = 'travel join destination on travel.destinationid = destination.id';
		this.advancedSearch.setSortDirection(SortDirection.DESCENDING);
		this.advancedSearch.setSortField('id');
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams().subscribe(data => {
			if (data) {
				if (data[1]) {
					this.loggedUser = data[1];
				}

				this.search(data[0]);
			}

		});
	}

	ngOnInit() {

	}

	close() {
		this.closeModal.emit();
	}

	ngOnDestroy() {
		if (this.subcription) {
			this.subcription.unsubscribe();
		}

	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.crudService.searchEntities(Travel, 'travel', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(Travel, 'travel', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}


	onPageChange(pageInfo: any) {

		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onActivate(event: any) {
		if (true /*this.aclService.hasPermission('centri.detail')*/) {
			if (event.type === 'dblclick') {
				this.router.navigate([`/travel/edit/${event.row.id}`], { queryParamsHandling: 'merge' });
				if (!this.showFilters) {
					this.closeModal.emit();
				}
			}
		}
	}


	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;

		}
		if (this.type) {
			this.setValues()
		}
		this.loadItems();
	}

	setValues() {
		switch (this.type) {
			case 'happened': {
				if (this.rangeFilter.from && this.rangeFilter.to) {
					const start = this.rangeFilter.from ? this.rangeFilter.from : newDate().getTime();
					const stop = this.rangeFilter.to ? this.rangeFilter.to : newDate().getTime();
					this.advancedSearch.addRangeFilter('enddate', Math.round(start / 1000), Math.round(stop / 1000));
				}
				this.advancedSearch.addSimpleFilter('happened', 1);
				break;
			}
			case 'running': {
				if (this.rangeFilter.from && this.rangeFilter.to) {
					const enddateStart = this.rangeFilter.from ? this.rangeFilter.from : newDate().getTime();
					const enddateStop = this.rangeFilter.to ? (Number.MAX_SAFE_INTEGER / 100) : newDate().getTime();
					this.advancedSearch.addRangeFilter('enddate', Math.round(enddateStart / 1000), Math.round(enddateStop / 1000));

					const begindateStart = this.rangeFilter.from ? 0 : newDate().getTime();
					const begindateStop = this.rangeFilter.to ? this.rangeFilter.to : newDate().getTime();
					this.advancedSearch.addRangeFilter('begindate', Math.round(begindateStart / 1000), Math.round(begindateStop / 1000));

					const selecteddStatus = ['inprogress', 'active', 'closed']
					this.advancedSearch.addInFilter('status', selecteddStatus);
				}
				break;
			}
			case 'created': {
				if (this.rangeFilter.from && this.rangeFilter.to) {
					const start = this.rangeFilter.from ? this.rangeFilter.from : newDate().getTime();
					const stop = this.rangeFilter.to ? this.rangeFilter.to : newDate().getTime();
					this.advancedSearch.addRangeFilter('insdate', Math.round(start / 1000), Math.round(stop / 1000));
				}
				break;
			}
		}
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	delete(row: User, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.crudService.deleteEntity(Travel, 'travel', row).subscribe(() => {
					this.translateService.get(['labels.travel-deleted', 'labels.travel']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.travel-deleted'], messages['labels.travel']);
						this.loadItems();
					});
				});
			}
		});
	}

	accept(travel: Travel, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-accept'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.travelService.accept(travel).subscribe(results => {
					this.loadItems();
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.travel'));
				});
			}
		});
	}

	decline(report: Travel, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-decline'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.travelService.decline(report).subscribe(results => {
					this.loadItems();
					this.messageService.sendSuccess(this.translateService.instant('labels.travel-declined'), this.translateService.instant('labels.travel-declined'));
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.report'));
				});
			}
		});
	}

}
