<div [hidden]="chartStruct?.line?.length === 0 || loading">
	<div
		[id]="divid"
		#chartdiv
		style="width: 100%; height: 500px"></div>
</div>

<!-- <div class='text-center' *ngIf='datas?.length !== 0 && loading'>
    <app-loading [full]='false' ></app-loading>
    <label>Attendere generazione grafico...</label>
</div> -->

<div
	*ngIf="chartStruct?.line?.length === 0"
	class="text-center">
	<strong>{{'labels.empty-chart' | translate}}</strong>
</div>
