import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Page, Search } from 'elbuild-ui-lib-core';

import { Feature } from '../../model/feature.model';
import { Role, RoleFeature } from '../../model/role.model';
import { NewEditFeatureComponent } from './new-edit-feature/new-edit-feature.component';

@Component({
	selector: 'app-role-features',
	templateUrl: './role-features.component.html',
	styleUrls: ['./role-features.component.scss']
})
export class RoleFeaturesComponent implements OnInit {

	roles: Role[];
	features: Feature[];
	rolefeature: RoleFeature[];
	searchFeature: Search;
	selectedRid: number;
	loadingFeature: boolean;

	tagSelect: string[] = [];
	selectedSearchTag: string;

	constructor(private roleCrudeService: CrudService<Role>,
		private featureCrudService: CrudService<Feature>,
		private rolefeatureCrudService: CrudService<RoleFeature>,
		private messageService: MessageService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		private modalService: NgbModal) {
		this.searchFeature = new Search('roletofeature');
		this.loadRoles();
		this.loadFeatures();
	}

	ngOnInit() {
	}

	loadRoles() {
		this.roleCrudeService.getAll(Role, 'role').subscribe(result => {
			this.roles = result;
			this.setSelectedRid(this.roles[0]);

		}, error => {});
	}


	loadFeatures() {
		const searchFeature = new Search('systemfeature');

		if (this.selectedSearchTag) {
			searchFeature.addSimpleFilter('tag', this.selectedSearchTag);
		} else {
			this.searchFeature.removeSimpleFilter('tag');
		}
		//TODO: endopoint max 100 ma può essere bloccante in futuro se si aggiungono molte feature
		this.featureCrudService.searchEntities(Feature, 'systemfeature', searchFeature, new Page(100)).subscribe(result => {
			this.features = result;
			//prendo tutti i tag possibili trovati e rimuovo i duplicati
			if (!this.selectedSearchTag)
				this.tagSelect = this.features.map(f => f.tag).filter((v, i, a) => a.findIndex(v2 => (v2 === v)) === i)

		}, error => {});
	}

	loadRoleFeatures(rid: number) {
		this.searchFeature.removeSimpleFilter('rid');
		this.searchFeature.addSimpleFilter('rid', rid);

		this.rolefeatureCrudService.searchEntities(RoleFeature, 'roletofeature', this.searchFeature, new Page(100)).subscribe(result => {
			this.rolefeature = result;
			this.loadingFeature = false;
		}, error => {});
	}

	setSelectedRid(role: Role) {
		this.loadingFeature = true;
		this.selectedRid = role.id;
		this.loadRoleFeatures(this.selectedRid);
	}

	addRemoveFeatureToRole(rid: number, fid: number, event: any) {
		const value = event.target.checked;
		if (value) {
			const payload = {
				rid: rid,
				fid: fid
			};
			this.rolefeatureCrudService.createEntity(RoleFeature, 'roletofeature', payload).subscribe(result => {
				this.rolefeature.push(result);
			}, error => {});
		} else {
			const featureToRemove = this.rolefeature.filter(item => item.fid === fid)[0];
			this.rolefeatureCrudService.deleteEntity(RoleFeature, 'roletofeature', featureToRemove).subscribe(result => {
				this.rolefeature = this.rolefeature.filter(item => item.fid !== fid);
			}, error => {});
		}
	}

	openFeatureModal(feature?: Feature) {
		const modal = this.modalService.open(NewEditFeatureComponent, { centered: true, size: 'lg' });

		modal.componentInstance.feature = feature ? feature : new Feature();
		//init creazione qui
		if (!feature) {
			modal.componentInstance.feature.tag = this.selectedSearchTag;
		}

		modal.result.then((item: Feature) => {
			const call = item.id ? this.featureCrudService.saveEntity(Feature, 'systemfeature', item) : this.featureCrudService.createEntity(Feature, 'systemfeature', item);
			call.subscribe(result => {
				this.loadFeatures();
				this.messageService.sendSuccess(this.translateService.instant('labels.feature-edited'), this.translateService.instant('labels.feature'));

			}, error => {});
		}).catch(() => {});
	}

	delete(row: Feature) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-feature-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.featureCrudService.deleteEntity(Feature, 'systemfeature', row).subscribe(() => {
					this.loadFeatures();
					this.translateService.get(['labels.feature-deleted', 'labels.feature']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.feature-deleted'], messages['labels.feature']);
					});
				});
			}
		});
	}

	isFeatureChecked(fid) {
		return this.rolefeature.map(item => item.fid).includes(fid);
	}

}
