import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { Attach, CrudService, MessageService } from 'elbuild-ui-lib-core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-media-box',
  templateUrl: './media-box.component.html',
  styleUrls: ['./media-box.component.scss']
})
export class MediaBoxComponent implements OnInit {

  @Input()
  attach: any;
  @Input()
  guest: any;
  baseurl: string = environment.baseurl;


  @Output() reload = new EventEmitter<string>();

  constructor(private crudService: CrudService<Attach>,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translateService: TranslateService,) { }

  ngOnInit() {
    this.attach.size = Math.trunc(this.attach.size / 1024);
  }


  callParent(): void {
    this.reload.next('reloadItems');
  }


  deleteAttach(attach: any) {
    this.dialogService.openConfirmModal({
      message: this.translateService.instant('labels.warning-media-delete'),
      hasIcon: true,
      type: 'warning'
    }).then(result => {
      if (result) {
        this.crudService.deleteEntity(Attach, 'attach', attach)
          .subscribe(() => {
            this.translateService.get(['labels.media-deleted', 'labels.centri']).subscribe(messages => {
              this.messageService.sendSuccess(messages['labels.media-deleted'], messages['labels.note']);
              this.callParent();
            });
          });
      }
    });
  }

}
