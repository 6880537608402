import { Component, OnInit, Input } from '@angular/core';
import { Travel, TravelType } from 'meetravel-ui-lib';
import { ListService, Search, Page, CrudService, List, ListItem } from 'elbuild-ui-lib-core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-travel-types',
  templateUrl: './travel-types.component.html',
  styleUrls: ['./travel-types.component.scss']
})
export class TravelTypesComponent implements OnInit {

  @Input() travel: Travel;
  listitems: ListItem[];

  toAdd = [];
  toDelete = [];

  constructor(private listService: CrudService<List>,
    private listItemService: CrudService<ListItem>,
    private travelTypeService: CrudService<TravelType>) {
    this.loadItems();
  }

  loadItems() {
    const searchList = new Search('selist');
    const searchListItem = new Search('selistitem');
    searchList.addSimpleFilter('slug', 'travel.types');
    this.listService.searchEntities(List, 'selist', searchList, new Page(1)).subscribe(result => {
      searchListItem.addSimpleFilter('lid', result[0].id);
      this.listItemService.searchEntities(ListItem, 'selistitem', searchListItem, new Page(100)).subscribe(items => {
        this.listitems = items;
      }, error => {
        console.log('error', error);
      });
    }, error => {
      console.log('error', error);
    });

  }

  addDelete(item: ListItem, event: any) {
    if (event) {
      if (!this.travel.types.map(listitem => listitem.id).includes(item.id)) {
        this.toAdd.push({ id: item.id, traveltype: item.itemvalue });
      }
      if (this.toDelete.map(listitem => listitem.id).includes(item.id)) {
        this.toDelete = this.toDelete.filter(listitem => listitem.id !== item.id);
      }
    } else {
      if (this.travel.types.map(listitem => listitem.id).includes(item.id)) {
        this.toDelete.push({ id: item.id, traveltype: item.itemvalue });
      }
      if (this.toAdd.map(listitem => listitem.id).includes(item.id)) {
        this.toAdd = this.toAdd.filter(listitem => listitem.id !== item.id);
      }
    }

  }

  isChecked(item: ListItem) {
	if (this.travel && this.travel.types && this.travel.types.length > 0) {
    	return this.travel.types.map(listitem => listitem.traveltype).includes(item.itemvalue);
	} return false;
  }

  ngOnInit() {
  }

  submit() {
    const calls = [];
    this.toAdd.forEach(item => calls.push(this.travelTypeService.createEntity(TravelType, 'traveltype', { ...item, travelid: this.travel.id })));
    return calls;
  }

}
