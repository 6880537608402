import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { MessageService, Page } from 'elbuild-ui-lib-core';
import { MeetravelUser, Travel, TravelService, UserService } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-user-travel',
	templateUrl: './user-travel.component.html',
	styleUrls: ['./user-travel.component.scss']
})
export class UserTravelComponent implements OnInit {

	@Input() user: MeetravelUser;

	@Input() isJoined: boolean;
	@Input() isAbandoned: boolean;

	loading: boolean = false;
	rows: Travel[] = [];
	page = new Page();
	tipostatopresenze: string = 'tutti';
	leftctr: number = 0;
	absencectr: number = 0;

	constructor(
		private travelService: TravelService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private userService: UserService,
		private router: Router,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef) {


	}



	ngOnInit() {
		let countleft = this.userService.getUserTravelleftCount(this.user.id);
		let countabs = this.userService.getUserTravelabsCount(this.user.id);
		forkJoin([countleft, countabs]).subscribe(results => {
			this.leftctr = results[0].ct;
			this.absencectr = results[1].ct;
			this.loading = false;
			this.loadItems();
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
			this.loadItems();
		});

	}


	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();
		console.log(this.tipostatopresenze);
		if (this.tipostatopresenze == 'tutti') {
			console.log('tutti');

			let datarequest: any;
			let countrequest: any;
			if (this.isJoined) {
				datarequest = this.userService.getJoinedTravels(this.user.id, this.page.start, this.page.stop);
				countrequest = this.userService.getJoinedTravelsCount(this.user.id);

			} else if (this.isAbandoned) {
				datarequest = this.userService.getAbandonedTravels(this.user.id, this.page.start, this.page.stop);
				countrequest = this.userService.getAbandonedTravelsCount(this.user.id);
			} else {
				datarequest = this.userService.getOrganizedTravels(this.user.id, this.page.start, this.page.stop);
				countrequest = this.userService.getOrganizedTravelsCount(this.user.id);
			}
			forkJoin([datarequest, countrequest]).subscribe(results => {
				this.rows = results[0] as Array<Travel>;
				this.page.setTotal((results[1] as any).ct);
				this.loading = false;
				console.log(this.rows);
			}, ({ error }) => {
				this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
				this.loading = false;
			});

		} else if (this.tipostatopresenze == 'presenze') {
			console.log('presenze');

			const datarequest = this.userService.getUserTravelleft(this.user.id, this.page.start, this.page.stop);
			const countrequest = this.userService.getUserTravelleftCount(this.user.id);
			forkJoin([datarequest, countrequest]).subscribe(results => {
				this.page.setTotal(results[1].ct);
				this.rows = results[0];

				this.loading = false;
			}, ({ error }) => {
				this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
				this.loading = false;
			});

		} else if (this.tipostatopresenze == 'assenze') {

			console.log('assenze');
			const datarequest = this.userService.getUserTravelabs(this.user.id, this.page.start, this.page.stop);
			const countrequest = this.userService.getUserTravelabsCount(this.user.id);
			forkJoin([datarequest, countrequest]).subscribe(results => {
				this.page.setTotal(results[1].ct);
				this.rows = results[0];

				this.loading = false;
			}, ({ error }) => {
				this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
				this.loading = false;
			});

		}


	}

	accept(travel: Travel, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-accept'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.travelService.accept(travel).subscribe(results => {
					this.loadItems();
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.travel'));
				});
			}
		});
	}

	decline(report: Travel, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-decline'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.travelService.decline(report).subscribe(results => {
					this.loadItems();
					this.messageService.sendSuccess(this.translateService.instant('labels.travel-declined'), this.translateService.instant('labels.travel-declined'));
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.report'));
				});
			}
		});
	}

	selectTipoStato(type: string) {
		if (type == 'presenze' && this.user.leftctr > 0) {
			this.tipostatopresenze = 'presenze';
			this.loadItems()
		} else if (type == 'assenze' && this.user.absencectr > 0) {
			this.tipostatopresenze = 'assenze';
			this.loadItems()
		}
	}



	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		console.log(this.page)
		this.loadItems();
	}


	rowClass = (row: any) => {
		return '';
	}

	get pageSize() {
		let count = 0;
		if (this.tipostatopresenze == 'tutti') {
			count = this.rows?.length;
		}
		if (this.tipostatopresenze == 'presenze') {
			count = this.leftctr;
		}
		if (this.tipostatopresenze == 'assenze') {
			count = this.absencectr;
		}
		return count;
	}




}
