import { Component, OnInit, OnDestroy } from '@angular/core';
import { Regione, Provincia } from 'meetravel-ui-lib';
import { CrudService, SharedDataService, MessageService, Search } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-province-edit',
	templateUrl: './province-edit.component.html',
	styleUrls: ['./province-edit.component.scss']
})
export class ProvinceEditComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	province: Provincia = new Provincia();
	regione: {
		search: Search,
		selected: Regione
	};


	get title(): string {
		return this.province?.id ? 'province-edit' : 'province-create';
	}


	constructor(
		private provinceService: CrudService<Provincia>,
		private sharedDataService: SharedDataService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal,
		private regioneService: CrudService<Regione>
	) {
		this.regione = {
			search: new Search('regioni'),
			selected: undefined
		};
	}

	ngOnInit() { }

	ngOnDestroy(): void {
		this.sharedDataService.addCommonData('route', undefined);
	}

	load(provinciaid: number): void {
		this.loading = true;
		this.provinceService.getEntity(Provincia, 'province', provinciaid).subscribe((provincia: Provincia) => {
			this.province = provincia;
			this.findItems();
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.province'));
			this.regione = undefined;
			this.loading = false;
		});
	}
	findItems() {
		this.regioneService.getEntity(Regione, 'regioni', this.province.rid).subscribe((res: Regione) => this.regione.selected = res);
	}

	dismiss(): void {
		this.activeModal.dismiss();
	}

	submit(): void {

		this.setSelectedFields();

		if (!this.province.id) {
			this.provinceService.createEntity(Provincia, 'province', this.province).subscribe((response: Provincia) => {
				this.province = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.province-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.province);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {
			this.provinceService.saveEntity(Provincia, 'province', this.province).subscribe((response: Provincia) => {
				this.province = response;
				this.messageService.sendSuccess(
					this.translateService.instant('labels.province-save-success'),
					this.translateService.instant('labels.success')
				);
				this.activeModal.close(this.province);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

	setSelectedFields(): void {
		if (this.regione.selected) {
			this.province.rid = this.regione.selected.id;
			this.province.regione = this.regione.selected.descrizione;
		}
	}

}

