import { Component, Input, OnInit } from '@angular/core';
import { Guest } from 'meetravel-ui-lib';

@Component({
	selector: 'app-guest-list',
	templateUrl: './guest-list.component.html',
	styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent implements OnInit {

	@Input() guestList: Guest[];
	rows: Guest[];
	@Input() isInvite: boolean = false;
	@Input() isRecived: boolean = false;
	@Input() isSent: boolean = false;

	constructor() {}

	ngOnInit() {

		this.rows = this.guestList;
	}

	onSearch(event: any) {
		this.rows = this.guestList.filter(item => {
			let match = false;
			if (event.genericterm && item.user) {
				match = item.user.fullname.toLowerCase().lastIndexOf(event.genericterm) >= 0;
			} else if (!event.genericterm) {
				match = true;
			}
			if (match)
				return item;
		});

		if (event.statuses.length > 0) {
			this.rows = this.rows.filter(item => event.statuses.includes(item.realstatus));
		}

		if (event.jointype) {
			this.rows = this.rows.filter(item => item.jointype === event.jointype);
		}

		if (event.compatibilitymin) {
			this.rows = this.rows.filter(item => item.compatibility >= event.compatibilitymin / 100);
		}

		if (event.compatibilitymax) {
			this.rows = this.rows.filter(item => item.compatibility <= event.compatibilitymax / 100);
		}

		if (event.invitedby) {
			this.rows = this.rows.filter(item => item.inviterid <= event.invitedby);
		}

	}



}
