<div>
	<app-guest-list-filters
		[isInvite]="isInvite"
		[isRecived]="isRecived"
		[isSent]="isSent"
		(onSearch)="onSearch($event)"></app-guest-list-filters>
</div>

<div *ngFor="let guest of rows">
	<app-guest-card
		[guest]="guest"
		[isInvite]="isInvite"
		[isRecived]="isRecived"
		[isSent]="isSent"></app-guest-card>
</div>
