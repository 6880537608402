import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CrudService, Page } from 'elbuild-ui-lib-core';
import { MeetravelUser, UserService } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-met-list',
	templateUrl: './met-list.component.html',
	styleUrls: ['./met-list.component.scss']
})
export class MetListComponent implements OnInit {

	@Input() uid: number;
	loading: boolean = false;
	rows: any[] = [];
	page: Page = new Page(20);

	constructor(private crudService: CrudService<MeetravelUser>,
		private userService: UserService,
		private cdr: ChangeDetectorRef) {

	}

	ngOnInit() {
		this.page.setPageNumber(0);
		this.loadItems();
	}

	ngAfterViewInit() {

	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();
		const data = this.userService.getMet(this.uid, this.page.start, this.page.stop);
		const count = this.userService.getMetCounter(this.uid);
		forkJoin([data, count]).subscribe(data => {
			this.rows = data[0];
			this.page.totalElements = data[1].ct;
			this.loading = false;
			this.cdr.detectChanges();
		});
	}

	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}


}
