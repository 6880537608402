<div class="row">
	<div class="col-sm-12">
		<app-banner-list-filters
			#listfilters
			[searchparams]="advancedSearch"
			(searchEvent)="search($event)">
		</app-banner-list-filters>
	</div>
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">
			<a
				class="float-right"
				(click)="edit(null)">
				<i
					container="body"
					ngbTooltip="{{'labels.create' | translate}}"
					class="feather icon-plus"></i>
			</a>

			<div class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-banner' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="title"
						[flexGrow]="1"
						[name]="'labels.title' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date:"dd/MM/yyyy"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="moddate"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.moddate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date: "dd/MM/yyyy"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="cta"
						[flexGrow]="1"
						[name]="'labels.cta' | translate"
						[sortable]="false"
						[resizeable]="false">
						<ng-template
							let-row="row"
							ngx-datatable-cell-template>
							<a [href]="row.cta">
								<i
									container="body"
									ngbTooltip="{{'labels.view-link' | translate}}"
									class="feather icon-external-link"></i>
							</a>

						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="fromdate"
						[flexGrow]="1"
						[name]="'labels.startdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="todate"
						[flexGrow]="1"
						[name]="'labels.enddate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date : 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="priority"
						[flexGrow]="1"
						[name]="'labels.priority' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="showtopremium"
						[flexGrow]="1.5"
						[name]="'labels.showtopremium' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{(value ? 'labels.yes' : 'labels.no') | translate}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="media"
						[flexGrow]="1"
						[name]="'labels.media' | translate"
						[resizeable]="false">
						<ng-template
							let-row="row"
							let-value="value"
							ngx-datatable-cell-template>
							<a
								class="link"
								*ngIf="!row.media"
								(click)="edit(row)">
								<i
									container="body"
									ngbTooltip="{{'labels.edit-media' | translate}}"
									class="feather icon-image"></i>
							</a>
							<i
								*ngIf="row.media"
								container="body"
								class="feather icon-check"></i>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a (click)="edit(row)">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
								<a (click)="delete(row, $event)">
									<i
										container="body"
										ngbTooltip="{{'labels.delete' | translate}}"
										class="feather icon-trash"></i>
								</a>
								<a>
									<i
										*ngIf="row.softblock"
										container="body"
										ngbTooltip="{{'labels.softblock' | translate}}"
										class="feather text-danger icon-lock"></i>
								</a>

							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>

</div>
