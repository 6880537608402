import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { MeetravelUser, Travel, UserService } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-user-travel-partecipation-list',
	templateUrl: './user-travel-partecipation-list.component.html',
	styleUrls: ['./user-travel-partecipation-list.component.scss']
})
export class UserTravelPartecipationListComponent implements OnInit {
	@Input() user: MeetravelUser;

	loading: boolean = false;
	rows: Travel[] = [];
	search: Search = new Search('travel join travelguest on travel.id = travelguest.travelid');

	constructor(private messageService: MessageService,
		private crudService: CrudService<Travel>,
		private translateService: TranslateService,
		private userService: UserService,
		private router: Router,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		if (this.user)
			this.search.addSimpleFilter('travelguest.userid', this.user.id);
		this.search.addInFilter("travelguest.status", ["joined", "follower", "invited"]);
		this.search.addInFilter("status", ["submitted", "active"]);
		this.loadItems();
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		const datarequest = this.crudService.searchEntities(Travel, 'travel', this.search, this.search.page);
		const countrequest = this.crudService.countEntities(Travel, 'travel', this.search);
		forkJoin([datarequest, countrequest]).subscribe(data => {
			this.rows = data[0];
			this.search.page.setTotal(data[1].ct);
			this.loading = false;
			this.cdr.detectChanges();
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {

		this.search.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.search.setSortField(sort.prop);
		this.search.setSortDirection(sort.dir);
		this.loadItems();
	}

	onActivate(event: any) {
		if (true /*this.aclService.hasPermission('centri.detail')*/) {
			if (event.type === 'dblclick') {
				// this.router.navigate([`/user/view/${event.row.id}`], { queryParamsHandling: 'merge' });
			}
		}
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.search) {
			return this.search.page;
		} else {
			return undefined;
		}
	}

}
