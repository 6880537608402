import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'elbuild-ui-lib-core';
import { Travel, travelAdminStatus, TravelService } from 'meetravel-ui-lib';

@Component({
	selector: 'app-status-admin-modal',
	templateUrl: './status-admin-modal.component.html',
	styleUrls: ['./status-admin-modal.component.scss']
})
export class StatusAdminModalComponent implements OnInit {

	loading: boolean = false;
	travel: Travel

	adminStatus: travelAdminStatus;

	EnumAdminStatus = travelAdminStatus;


	get title(): string {
		return 'status-edit';
	}


	constructor(
		private activeModal: NgbActiveModal,
		private travelService: TravelService,
		private messageService: MessageService,
		private translateService: TranslateService,
	) {

	}

	ngOnInit() {}

	dismiss(): void {
		this.activeModal.close(this.travel)
	}

	submit(): void {
		this.travelService.editTravelStatus(this.adminStatus, this.travel).subscribe(res => {
			this.activeModal.close(res)
			this.messageService.sendSuccess(this.translateService.instant('labels.save-success'), this.translateService.instant('labels.travel'));
		}, error => {
			this.messageService.sendError(error);
		});
	}

}