<form
	#editForm="ngForm"
	[formReset]="editForm"
	(ngSubmit)="editForm.valid && submit()">
	<div class="modal-header">
		<h4
			class="modal-title"
			*ngIf="!loading">
			{{'labels.' + title | translate}}
		</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="dismiss()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div
		class="modal-body"
		ngbAutofocus>
		<div
			class="row"
			*ngIf="loading">
			<div class="col text-center">
				<app-loader [material]="false"></app-loader>
			</div>
		</div>

		<div
			class="row"
			*ngIf="!loading && country">
			<div class="col-sm-12">
				<div class="row">

					<!-- sigla -->
					<div class="form-group col-md-4">
						<label>{{ 'labels.sigla' | translate }}*</label>
						<input
							[(ngModel)]="country.sigla"
							#sigla="ngModel"
							name="sigla"
							required
							type="text"
							class="form-control"
							placeholder="{{ 'labels.sigla' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && sigla.invalid}"
							maxlength="128">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && sigla.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>

					<!-- name -->
					<div class="form-group col-md-4">
						<label for="title">{{ 'labels.name' | translate }}*</label>
						<input
							[(ngModel)]="country.name"
							#name="ngModel"
							name="name"
							required
							type="text"
							class="form-control"
							placeholder="{{ 'labels.name' | translate }}"
							[ngClass]="{'is-invalid': editForm.submitted && name.invalid}"
							maxlength="128">
						<div
							class="invalid-feedback"
							*ngIf="editForm.submitted && name.invalid">
							{{'errors.required' | translate}}
						</div>
					</div>

				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">

		<button
			type="reset"
			class="btn btn-secondary">
			{{'labels.reset' | translate}}
		</button>
		<button
			type="button"
			class="btn btn-secondary"
			(click)="dismiss()">
			{{'labels.cancel' | translate}}
		</button>
		<button
			type="submit"
			class="btn btn-primary">
			{{'labels.save' | translate}}
		</button>
	</div>
</form>
