import { CoreComponentsModule } from './core-components/core-components.module';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataModule } from './data/data.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
// import { PipesModule } from 'elbuild-ui-lib-core';


const LOGIN_CONF = {
	useHash: true,
	enableTracing: true,
};

const NB_CORE_PROVIDERS = [
	...DataModule.forRoot().providers,
];

@NgModule({
	imports: [
		CommonModule,
		//   PipesModule
	],
	exports: [
		CoreComponentsModule,
	],
	declarations: [],
})
export class CoreModule {

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}

}


