import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { AuthService, SharedDataService } from 'elbuild-ui-lib-core';
@Injectable()
export class AclService {

	constructor(private authService: AuthService, private sharedDataService: SharedDataService) {

	}


	getDepots() {
		const token = this.authService.getToken();
		const dToken = jwtDecode(token);
		return dToken.depo;
	}
	hasPermission(types: string[], type: string): boolean {
		if (!types || !types.length) return true;
		if (!type) return false;
		return types.indexOf(type) >= 0;

	}


}
