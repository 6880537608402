<span
	class="badge badge"
	[class]="getBadgeClass()">
	{{'types.guest-status.' + status | translate}}
</span>

<span
	*ngIf="status != GuestStatus.INVITED"
	class="badge badge chatBox"
	(click)="chatStatus()"
	[class]="getChatClass()">
	{{'labels.' + ((status == GuestStatus.JOINED || status == GuestStatus.PARTECIPANT) ? 'removefromchat' : 'addtochat') | translate}}
</span>
