import { Component, OnInit, NgZone, AfterViewInit, Input, OnChanges, SimpleChanges, OnDestroy, AfterViewChecked, AfterContentChecked } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_it from '@amcharts/amcharts4/lang/it_IT';
import { GenericChart, ChartItem } from 'meetravel-ui-lib';

am4core.useTheme(am4themes_animated);


@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})

export class LineChartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {


    chart: am4charts.XYChart;
    @Input() chartStruct: GenericChart;
    @Input() divid;
    loading: boolean;

    categoryAxis: any;
    valueAxis: any;
    viewInited = false;
    waitingToDisplay = false;

    constructor(private zone: NgZone) { }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        if (this.chart)
            this.chart.dispose();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.chartStruct.line && this.chartStruct.line.length > 0) {
            if (this.viewInited)
                this.createChart();
            else
                this.waitingToDisplay = true;
        }
    }

    ngAfterViewInit(): void {
        this.viewInited = true;
        if (this.waitingToDisplay) {
            this.createChart();
            this.waitingToDisplay = false;
        }
    }




    createChart() {
        if (this.chart) this.chart.dispose();
        this.loading = true;
        console.log(this.divid);
        this.chart = am4core.create(this.divid, am4charts.XYChart);
        this.chart.language.locale = am4lang_it;
        this.chart.dateFormatter.language = new am4core.Language();
        this.chart.dateFormatter.language.locale = am4lang_it;
        this.chart.data = this.chartStruct.line;

        this.chart.dateFormatter.inputDateFormat = 'dd/MM/yyyy';

        // Create title

        const title = this.chart.titles.create();
        title.text = this.chartStruct.chartname;
        title.fontSize = 20;
        title.marginBottom = 20;

        // Create axes
        const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        const series = this.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.dateX = 'label';
        series.tooltipText = '{value}';
        series.strokeWidth = 2;
        series.minBulletDistance = 15;

        // Drop-shaped tooltips
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = 'vertical';
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = 'middle';
        series.tooltip.label.textValign = 'middle';

        // Make bullets grow on hover
        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color('#fff');

        const bullethover = bullet.states.create('hover');
        bullethover.properties.scale = 1.3;

        // Make a panning cursor
        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.behavior = 'panXY';
        this.chart.cursor.xAxis = dateAxis;
        this.chart.cursor.snapToSeries = series;

        // Create vertical scrollbar and place it before the value axis
        this.chart.scrollbarY = new am4core.Scrollbar();
        this.chart.scrollbarY.parent = this.chart.leftAxesContainer;
        this.chart.scrollbarY.toBack();

        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 50;
        const startDate = this.getDate(this.chartStruct.line[0].label);
        const endDate = this.getDate(this.chartStruct.line[this.chartStruct.line.length - 1].label);
        dateAxis.min = new Date(startDate.year, startDate.month, startDate.date).getTime();
        dateAxis.max = new Date(endDate.year, endDate.month, endDate.date).getTime();

        dateAxis.keepSelection = true;

        this.chart.events.on('ready', () => {
            this.loading = false;
        });

    }

    getDate(label: string) {
        const year = +label.split('/')[2];
        const month = (+label.split('/')[1]) - 1;
        const date = +label.split('/')[0];
        return {
            year: year,
            month: month,
            date: date
        };
    }

    getMax(): number {
        let max = 0;
        for (const d of this.chart.data) {
            max = (d.value > max) ? d.value : max;
        }
        return max + 0.5;
    }


    parseDataStruct(datas: ChartItem[]) {
        const lineStruct = [];
        datas.forEach(data => {

            const chartItem = { value: 0 };
            datas.forEach(element => {
                chartItem.value += element.value;
            });

            const labeldate = this.getDate(data.label);
            chartItem['label'] = new Date(labeldate.year, labeldate.month, labeldate.date);

            lineStruct.push(chartItem);
        });
        return lineStruct;
    }
    // Add data
    // Create axes
}
