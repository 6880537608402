<div class="login-register" style="background: grey">

  <div class="login-box card">
    <div class="card-body">
      <div class="login-logo">
        <a class="navbar-brand" href="/">
          <b>
            <img alt="homepage" class="dark-logo" src="assets/images/logo-icon.png">
          </b>
          <span><img alt="homepage" class="dark-logo" src="assets/images/logo-text.png"></span>
        </a>
      </div>
      <form class="form-horizontal form-material" #pwdForm="ngForm" (ngSubmit)="pwdForm.valid && changepwd()">
        <h3 class="box-title m-b-20">Change password</h3>
        <div class="form-group ">
          <div class="col-xs-12">
            <input name="password" class="form-control" type="password" required [(ngModel)]="password"
              placeholder="Password">
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <input name="confirmpassword" class="form-control" type="password" required [(ngModel)]="confirmpassword"
            placeholder="Password confirm">
          </div>
        </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button type="submit" class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
              [disabled]="pwdForm.invalid || password != confirmpassword">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
