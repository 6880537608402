import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { Feedback, MeetravelUser } from 'meetravel-ui-lib';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';

import { EditFeedbackModalComponent } from '../edit-feedback-modal/edit-feedback-modal.component';

@Component({
	selector: 'app-feedback-list',
	templateUrl: './feedback-list.component.html',
	styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {

	@Input() travelid: number;
	@Input() sent: boolean = false;
	@Input() received: boolean = false;
	@Input() uid: number;
	loading: boolean = false;
	rows: Feedback[] = [];
	advancedSearch: Search = new Search('feedback join travel on feedback.travelid = travel.id', 'id', 'feedback');

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;

	subcription: Subscription;

	constructor(private crudService: CrudService<Feedback>,
		private userService: CrudService<MeetravelUser>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbModal,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef) {

	}

	ngOnInit() {
		if (this.travelid) {
			this.advancedSearch.addSimpleFilter('travelid', this.travelid);
		} else if (this.sent) {
			this.advancedSearch.addSimpleFilter('receiverid', this.uid);
		} else if (this.received) {
			this.advancedSearch.addSimpleFilter('senderid', this.uid);
		}
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams();

		const requests: Observable<any>[] = [urlparamsreq];

		this.subcription = combineLatest(requests).subscribe(data => {

			this.search(data[0]);

		});
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.crudService.searchEntities(Feedback, 'feedback', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(Feedback, 'feedback', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {

		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onActivate(event: any) {
		if (true /*this.aclService.hasPermission('centri.detail')*/) {
			if (event.type === 'dblclick') {
				// this.router.navigate([`/user/view/${event.row.id}`], { queryParamsHandling: 'merge' });
			}
		}
	}


	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;

		}
		this.loadItems();
	}


	applySearch(event: any) {
		this.advancedSearch = event;
		this.loadItems();
	}

	showFeedbackNote(item: Feedback) {
		this.dialogService.openConfirmModal({
			message: item.note,
			hasIcon: true,
			type: 'info'
		});
	}

	edit(item: Feedback, event: any) {
		const modal = this.modalService.open(EditFeedbackModalComponent, { centered: true, size: 'lg' });
		if (item) {
			modal.componentInstance.feedback = item;
		}
		modal.result.then((item: Feedback) => {
			if (!item.note) {
				item.approved = true;
			}
			this.crudService.saveEntity(Feedback, 'feedback', item).subscribe(res => {
				const messages = this.translateService.instant(['labels.feedback-save-success', 'labels.success']);
				this.messageService.sendSuccess(messages['labels.feedback-save-success'], messages['labels.success']);
				this.loadItems()
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}).catch(() => {});
	}

	approve(feedback: Feedback, event: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-feedback-approve'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				feedback.approved = true;
				this.crudService.saveEntity(Feedback, 'feedback', feedback).subscribe(results => {
					const messages = this.translateService.instant(['labels.feedback-approved-success', 'labels.success']);
					this.messageService.sendSuccess(messages['labels.feedback-approved-success'], messages['labels.success']);
					this.loadItems();
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.travel'));
				});
			}
		});
	}


	delete(row: Feedback, event: any) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-feedback-delete'),
			hasIcon: true,
			type: 'warning',
		}).then((result) => {
			if (result) {
				this.crudService.deleteEntity(Feedback, 'feedback', row).subscribe(() => {
					this.translateService.get(['labels.feedback-deleted', 'labels.feedback']).subscribe((messages) => {
						this.messageService.sendSuccess(messages['labels.feedback-deleted'], messages['labels.feedback']);
						this.updateUser(row.sender);
						this.loadItems();
					});
				});
			}
		});
	}

	updateUser(user: MeetravelUser) {
		user.leftctr = user.leftctr ? user.leftctr - 1 : user.leftctr;
		user.absencectr += 1;
		this.userService.saveEntity(MeetravelUser, 'user', user).subscribe(res => {
			this.messageService.sendSuccess(
				this.translateService.instant('labels.user-save-success'),
				this.translateService.instant('labels.success')
			);
		}, ({ error }) => {
			this.messageService.sendError(
				error.msg ? error.msg : error,
				this.translateService.instant('labels.event-save-error')
			);
		});
	}

}
