<div class="card">
	<div class="card-body">
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-6">
				<app-range-picker-filters (onRangeChange)="onRangeChange($event)"></app-range-picker-filters>
			</div>
		</div>
	</div>
</div>
<app-loading
	[full]="false"
	*ngIf="loading"></app-loading>
<div *ngIf="!loading">
	<div class="row">
		<div class="col-md-3">
			<div class="card">
				<div class="card-body">
					<app-metric 
						[metric]="data?.totfeedback">
					</app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<div (click)="travelModal()" class="card">
				<div class="card-body">
					<app-metric
						[metric]="data?.periodfeedback">
					</app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.periodabsence"></app-metric>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<div class="card">
				<div class="card-body">
					<app-metric [metric]="data?.periodabsenced"></app-metric>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.mgmtratingpiechart"
						[id]="'mgmtratingpiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.travelfeedbackpiechart"
						[id]="'travelfeedbackpiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<app-pie-chart
						[chartStruct]="data?.travellingratingpiechart"
						[id]="'travellingratingpiechart'"></app-pie-chart>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<app-line-chart
						[chartStruct]="data?.feedbackchart"
						divid="feedbackchart"></app-line-chart>
				</div>
			</div>
		</div>
	</div>
</div>
