import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Purchase } from 'meetravel-ui-lib';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-purchase-modal',
	templateUrl: './purchase-modal.component.html',
	styleUrls: ['./purchase-modal.component.scss']
})
export class PurchaseModalComponent implements OnInit {

	item: Purchase;

	constructor(private activeModal: NgbActiveModal,
		private clipboard: Clipboard) {}

	ngOnInit() {
	}

	dismiss() {
		this.activeModal.close();
	}

	copy() {
		if (this.item && this.item.receipt) {
			this.clipboard.copy(this.item.receipt);
		}
	}

}
