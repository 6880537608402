import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'elbuild-ui-componentslib';
import { CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { Domandetestcreator } from 'meetravel-ui-lib';
import { forkJoin } from 'rxjs';

import { DomandetestModalAddEditComponent } from '../components/domandetest-modal-add-edit/domandetest-modal-add-edit.component';

@Component({
	selector: 'app-domandetestcreatorlist',
	templateUrl: './domandetestcreatorlist.component.html',
	styleUrls: ['./domandetestcreatorlist.component.scss']
})
export class DomandetestcreatorlistComponent implements OnInit {

	loading: boolean = false;
	advancedSearch: Search = new Search('domandetestcreator');
	showAdvancedSearch: boolean = true;
	rows: Domandetestcreator[] = [];

	constructor(
		private crudService: CrudService<Domandetestcreator>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private modalService: NgbModal,
		private router: Router,
		private cdr: ChangeDetectorRef,) {}

	ngOnInit() {
		this.loadItems();
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.loadItems();
	}

	onActivate(event: any) {
		if (event.type === 'dblclick') {
			this.router.navigateByUrl(`/testcreator/rispostetestcreator/${event.row.id}`);
		}
	}


	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();
		const datarequest = this.crudService.searchEntities(Domandetestcreator, 'domandetestcreator', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.crudService.countEntities(Domandetestcreator, 'domandetestcreator', this.advancedSearch);
		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.rows = results[0];
			this.advancedSearch.page.setTotal(results[1].ct);
			this.loading = false;
			this.cdr.detectChanges();
		}
		);
	}

	edit(item?: Domandetestcreator) {
		const modal = this.modalService.open(DomandetestModalAddEditComponent, { size: 'lg', backdrop: 'static', centered: true, keyboard: false });
		if (item)
			modal.componentInstance.item = item;
		modal.result.then((result: Domandetestcreator) => {
			console.log(result);
			if (result) {
				const observable = result?.id ? this.crudService.saveEntity(Domandetestcreator, 'domandetestcreator', result) : this.crudService.createEntity(Domandetestcreator, 'domandetestcreator', result);
				observable.subscribe((result: Domandetestcreator) => {
					this.loadItems();
					this.messageService.sendSuccess(this.translateService.instant('labels.domandetestcreator-saved'),
						this.translateService.instant('labels.success'));
				});
			} else {
				this.messageService.sendError(this.translateService.instant('domandetestcreator-not-saved'));
			}
		});
	}

	delete(row: Domandetestcreator, event?: any) {
		event.target.closest('datatable-body-cell').blur();
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-domandetestcreator-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.crudService.deleteEntity(Domandetestcreator, 'domandetestcreator', row).subscribe(() => {
					this.translateService.get(['labels.domandetestcreator-deleted', 'labels.domandetestcreator']).subscribe(messages => {
						this.messageService.sendSuccess(this.translateService.instant('labels.domandetestcreator-deleted'),
							this.translateService.instant('labels.success'));
						this.loadItems();
					});
				});
			}
		});
	}


}

