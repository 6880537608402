import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'elbuild-ui-lib-core';
import { Product } from 'meetravel-ui-lib';

@Component({
	selector: 'app-product-modal-edit',
	templateUrl: './product-modal-edit.component.html',
	styleUrls: ['./product-modal-edit.component.scss']
})
export class ProductModalEditComponent implements OnInit {
	item: Product;
	productClass = Product;
	constructor(private activeModal: NgbActiveModal,
		private messageService: MessageService,
		private translateService: TranslateService) {}

	ngOnInit() {
		if (!this.item) {
			this.item = new Product();
		}
	}

	submit() {
		this.activeModal.close(this.item);
	}

	dismiss() {
		this.activeModal.dismiss();
	}

	close() {
		this.activeModal.close();
	}

	update(event: any): void {
		if (event && event.success && event.response) {
			this.item.image = event.response.opaqueid;
			console.log(event.response);
			console.log(this.item.image);

		} else {
			this.messageService.sendError(
				this.translateService.instant('labels.event-upload-error')
			);
		}
	}
}
