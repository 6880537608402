import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Search, ParamsService, SortDirection } from 'elbuild-ui-lib-core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { MeetravelUser, UserReportStatus, UserType, UserStatus } from 'meetravel-ui-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
  selector: 'app-alert-list-filters',
  templateUrl: './alert-list-filters.component.html',
  styleUrls: ['./alert-list-filters.component.scss']
})
export class AlertListFiltersComponent extends ListFiltersComponent implements OnInit {

  keyword: string;
  active: boolean = true;

  searchUsers: Search;
  userClass: ClassType<MeetravelUser> = MeetravelUser;
  senderSelected: MeetravelUser;

  statuses: string[] = Object.keys(UserReportStatus).map(k => UserReportStatus[k]);

  @Output() searchEvent = new EventEmitter<Search>();


  constructor(router: Router,
    _route: ActivatedRoute,
    paramsService: ParamsService) {
    super(router, _route, paramsService);
    this.searchUsers = new Search('user');
    this.searchUsers.addSimpleFilter('utype', UserType.USER);
    this.searchUsers.addSimpleFilter('status', UserStatus.ACTIVE);
    this.searchUsers.setSortField('id');
    this.searchUsers.setSortDirection(SortDirection.DESCENDING);
  }

  ngOnInit() {
    this.resetSearch();
  }

  onSelectedSender(event: MeetravelUser) {
    this.searchparams.addSimpleFilter('sender', event.id);
  }

  onDeleteSender() {
    this.searchparams.removeSimpleFilter('sender');
  }

  resetSearch() {
    this.keyword = undefined;
    this.active = true;
    this.searchparams.reset();
    this.senderSelected = undefined;
    this.searchparams.rangeTmp = {
      insdate: {
        start: undefined,
        stop: undefined
      }
    };
    this.searchparams.setSortField('id');
    this.searchparams.setSortDirection(SortDirection.DESCENDING);
    this.search();
  }

  search(keeppage?: boolean) {
    this.searchEvent.emit(this.searchparams);
  }

 

}
