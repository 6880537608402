import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassType } from 'class-transformer/ClassTransformer';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';
import { download, MessageService, ParamsService, Search, SortDirection } from 'elbuild-ui-lib-core';
import { CommonService, MeetravelUser, UserReportStatus, UserType } from 'meetravel-ui-lib';
import * as moment from 'moment';

@Component({
	selector: 'app-report-list-filters',
	templateUrl: './report-list-filters.component.html',
	styleUrls: ['./report-list-filters.component.scss']
})
export class ReportListFiltersComponent extends ListFiltersComponent implements OnInit {

	keyword: string;
	active: boolean = true;

	searchUsers: Search;
	userClass: ClassType<MeetravelUser> = MeetravelUser;
	reporterSelected: MeetravelUser;
	reportedSelected: MeetravelUser;
	loading: boolean = false;
	blockuser: boolean = false;
	blockadmin: boolean = false;
	temporaryblock: boolean = false;

	statuses: string[] = Object.keys(UserReportStatus).map(k => UserReportStatus[k]);
	@Output() searchEvent = new EventEmitter<Search>();


	constructor(router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService,
		private messageService: MessageService,
		private commonService: CommonService) {
		super(router, _route, paramsService);
		this.searchUsers = new Search('user');
		this.searchUsers.addSimpleFilter('utype', UserType.USER);
	}

	ngOnInit() {
		this.resetSearch();
	}

	onSelectedReporter(event: MeetravelUser) {
		this.searchparams.addSimpleFilter('reporterid', event.id);
	}

	onDeleteReporter() {
		this.searchparams.removeSimpleFilter('reporterid');
	}

	onSelectedReported(event: MeetravelUser) {
		this.searchparams.addSimpleFilter('userid', event.id);
	}

	onDeleteReported() {
		this.searchparams.removeSimpleFilter('userid');
	}

	resetSearch() {
		this.keyword = undefined;
		this.active = true;
		this.searchparams.reset();
		this.searchparams.simpleTmp['status'] = undefined;
		this.reporterSelected = undefined;
		this.reportedSelected = undefined;
		this.searchparams.setSortDirection(SortDirection.DESCENDING);
		this.searchparams.setSortField('id');
		this.searchparams.rangeTmp = {
			insdate: {
				start: undefined,
				stop: undefined
			}
		};
		this.search();
	}

	search(keeppage?: boolean) {
		this.searchEvent.emit(this.searchparams);
	}

	export() {
		this.loading = true;
		this.commonService.export(this.searchparams).subscribe(res => {
			this.loading = false;
			const filename = this.searchparams.table + '_' + moment().format('DDMMyyyy') + '.xlsx';
			download(res, filename)
		}, error => {
			this.messageService.sendError(error);
			this.loading = false;
		});
	}
}

