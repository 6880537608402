<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">

	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group d-flex flex-row">
					<input
						type="text"
						class="form-control d-inline"
						name="keyword"
						[(ngModel)]="searchparams.genericSearchTerm">
					<button
						type="button"
						class="btn btn-link ml-2"
						(click)="toggleAdvanced()">
						<i class="feather icon-filter"></i>

					</button>
				</div>
			</div>

		</div>
		<div [ngbCollapse]="!showAdvancedSearch">
			<div class="row">
				<!-- fname -->
				<div class="col-md-4">
					<div class="form-group">
						<label>{{'labels.fname' | translate}}</label>
						<input
							type="text"
							class="form-control"
							name="name"
							[(ngModel)]="searchparams.likeTmp['fname']">
					</div>
				</div>
				<!-- lname -->
				<div class="col-md-4">
					<div class="form-group">
						<label>{{'labels.lname' | translate}}</label>
						<input
							type="text"
							class="form-control"
							name="lname"
							[(ngModel)]="searchparams.likeTmp['lname']">
					</div>
				</div>
				<!-- email -->
				<div class="col-md-4">
					<div class="form-group">
						<label>{{'labels.email' | translate}}</label>
						<input
							type="text"
							class="form-control"
							name="email"
							[(ngModel)]="searchparams.likeTmp['email']">
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-3">
					<label>
						{{'labels.subscribed' | translate}} {{'labels.from' | translate}}
					</label>
					<app-datepicker
						name="subscribedfrom"
						[(ngModel)]="subscribed.from">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>{{'labels.to' | translate | titlecase}}</label>
					<app-datepicker
						name="subscribedto"
						[disabled]="!subscribed.from"
						[min]="subscribed.from"
						[(ngModel)]="subscribed.to">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>
						In viaggio {{'labels.from' | translate}}
					</label>
					<app-datepicker
						name="travelfrom"
						[(ngModel)]="joined.from">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>{{'labels.to' | translate | titlecase}}</label>
					<app-datepicker
						name="travelto"
						[disabled]="!joined.from"
						[min]="joined.from"
						[(ngModel)]="joined.to">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>Nati da</label>
					<app-datepicker
						name="birthfrom"
						[(ngModel)]="birth.from">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>{{'labels.to' | translate | titlecase}}</label>
					<app-datepicker
						name="birthto"
						[disabled]="!birth.from"
						[min]="birth.from"
						[(ngModel)]="birth.to">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>Ultimo accesso da</label>
					<app-datepicker
						name="lastloginfrom"
						[(ngModel)]="lastlogin.from">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>{{'labels.to' | translate | titlecase}}</label>
					<app-datepicker
						name="lastloginto"
						[disabled]="!lastlogin.from"
						[min]="lastlogin.from"
						[(ngModel)]="lastlogin.to">
					</app-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<label>Stile di viaggio</label>
					<ng-multiselect-dropdown
						name="statuses"
						[placeholder]="'labels.select' | translate"
						[data]="travelstyleList"
						[(ngModel)]="selectedTravelstyle"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>

				<div class="col-md-4">
					<label>Interessi</label>
					<ng-multiselect-dropdown
						name="statuses"
						[placeholder]="'labels.select' | translate"
						[data]="interestList"
						[(ngModel)]="selectedInterest"
						[settings]="dropdownSettings">
					</ng-multiselect-dropdown>
				</div>

				<div
					class="col-md-4"
					*ngIf="provinceList">
					<div class="form-group">
						<label>{{'labels.provincia' | translate}}</label>
						<select
							[(ngModel)]="selectedProvince"
							#province="ngModel"
							name="province"
							required
							class="form-control"
							id="province"
							[multiple]="false"
							[disabled]="false">
							<option [defaultSelected]="true"></option>
							<option
								*ngFor="let r of provinceList"
								[value]="r.descrizione">
								{{r.descrizione}}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="onlyactive"
								type="checkbox"
								name="onlyactive"
								[(ngModel)]="onlyactive"
								checked
								(click)="searchparams.simpleTmp['status'] = (($event.target.checked) ? 'active' : '')">
							<label
								for="onlyactive"
								class="ml-1">
								{{'labels.only-active' | translate}}
							</label>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="onlybanned"
								type="checkbox"
								name="onlybanned"
								[(ngModel)]="onlybanned"
								checked
								(click)="searchparams.simpleTmp['status'] = (($event.target.checked) ? 'banned' : '')">
							<label
								for="onlybanned"
								class="ml-1">
								{{'labels.only-banned' | translate}}
							</label>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="onlysoftblocked"
								type="checkbox"
								name="onlysoftblocked"
								[(ngModel)]="onlysoftblocked"
								(click)="searchparams.simpleTmp['softblock'] = (($event.target.checked) ? 1 : '')">
							<label
								for="onlysoftblocked"
								class="ml-1">
								{{'labels.only-softblocked' | translate}}
							</label>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="onlycertified"
								type="checkbox"
								name="onlycertified"
								[(ngModel)]="onlycertified"
								(click)="searchparams.simpleTmp['certifiedhost'] = (($event.target.checked) ? 1 : '')">
							<label
								for="onlycertified"
								class="ml-1">
								{{'labels.only-certified' | translate}}
							</label>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="onlymedia"
								type="checkbox"
								name="onlymedia"
								[(ngModel)]="onlymedia"
								(click)="searchparams.notEqualTmp['img'] = (($event.target.checked) ? null : '')">
							<label
								for="onlymedia"
								class="ml-1">
								Solo utenti con foto caricate
							</label>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="premium"
								type="checkbox"
								name="premium"
								[(ngModel)]="onlypremium">
							<label
								for="premium"
								class="ml-1">
								Solo utenti premium
							</label>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div>
						<div class="form-check">
							<label class="fake-label"></label>
							<input
								id="testcreator"
								type="checkbox"
								name="testcreator"
								[(ngModel)]="testcreator"
								(click)="searchparams.simpleTmp['testcreator'] = (($event.target.checked) ? 1 : '')">
							<label
								for="testcreator"
								class="ml-1">
								{{'labels.tester-only' | translate}}
							</label>
						</div>
					</div>
				</div>
			</div>

		</div>

		<br>

		<div class="row mt-1">

			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
				<a
					*ngIf="!isStaff"
					class="btn btn-success float-right"
					(click)="loading ? undefined : export()">
					<i
						*ngIf='loading'
						class="fas fa-spinner fa-spin"></i>
					{{'labels.export-xlsx' | translate}}
				</a>

				<a
					*ngIf="!isStaff"
					class="btn btn-primary float-right mr-4"
					(click)="loading ? undefined : openSendPushModal()">
					<i
						*ngIf='loading'
						class="fas fa-spinner fa-spin"></i>
					Invia Notifiche Cluster
				</a>

				<a
					*ngIf="false"
					[routerLink]="'new'"
					class="btn btn-primary float-right">
					{{'labels.new-user' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
