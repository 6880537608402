import { Component, OnInit } from '@angular/core';
import { Destination, DestinationType } from 'meetravel-ui-lib';
import { CrudService, enumToArray, MessageService, Search } from 'elbuild-ui-lib-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-destination-new-edit',
	templateUrl: './destination-new-edit.component.html',
	styleUrls: ['./destination-new-edit.component.scss']
})
export class DestinationNewEditComponent implements OnInit {

	destination: Destination;
	loading: boolean;
	destinationClass = Destination;
	destinationType: any[];
	DestinationType = DestinationType;

	searchDestination: Search = new Search('destination')
	destinationSelected: Destination = new Destination();

	constructor(
		private crudService: CrudService<Destination>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private modalService: NgbActiveModal
	) {
		this.destination = new Destination();
		this.destinationType = enumToArray(DestinationType);
	}

	ngOnInit() {
	}

	dismiss() {
		this.modalService.dismiss();
	}

	close() {
		this.modalService.close();
	}

	load(id: number) {
		this.crudService.getEntity(Destination, 'destination', id).subscribe(destination => {
			this.destination = destination;
			if (this.destination.id) {
				this.searchDestination.addNotEqualFilter('id', this.destination.id)
			}
			this.loadParent(this.destination.parentarea)
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.destination'));
			this.destination = undefined;
			this.loading = false;
		});
	}

	loadParent(id: number) {
		this.crudService.getEntity(Destination, 'destination', id).subscribe(destination => {
			this.destinationSelected = destination;
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.destination'));
			this.destinationSelected.id = undefined;
			this.loading = false;
		});
	}

	update(event: any): void {

		if (event && event.success && event.response) {
			this.destination.stockimg = event.response.stockimg;

		} else {
			this.messageService.sendError(
				this.translateService.instant('labels.event-upload-error')
			);
		}
	}

	submit() {
		if (this.searchDestination) {
			this.destination.parentarea = this.destinationSelected.id;
		}

		if (this.destination.type == DestinationType.INTERNATIONAL) {
			this.destination.province = false;
			this.destination.regione = false;
		}

		if (!this.destination.id) {
			this.crudService.createEntity(Destination, 'destination', this.destination).subscribe(response => {
				this.destination = response;
				this.close();
				const messages = this.translateService.instant(['labels.destination-save-success', 'labels.success']);
				this.messageService.sendSuccess(messages['labels.destination-save-success'], messages['labels.success']);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		} else {

			this.crudService.saveEntity(Destination, 'destination', this.destination).subscribe(response => {
				this.destination = response;
				this.close();
				const messages = this.translateService.instant(['labels.destination-save-success', 'labels.success']);
				this.messageService.sendSuccess(messages['labels.destination-save-success'], messages['labels.success']);
			}, ({ error }) => {
				this.messageService.sendError(
					error.msg ? error.msg : error,
					this.translateService.instant('labels.event-save-error')
				);
			});
		}
	}

}
