import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {

  constructor(private location: Location) { }

  cancel() {
    this.location.back();
  }
}
