import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { plainToClass, classToPlain } from 'class-transformer';
import { UserRole, AuthService, Staff } from 'elbuild-ui-lib-core';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class UserService {

	httpOptions: any;

	constructor(protected http: HttpClient,
		private authService: AuthService) { }

	getUserRolesByUid(uid: number): Observable<any> {
		return this.http.get(`/ws/usertorole/filter/uid/${uid}/range/0/100`)
			.pipe(map(res => plainToClass(UserRole, res as Object)));
	}

	assignRoleToUser(uid: number, rid: number): Observable<any> {
		return this.http.post(`/ws/usertorole`, { uid, rid })
			.pipe(map(res => plainToClass(UserRole, res as Object)));
	}

	unassignRoleToUser(id: number): Observable<any> {
		return this.http.delete(`/ws/usertorole/${id}`);
	}

	forceActivateUser(id: number) {
		return this.http.post(`/ws/user/${id}/activate`, {})
			.pipe(map(res => plainToClass(Staff, res as Object)));
	}

	signup(model: any) {
		return this.http.get('/ws/jwt/token', {}).pipe(mergeMap(((token: any) => {
			const httpOptions = {
				headers: new HttpHeaders({
					Authorization: 'Bearer: ' + token.jwt
				}),
			};
			return this.http.post(`/ws/user/signup`, classToPlain(model), httpOptions)
				.pipe(map(res => plainToClass(Staff, res as Object)));
		})));
	}

	changePassword(id: number, model: any) {
		return this.http.get('/ws/jwt/token', {}).pipe(mergeMap(((token: any) => {
			const httpOptions = {
				headers: new HttpHeaders({
					Authorization: 'Bearer: ' + token.jwt
				}),
			};
			return this.http.post(`/ws/user/${id}/password`, classToPlain(model), httpOptions)
				.pipe(map(res => plainToClass(Staff, res as Object)));
		})));
	}

	renewToken(): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'Bearer: ' + this.authService.getToken()
			}),
		};
		return this.http.get('/ws/jwt/user/renew', httpOptions)
			.pipe(map((token: any) => {
				this.authService.setTokenCookie(token.jwt);
			}));
	}



}

