<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div
			class="row mb-2"
			*ngIf="filtersReady && !isInvite">
			<div class="col-md-2">
				<input
					type="radio"
					[name]="'toggle'"
					(change)="onToggle($event, undefined)">
				{{'labels.nofilter' | translate}}
			</div>
			<div
				class="col-md-2"
				*ngFor="let status of statuses">
				<input
					type="radio"
					[id]="status"
					[name]="'toggle'"
					[checked]="status === 'joined'"
					(change)="onToggle($event, status)">
				{{'types.guest-status.'+status | translate}}
			</div>

		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.username' | translate}}</label>
					<input
						type="text"
						class="form-control"
						name="keyword"
						[(ngModel)]="guestsearch.genericterm">
				</div>
			</div>

			<div
				class="col-md-4"
				*ngIf="!isInvite && !isSent && !isRecived">
				<div class="form-group">
					<label>{{'labels.jointype' | translate}}</label>
					<select
						class="form-control"
						name="jointype"
						[(ngModel)]="guestsearch.jointype">
						<option
							*ngFor="let item of jointypes"
							[value]="item">
							{{'types.jointype.'+item | translate}}
						</option>
					</select>
				</div>
			</div>
			<div
				class="col-md-4"
				*ngIf="isInvite && !isSent">
				<div class="form-group">
					<label>{{'labels.invitedby' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedInviter($event)"
						(onDelete)="onDeleteInviter()"
						name="customer"
						[(ngModel)]="userSelected"
						placeholder="{{ 'labels.invitedby' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<label>
						{{'labels.compatibility' | translate}} {{'labels.min' | translate}}
					</label>
					<input
						type="number"
						class="form-control"
						name="compatibilitymin"
						[min]="1"
						[max]="100"
						[(ngModel)]="guestsearch.compatibilitymin">

				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<label>
						{{'labels.compatibility' | translate}} {{'labels.max' | translate}}
					</label>
					<input
						type="number"
						class="form-control"
						name="compatibilitymax"
						[min]="1"
						[max]="100"
						[(ngModel)]="guestsearch.compatibilitymax">
				</div>
			</div>

		</div>

		<div class="row">
			<div class="col-md-6">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<button
					type="button"
					class="btn btn-default"
					(click)="resetSearch()">
					{{'labels.reset' | translate}}
				</button>
			</div>
		</div>
	</form>
</app-card>
