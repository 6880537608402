import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudService, Page, RangeFilter, Search, SortDirection, thisYear } from 'elbuild-ui-lib-core';
import { DashboardService, Feedback, TravelFeedback } from 'meetravel-ui-lib';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';

@Component({
	selector: 'app-dashboard-feedback',
	templateUrl: './dashboard-feedback.component.html',
	styleUrls: ['./dashboard-feedback.component.scss']
})
export class DashboardFeedbackComponent implements OnInit {

	loading = true;
	data: any;
	rangeFilter: RangeFilter;
	today: Date;
	activedFilter: number;

	constructor(private dashboardService: DashboardService,
		private modalService: NgbModal) {
		this.today = new Date();
		this.rangeFilter = new RangeFilter();
	}

	ngOnInit() {

	}

	loadDashboard() {
		this.loading = true;
		const filter = { start: Math.trunc(this.rangeFilter.from / 1000), stop: Math.trunc(this.rangeFilter.to / 1000) };
		this.dashboardService.getFeedbackDashboard(filter.start, filter.stop).subscribe(result => {
			this.data = result;
			this.loading = false;
		}, error => {
			console.log('error', error);
		});
	}

	travelModal() {
		const modal = this.modalService.open(FeedbackModalComponent, { centered: true, size: 'lg' });

		modal.componentInstance.rangeFilter = this.rangeFilter;

		modal.result.then(() => {

		}).catch(() => {});
	}

	onRangeChange(event: RangeFilter) {
		this.rangeFilter = event;
		if (this.rangeFilter.from && this.rangeFilter.to) {
			this.loadDashboard();
		}
	}

}
