<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group d-flex flex-row">
					<input
						type="text"
						class="form-control d-inline"
						name="keyword"
						placeholder="Cerca..."
						[(ngModel)]="searchparams.genericSearchTerm">

				</div>
			</div>

		</div>

		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
			</div>

		</div>
	</form>
</app-card>
