//seguendo il nav a sx

export enum DashBoardFeatures {
	SECTION_DASHBOARD = 'section.dashboard',
	DASHBOARD_CLIENTI = 'section.dashboard.clienti',
	DASHBOARD_VIAGGI = 'section.dashboard.viaggi',
	DASHBOARD_INVITI = 'section.dashboard.inviti',
	DASHBOARD_FEEDBACK = 'section.dashboard.feedback',
	DASHBOARD_CHAT = 'section.dashboard.chat',
}

export enum AdminFeatures {
	SECTION_ADMIN = 'section.admin',
	ADMIN_STAFF = 'section.admin.staff',
	ADMIN_UTENTI = 'section.admin.utenti',
	ADMIN_RUOLI_E_FEATURES = 'section.admin.ruoliefeatures',
	ADMIN_VIAGGI = 'section.admin.viaggi',
	ADMIN_GAMIFICATION = 'section.admin.gamification',
	ADMIN_TESTCREATOR = 'section.admin.testcreator',
	ADMIN_PRODUCT = 'section.admin.product',
	ADMIN_PURCHASE = 'section.admin.purchase',
	ADMIN_SMART_TRANSACTIONS = 'section.admin.smart-transactions',
	ADMIN_SEGNALAZIONI = 'section.admin.segnalazioni',
	ADMIN_FEEDBACK = 'section.admin.feedback',
	ADMIN_ALERT = 'section.admin.alert',
	ADMIN_DESTINAZIONI = 'section.admin.destinazioni',
	ADMIN_NOTIFICHE = 'section.admin.notifiche',
	ADMIN_LISTE = 'section.admin.liste',
	ADMIN_BANNER = 'section.admin.banner',
	ADMIN_DISCOUNT = 'section.admin.discount',
	ADMIN_EDIT_USER = 'section.admin.utenti.edit',
	ADMIN_EDIT_VIAGGI = 'section.admin.viaggi.edit'


}

export enum ZoneFeatures {
	SECTION_ZONE = 'section.zone',
	ZONE_NAZIONI = 'section.zone.nazioni',
	ZONE_REGIONI = 'section.zone.regioni',
	ZONE_PROVINCE = 'section.zone.province',
	ZONE_COMUNI = 'section.zone.comuni',
}

export enum ConfigurazioneFeatures {
	SECTION_CONFIGURAZIONE = 'section.configurazione',
	CONFIGURAZIONE_SYSTEM = 'section.configurazione.system',


}
