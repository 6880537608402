import { Component, OnInit } from '@angular/core';
import { ParamsService } from 'elbuild-ui-lib-core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
	selector: 'app-city-list-filters',
	templateUrl: './city-list-filters.component.html',
	styleUrls: ['./city-list-filters.component.scss']
})
export class CityListFiltersComponent extends ListFiltersComponent implements OnInit {

	constructor(
		router: Router,
		_route: ActivatedRoute,
		paramsService: ParamsService
	) {
		super(router, _route, paramsService);

	}

	ngOnInit() {
	}

	readQueryParams(params: string) {
		super.readQueryParams(params);
	}

	resetSearch() {
		super.resetSearch();
	}

	search(keeppage?: boolean) {

		super.search(keeppage);
	}



}
