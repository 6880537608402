import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminFeatures, ConfigurazioneFeatures, DashBoardFeatures, ZoneFeatures } from './@core/core-components/conf/features';
import { AuthenticationGuardService } from './backoffice-auth/auth-guard.service';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';

const routes: Routes = [
	{
		path: '',
		component: AdminComponent,
		children: [
			{
				path: 'exports',
				loadChildren: () => import('./content/pages/exports/exports.module').then(module => module.ExportsModule),
			},
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full',

			},
			{
				path: 'dashboard',
				loadChildren: () => import('./content/pages/dashboard/dashboard.module').then(module => module.DashboardModule),
				data: {
					section: 'dashboard',
					aclFeatures: [DashBoardFeatures.SECTION_DASHBOARD]
				}
			},

			{
				path: 'user',
				loadChildren: () => import('./content/pages/user/user.module').then(module => module.UserModule),
				data: {
					section: 'user',
					aclFeatures: [AdminFeatures.ADMIN_UTENTI]
				}

			},
			{
				path: 'staff',
				loadChildren: () => import('./content/pages/staff/staff.module').then(module => module.StaffModule),
				data: {
					section: 'staff',
					aclFeatures: [AdminFeatures.ADMIN_STAFF]
				}

			},
			{
				path: 'travel',
				loadChildren: () => import('./content/pages/travel/travel.module').then(module => module.TravelModule),
				data: {
					section: 'travel',
					aclFeatures: [AdminFeatures.ADMIN_VIAGGI]
				}

			},
			{
				path: 'banner',
				loadChildren: () => import('./content/pages/banner/banner.module').then(module => module.BannerModule),
				data: {
					section: 'banner',
					aclFeatures: [AdminFeatures.ADMIN_BANNER]
				}

			},
			{
				path: 'discount',
				loadChildren: () => import('./content/pages/discount/discount.module').then(module => module.DiscountModule),
				data: {
					section: 'discount',
					aclFeatures: [AdminFeatures.ADMIN_BANNER]
				}

			},
			{
				path: 'purchase',
				loadChildren: () => import('./content/pages/purchase/purchase.module').then(module => module.PurchaseModule),
				data: {
					section: 'banner',
					aclFeatures: [AdminFeatures.ADMIN_BANNER]
				}

			},
			{
				path: 'smart-transactions',
				loadChildren: () => import('./content/pages/smart-transactions/smart-transactions.module').then(module => module.SmartTransactionsModule),
				data: {
					section: 'banner',
					aclFeatures: [AdminFeatures.ADMIN_BANNER]
				}

			},
			{
				path: 'product',
				loadChildren: () => import('./content/pages/product/product.module').then(module => module.ProductModule),
				data: {
					section: 'banner',
					aclFeatures: [AdminFeatures.ADMIN_BANNER]
				}

			},
			{
				path: 'report',
				loadChildren: () => import('./content/pages/report/report.module').then(module => module.ReportModule),
				data: {
					section: 'report',
					aclFeatures: [AdminFeatures.ADMIN_SEGNALAZIONI]
				}

			},
			{
				path: 'alert',
				loadChildren: () => import('./content/pages/alert/alert.module').then(module => module.AlertModule),
				data: {
					section: 'alert',
					aclFeatures: [AdminFeatures.ADMIN_ALERT]
				}

			},
			{
				path: 'country',
				loadChildren: () => import('./content/pages/country/country.module').then(module => module.CountryModule),
				data: {
					section: 'country',
					aclFeatures: [ZoneFeatures.ZONE_NAZIONI]
				}
			},
			{
				path: 'regione',
				loadChildren: () => import('./content/pages/regione/regione.module').then(module => module.RegioneModule),
				data: {
					section: 'regione',
					aclFeatures: [ZoneFeatures.ZONE_REGIONI]
				}
			},
			{
				path: 'province',
				loadChildren: () => import('./content/pages/province/province.module').then(module => module.ProvinceModule),
				data: {
					section: 'province',
					aclFeatures: [ZoneFeatures.ZONE_PROVINCE]
				}
			},
			{
				path: 'gamification',
				loadChildren: () => import('./content/pages/gamification/gamification.module').then(module => module.GamificationModule),
				data: {
					section: 'gamifications',
					aclFeatures: [AdminFeatures.ADMIN_GAMIFICATION]
				}
			},
			{
				path: 'testcreator',
				loadChildren: () => import('./content/pages/testcreator/testcreator.module').then(module => module.TestcreatorModule),
				data: {
					section: 'testcreator',
					aclFeatures: [AdminFeatures.ADMIN_TESTCREATOR]
				}
			},

			{
				path: 'testcreator/rispostetestcreator',
				loadChildren: () => import('./content/pages/rispostetestcreator/rispostetestcreator.module').then(module => module.Rispostetestcreatormodule),
				data: {
					section: 'testcreator',
					aclFeatures: [AdminFeatures.ADMIN_TESTCREATOR]
				}
			},

			{
				path: 'city',
				loadChildren: () => import('./content/pages/city/city.module').then(module => module.CityModule),
				data: {
					section: 'city',
					aclFeatures: [ZoneFeatures.ZONE_COMUNI]
				}
			},
			{
				path: 'destination',
				loadChildren: () => import('./content/pages/destination/destination.module').then(module => module.DestinationModule),
				data: {
					section: 'destionation',
					aclFeatures: [AdminFeatures.ADMIN_DESTINAZIONI]
				}
			},
			{
				path: 'notification',
				loadChildren: () => import('./content/pages/notification/notification.module').then(module => module.NotificationModule),
				data: {
					section: 'notification',
					aclFeatures: [AdminFeatures.ADMIN_NOTIFICHE]
				}
			},
			{
				path: 'conf',
				loadChildren: () => import('./content/pages/conf/conf.module').then(module => module.ConfModule),
				data: {
					section: 'conf',
					aclFeatures: [ConfigurazioneFeatures.CONFIGURAZIONE_SYSTEM]
				}
			},


			{
				path: 'list',
				loadChildren: () => import('./content/pages/list/list.module').then(module => module.ListModule),
				data: {
					section: 'list',
				}
			},

			{
				path: 'feedback',
				loadChildren: () => import('./content/pages/feedback/feedback.module').then(module => module.FeedbackModule),
				data: {
					section: 'feedback',
					aclFeatures: [AdminFeatures.ADMIN_FEEDBACK]
				}
			},
			{
				path: 'rolefeatures',
				loadChildren: () => import('./content/pages/role-features/role-features.module').then(module => module.RoleFeaturesModule),
				data: {
					section: 'rolefeatures',
					aclFeatures: [AdminFeatures.ADMIN_RUOLI_E_FEATURES]
				}
			},
			{
				path: 'unauthorized',
				loadChildren: () => import('./content/pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
			},
			{ path: 'login', redirectTo: '/signin', pathMatch: 'full' },
		],
		canActivate: [AuthenticationGuardService],
		canActivateChild: [AuthenticationGuardService]
	},
	{
		path: '',
		component: AuthComponent,
		children: []
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
