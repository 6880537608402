import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ClassType } from 'class-transformer/ClassTransformer';
import { DialogService } from 'elbuild-ui-componentslib';
import { AclService, CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { Destination, MeetravelUser, Travel as _TravelOld, TravelService, TravelStatuses, UserType } from 'meetravel-ui-lib';

import { AdminFeatures } from '../../../conf/features';
import { TravelTypesComponent } from '../travel-types/travel-types.component';
import { StatusAdminModalComponent } from './status-admin-modal/status-admin-modal.component';

class Travel extends _TravelOld {
	smart: boolean;
  }
@Component({
	selector: 'app-travel-detail',
	templateUrl: './travel-detail.component.html',
	styleUrls: ['./travel-detail.component.scss']
})
export class TravelDetailComponent implements OnInit, AfterViewInit {

	@ViewChild('travelTypeList') travelTypeComponent: TravelTypesComponent;

	@Input('travel')
	set travel(value: Travel) {
		this._travel = value;
		if (this._travel) {

			if (this._travel.organizer) {
				this.userSelected = this._travel.organizer;
			}
			if (this._travel.destinationid && this._travel.destination) {
				this.destinationSelected = new Destination();
				this.destinationSelected.id = this._travel.destinationid;
				this.destinationSelected.name = this._travel.destination;
			}
		}
	}

	_travel: Travel;
	destinationSearch = new Search('destination');
	destinationClass: ClassType<Destination> = Destination;
	destinationSelected: Destination;

	searchUsers: Search;
	userClass: ClassType<MeetravelUser> = MeetravelUser;
	userSelected: MeetravelUser;

	statuses: string[] = Object.keys(TravelStatuses).map(k => TravelStatuses[k]);
	travelClass = Travel;
	feature_editable = false;
	FEATURE_EDIT_VIAGGI = AdminFeatures.ADMIN_EDIT_VIAGGI;



	constructor(private crudService: CrudService<Travel>,
		private travelService: TravelService,
		private modalService: NgbModal,
		private messageService: MessageService,
		private translateService: TranslateService,
		private aclService: AclService,
		private router: Router,
		private dialogService: DialogService) {
		this.searchUsers = new Search('user');
		this.searchUsers.addSimpleFilter('utype', UserType.USER);
	}

	ngOnInit() {
		this.aclService.hasPermission(this.FEATURE_EDIT_VIAGGI).subscribe(res => {
			this.feature_editable = res;
		})
	}

	ngAfterViewInit() {
		console.log(this.statuses);
	}

	onSelectedDestination(event: Destination) {
		this._travel.destinationid = event.id;
		this._travel.destination = event.name;
	}

	onDeleteDestination() {
		this._travel.destinationid = undefined;
		this._travel.destination = undefined;
	}

	onSelectedOrganizer(event: MeetravelUser) {
		this._travel.hostid = event.id;
	}

	onDeleteOrganizer() {
		this._travel.hostid = undefined;
	}

	update(event: any): void {

		if (event && event.success && event.response) {
			this._travel.img = event.response.img;

		} else {
			this.messageService.sendError(
				this.translateService.instant('labels.event-upload-error')
			);
		}
	}

	accept() {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-accept'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.travelService.accept(this._travel).subscribe(res => {
					this.travel = res;
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.travel'));
				});
			}
		});
	}

	delete() {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.crudService.deleteEntity(Travel, 'travel', this._travel).subscribe(() => {
					this.translateService.get(['labels.travel-deleted', 'labels.travel']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.travel-deleted'], messages['labels.travel']);
						this.router.navigate(['/travel']);
					});
				});
			}
		});
	}

	submit() {
		this.travelTypeComponent.submit();
		this.crudService.saveEntity(Travel, 'travel', this._travel).subscribe(result => {
			this._travel = result;
			this.messageService.sendSuccess(this.translateService.instant('labels.save-success'), this.translateService.instant('labels.travel'));
		}, error => {
			this.messageService.sendError(error);
		});
	}

	showDeleteNote(item: Travel) {
		this.dialogService.openAlertModal({
			message: item.deletereason + ' - ' + item.deletereasonother,
			hasIcon: true,
			type: 'warning'
		});
	}


	openStatusEditModal() {
		const modal = this.modalService.open(StatusAdminModalComponent, { centered: true, size: 'sm' });

		modal.componentInstance.travel = this._travel;

		modal.result.then((travel: Travel) => {
			this._travel = travel
		}).catch(() => {});
	}


	decline() {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-travel-decline'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.travelService.decline(this._travel).subscribe(res => {
					this._travel = res;
					this.messageService.sendSuccess(this.translateService.instant('labels.travel-declined'), this.translateService.instant('labels.travel-declined'));
				}, error => {
					this.messageService.sendError(error, this.translateService.instant('labels.report'));
				});
			}
		});
	}

}
