<div class="row ">
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<app-loading
				[full]="false"
				*ngIf="loading"></app-loading>
			<a
				class="float-right link"
				(click)="edit()">
				<i
					container="body"
					ngbTooltip="{{'labels.create' | translate}}"
					class="feather icon-plus"></i>
			</a>

			<div
				*ngIf="!loading"
				class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-products' | translate,
          totalMessage: 'labels.total' | translate
		}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[sortable]="false"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="name"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.name' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="productKey"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.productkey' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="gruppo"
						[flexGrow]="0.5"
						[sortable]="false"
						[name]="'labels.groupid' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="groupname"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.groupname' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="groupmaster"
						[flexGrow]="1"
						[sortable]="false"
						[sortable]="true"
						[resizeable]="false">
						<ng-template ngx-datatable-header-template>
							{{'labels.groupmaster' | translate}}
							<i
								container="body"
								class="feather icon-info"
								ngbTooltip="Attenzione! È consentito un solo master per gruppo!"></i>
						</ng-template>
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value ? ('labels.yes' | translate) : ('labels.no' | translate)}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="active"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.active' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							{{value ? ('labels.yes' | translate) : ('labels.no' | translate)}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						[flexGrow]="1"
						[sortable]="false"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<div class="icon-buttons">
								<a (click)="edit(row)">
									<i
										container="body"
										ngbTooltip="{{'labels.edit' | translate}}"
										class="feather icon-edit"></i>
								</a>
							</div>
						</ng-template>
					</ngx-datatable-column>

				</ngx-datatable>

			</div>
		</app-card>
	</div>
</div>
