<ul
	ngbNav
	#nav="ngbNav"
	class="nav-tabs"
	[activeId]="activeTab">
	<li
		ngbNavItem
		domId="detail">
		<a ngbNavLink>{{'labels.general-data' | translate}}</a>
		<ng-template ngbNavContent>
			<app-travel-detail [travel]="travel"></app-travel-detail>
		</ng-template>
	</li>
	<li
		ngbNavItem
		domId="organizer">
		<a ngbNavLink>{{'labels.organizer-data' | translate}}</a>
		<ng-template ngbNavContent>
			<div class="card">
				<div class="card-body">
					<app-user-new-edit
						[editable]="false"
						[uid]="travel.hostid"></app-user-new-edit>
				</div>
			</div>

		</ng-template>
	</li>
	<li
		ngbNavItem
		domId="partecipants">
		<a ngbNavLink>{{'labels.partecipants' | translate}}</a>
		<ng-template ngbNavContent>
			<app-guest-list [guestList]="travel.guests"></app-guest-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		domId="invites">
		<a ngbNavLink>{{'labels.invites' | translate}}</a>
		<ng-template ngbNavContent>
			<app-guest-list
				[guestList]="travel.guests"
				[isInvite]="true"></app-guest-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		domId="feedback">
		<a ngbNavLink>{{'labels.feedback' | translate}}</a>
		<ng-template ngbNavContent>
			<app-feedback-list [travelid]="travel.id"></app-feedback-list>
		</ng-template>
	</li>
	<li
		*aclFeatures="FEATURE_EDIT_VIAGGI"
		ngbNavItem
		domId="chat">
		<a ngbNavLink>{{'labels.chat' | translate}}</a>
		<ng-template ngbNavContent>
			<app-travel-chat
				[travelid]="travel?.id"
				[selectedId]="messageid"></app-travel-chat>
		</ng-template>
	</li>
	<li
		ngbNavItem
		domId="rating">
		<a ngbNavLink>{{'labels.travel-rating' | translate}}</a>
		<ng-template ngbNavContent>
			<app-travel-feedback-list [travelid]="travel?.id"></app-travel-feedback-list>
		</ng-template>
	</li>
	<li
		ngbNavItem
		domId="media">
		<a ngbNavLink>{{'labels.travel-media' | translate}}</a>
		<ng-template ngbNavContent>
			<app-travel-media [travel]="travel"></app-travel-media>
		</ng-template>
	</li>
	<li
		*ngIf="travel.smart"
		ngbNavItem
		domId="smart">
		<a ngbNavLink>{{'labels.smart-data' | translate}}</a>
		<ng-template ngbNavContent>
			<app-travel-smart [travel]="travel"></app-travel-smart>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="nav"></div>
