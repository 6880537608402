import { Component, OnInit, Input } from '@angular/core';
import { Travel, TravelAccomodation } from 'meetravel-ui-lib';
import { ListItem, CrudService, List, Search, Page } from 'elbuild-ui-lib-core';

@Component({
  selector: 'app-travel-accomodation',
  templateUrl: './travel-accomodation.component.html',
  styleUrls: ['./travel-accomodation.component.scss']
})
export class TravelAccomodationComponent implements OnInit {

  @Input() travel: Travel;
  listitems: ListItem[];

  toAdd = [];
  toDelete = [];

  constructor(private listService: CrudService<List>,
    private listItemService: CrudService<ListItem>,
    private travelAccomodationService: CrudService<TravelAccomodation>) {
    this.loadItems();
  }

  loadItems() {
    const searchList = new Search('selist');
    const searchListItem = new Search('selistitem');
    searchList.addSimpleFilter('slug', 'travel.accomodation');
    this.listService.searchEntities(List, 'selist', searchList, new Page(1)).subscribe(result => {
      searchListItem.addSimpleFilter('lid', result[0].id);
      this.listItemService.searchEntities(ListItem, 'selistitem', searchListItem, new Page(100)).subscribe(items => {
        this.listitems = items;
      }, error => {
        console.log('error', error);
      });
    }, error => {
      console.log('error', error);
    });

  }

  addDelete(item: ListItem, event: any) {
    if (event) {
      if (!this.travel.types.map(listitem => listitem.id).includes(item.id)) {
        this.toAdd.push({ id: item.id, accomodation: item.itemvalue });
      }
      if (this.toDelete.map(listitem => listitem.id).includes(item.id)) {
        this.toDelete = this.toDelete.filter(listitem => listitem.id !== item.id);
      }
    } else {
      if (this.travel.types.map(listitem => listitem.id).includes(item.id)) {
        this.toDelete.push({ id: item.id, accomodation: item.itemvalue });
      }
      if (this.toAdd.map(listitem => listitem.id).includes(item.id)) {
        this.toAdd = this.toAdd.filter(listitem => listitem.id !== item.id);
      }
    }

  }

  isChecked(item: ListItem) {
    return this.travel.accomodations.map(listitem => listitem.accomodation).includes(item.itemvalue);
  }

  ngOnInit() {
  }

  submit() {
    const calls = [];
    this.toAdd.forEach(item => calls.push(this.travelAccomodationService.createEntity(TravelAccomodation, 'travelaccomodation', { ...item, travelid: this.travel.id })));
    return calls;
  }

}
