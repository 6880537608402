import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Search, User, CrudService, MessageService, SharedDataService, SortDirection } from 'elbuild-ui-lib-core';
import { Subscription, Observable, combineLatest, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Country } from 'meetravel-ui-lib';
import { CountryEditComponent } from '../country-edit/country-edit.component';
import { ListFiltersComponent, DialogService } from 'elbuild-ui-componentslib';

@Component({
	selector: 'app-country-list',
	templateUrl: './country-list.component.html',
	styleUrls: ['./country-list.component.scss']
})

export class CountryListComponent implements OnInit, AfterViewInit, OnDestroy {

	loading: boolean = false;
	rows: Country[];

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;
	showAdvancedSearch: boolean = true;

	advancedSearch: Search = new Search('country', 'name', 'country');

	loggedUser: User;
	subcription: Subscription;

	constructor(
		private countryService: CrudService<Country>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private sharedDataService: SharedDataService
	) {

		this.advancedSearch.setSortDirection(SortDirection.ASCENDING);
		this.advancedSearch.setSortField('id');
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams();

		const requests: Observable<any>[] = [urlparamsreq];

		if (!this.loggedUser) {
			const userrequest = this.sharedDataService.currentLoggedUser;
			requests.push(userrequest);
		}

		this.subcription = combineLatest(requests).subscribe(data => {

			if (data[1]) {
				this.loggedUser = data[1];
			}

			this.search(data[0]);

		});
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		if (this.subcription) {
			this.subcription.unsubscribe();
		}
	}

	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;
		}
		this.load();
	}

	load() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequest = this.countryService.searchEntities(Country, 'country', this.advancedSearch, this.advancedSearch.page);
		const countrequest = this.countryService.countEntities(Country, 'country', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.advancedSearch.page.setTotal(results[1].ct);
			this.rows = results[0];

			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.load();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.load();
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	delete(row: Country) {
		this.dialogService.openConfirmModal({
			message: this.translateService.instant('labels.warning-country-delete'),
			hasIcon: true,
			type: 'warning'
		}).then(result => {
			if (result) {
				this.countryService.deleteEntity(Country, 'country', row).subscribe(() => {
					this.translateService.get(['labels.country-deleted', 'labels.country']).subscribe(messages => {
						this.messageService.sendSuccess(messages['labels.country-deleted'], messages['labels.country']);
						this.load();
					});
				});
			}
		});
	}


	edit(countryid?: number) {
		const modal = this.modalService.open(CountryEditComponent, { centered: true, size: 'lg' });

		if (countryid) {
			modal.componentInstance.load(countryid);
		}
		modal.result.then((newItem: Country) => {
			this.load();
		}).catch(() => { });
	}

}
