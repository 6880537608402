import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Destination, MeetravelUser } from 'meetravel-ui-lib';
import { Search, MessageService, CrudService, SharedDataService } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { DestinationNewEditComponent } from '../destination-new-edit/destination-new-edit.component';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';

@Component({
  selector: 'app-destination-list',
  templateUrl: './destination-list.component.html',
  styleUrls: ['./destination-list.component.scss']
})
export class DestinationListComponent implements OnInit {

  advancedSearch: Search = new Search('destination');
  rows: Destination[];
  loading: boolean;

  @ViewChild('listfilters', {}) listfilters: ListFiltersComponent;

  constructor(
    private crudService: CrudService<Destination>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sharedDataService: SharedDataService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  search(searchParams?: any) {
    if (searchParams) {
      this.advancedSearch = searchParams;

    }
    this.loadItems();
  }

  loadItems() {
    this.loading = true;
    this.rows = [];
    this.cdr.detectChanges();

    const datarequest = this.crudService.searchEntities(Destination, 'destination', this.advancedSearch, this.advancedSearch.page);
    const countrequest = this.crudService.countEntities(Destination, 'destination', this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.advancedSearch.page.setTotal(results[1].ct);
      this.rows = results[0];
      console.log(this.rows);
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error, this.translateService.instant('labels.error-occurred'));
      this.loading = false;
    });
  }

  get page() {
    if (this.advancedSearch) {
      return this.advancedSearch.page;
    } else {
      return undefined;
    }
  }

  edit(item: Destination, event: any) {
    const modal = this.modalService.open(DestinationNewEditComponent, { centered: true, size: 'lg' });
    if (item) {
      modal.componentInstance.load(item.id);
    }
    modal.result.then((newItem: MeetravelUser) => {
      this.loadItems();
    }).catch(() => { });
  }

  delete(row: Destination, event: any) {
    this.dialogService.openConfirmModal({
      message: this.translateService.instant('labels.warning-destination-delete'),
      hasIcon: true,
      type: 'warning'
    }).then(result => {
      if (result) {
        this.crudService.deleteEntity(Destination, 'destination', row).subscribe(() => {
          this.translateService.get(['labels.destination-deleted', 'labels.destination']).subscribe(messages => {
            this.messageService.sendSuccess(messages['labels.destination-deleted'], messages['labels.destination']);
            this.loadItems();
          });
        });
      }
    });
  }


  onPageChange(pageInfo: any) {

    this.advancedSearch.page.setPageNumber(pageInfo.offset);
    this.listfilters.addQueryParams();
    this.loadItems();
  }

  onSortChange(sortInfo: any) {
    const sort = sortInfo.sorts[0];
    this.advancedSearch.setSortField(sort.prop);
    this.advancedSearch.setSortDirection(sort.dir);
    this.listfilters.addQueryParams();
    this.loadItems();
  }


  rowClass = (row: any) => {
    return '';
  }



}
