<app-card
	cardTitle
	cardClass="card-datatable"
	[options]="false">
	<form
		(ngSubmit)="search()"
		class="filterform">
		<div class="row">
			<div class="col-md-4">
				<div class="form-group">
					<label>{{'labels.sender' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedSender($event)"
						(onDelete)="onDeleteSender()"
						name="sender"
						[(ngModel)]="senderSelected"
						placeholder="{{ 'labels.sender' | translate }}">
					</app-entity-autocomplete>
				</div>
			</div>

			<div class="col-md-4">
				<label>
					{{'labels.insdate' | translate}} {{'labels.min' | translate}}
				</label>
				<app-datepicker
					name="insdatestart"
					[millis]="true"
					[(ngModel)]="searchparams.rangeTmp['insdate'].start">
				</app-datepicker>
			</div>
			<div class="col-md-4">
				<label>
					{{'labels.insdate' | translate}} {{'labels.max' | translate}}
				</label>
				<app-datepicker
					name="insdatestop"
					[millis]="true"
					[(ngModel)]="searchparams.rangeTmp['insdate'].stop">
				</app-datepicker>
			</div>

		</div>

		<div class="row mt-1">
			<div class="col">
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.search' | translate}}
				</button>
				<a
					(click)="resetSearch()"
					class="btn btn-default">
					{{'labels.reset' | translate}}
				</a>
			</div>
		</div>
	</form>
</app-card>
