<div class="modal-header">
	<h4 class="modal-title">
		{{(item?.id ? 'labels.edit-answer' : 'labels.new-answer') | translate}}
	</h4>
	<button
		type="button"
		class="close"
		aria-label="Close"
		(click)="dismiss()">
		<span aria-hidden="true">×</span>
	</button>
</div>
<div
	class="modal-body"
	ngbAutofocus>

	<div class="row">
		<div class="col-md-12">
			<div class="form-group">
				<label>{{'labels.answer' | translate}}</label>
				<input
					type="text"
					class="form-control"
					placeholder="{{'labels.answer' | translate}}"
					[(ngModel)]="item.answer">
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group">
				<label>{{'labels.sort' | translate}}</label>
				<input
					type="text"
					class="form-control"
					placeholder="{{'labels.sort' | translate}}"
					[(ngModel)]="item.sort">
			</div>
		</div>

		<div class="col-md-4">
			<div class="form-group mt-4 pt-2">
				<input
					id="correctanswer"
					class="bootstrap"
					name="correctanswer"
					[(ngModel)]="item.correctanswer"
					#correctanswer="ngModel"
					type="checkbox">
				<label
					class="check-label"
					for="correctanswer">
					{{'labels.correctanswer' | translate}}
				</label>
			</div>
		</div>

	</div>

</div>
<div class="modal-footer">
	<button
		class="btn btn-primary"
		(click)="submit()">
		{{'labels.save' | translate}}
	</button>

	<button
		(click)="dismiss()"
		class="btn btn-secondary"
		type="button">
		{{'labels.cancel' | translate}}
	</button>
</div>
